type App = 'idm' | 'admin' | 'warehouse' | 'yard' | 'execution' | 'ceramicPortal' | 'order';
type AppURL = 'idm' | 'admin' | 'warehouse' | 'yard' | 'yardWs' | 'execution' | 'ceramicPortal' | 'order';

export interface Environment {
  production?: boolean;
  envName?: string;

  cognito?: {
    region?: string;
    identityPoolId?: string;
    userPoolId?: string;
    clientId?: string;
    bucketId?: string;
    oauth?: {
      domain?: string;
      scope?: string[];
      redirectSignIn?: string;
      redirectSignOut?: string;
      responseType?: string;
    };
    federatedButtonText?: string;
    passwordPolicyText?: string;
  };

  apiUrl?: Record<AppURL, string>;
  domain: string;
  webUrl?: Record<App, string>;

  appId?: Record<App, number>;

  other?: {
    mapApiKey?: string;
    awsFileServiceApi?: string;
    tableColumnVisibilityToggle?: {
      yardLink?: {
        hideKeys?: string[];
      };
    };
  };

  inhanceWorker?: {
    authUrl?: string;
    appClientId?: string;
    appClientSecret?: string;
    apiUrl?: string;
  };

  redis?: {
    host?: string;
    port?: number;
    db?: number;
    password?: string;
  };
}

class Env {
  private _value: Environment;

  get values() {
    if (typeof window !== 'undefined') {
      if (!this._value && !!window) {
        const ls = window['environment'] || JSON.parse(localStorage?.getItem('config')) || undefined;
        // console.log('WIDNOW ENV: ', window['environment']);
        // console.log('LS', ls)
        this.values = ls;
        return ls;
      }
    }
    return this._value;
  }

  set values(value) {
    if (value) {
      localStorage?.setItem('config', JSON.stringify(value));
    }

    this._value = value;
  }
}

const d = new Env();
export const setEnvironment = env => {
  d.values = env;
};
export const environment = d;
