import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@ov-suite/helpers-angular';

export interface UploadedFile {
  original: string;
  thumbnail: string;
  scaled: string;
}

@Injectable({
  providedIn: 'root',
})
export class AWSStorageService {
  constructor(private readonly httpClient: HttpClient) {}

  async uploadFile(file: File): Promise<Observable<HttpResponse<UploadedFile>>> {
    const base64 = await this.convertToBase64(file);
    return this.httpClient.post<UploadedFile>(environment.values.other.awsFileServiceApi, base64.replace(`data:${file.type};base64,`, ''), {
      observe: 'response',
      headers: {
        'content-type': file.type,
      },
    });
  }

  async convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }
}
