import { Process } from '@ov-suite/helpers-shared';
import gql from 'graphql-tag';

export function startProcessInstanceByKeyMutation(keys: (keyof Process)[]) {
  return gql(`mutation StartProcessInstanceByKey($query:StartProcessByKeyInput!) {
    startProcessInstanceByKey(query: $query) {
       ${keys.join(`\n`)}
     }
   }`);
}

export function queryProcessInstances(keys: (keyof Process)[]) {
  return gql(`query QueryProcessInstances($query:QueryProcessInput!) {
    queryProcessInstances(query: $query) {
      data {
        ${keys.join(`\n`)}
      }
      total
      start
      sort
      order
      size
     }
   }`);
}

// todo: Complete this
export function startWorkflowMutation() {
  return gql(`
    mutation StartProcess($params: StartProcessInput!) {
      startProcess(params: $params) {
        id
        taskIds
      }
    }
  `)
}
