<div class="main-content">
  <div class="main-container">

    <div class="slide-in-blurred-top">
      <div class="row">
        <div class="col-md-8" [ngClass]="{ 'col-md-12': !sidebarFields }">
          <div class="left-side">
            <div class="card left-side">
              <div class="card-body">
                <ng-container *ngIf="title">
                  <h5 class="card-title">
                    {{ data?.id ? 'Edit ' + title : 'Create ' + title }}
                  </h5>
                  <hr />
                </ng-container>
                <ov-suite-form
                  #form
                  [formClass]="formClass"
                  [data]="data"
                  (save)="onSave()"
                  [showSave]="!sidebarFields"
                  [dataSources]="dataSources"
                  [sideBarMetadata]="sidebarFields"
                ></ov-suite-form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 dev" *ngIf="!!sidebarFields">
          <div class="card">
            <div class="card-body">
              <ng-container *ngFor="let item of sidebarFields">
                <div class="row">
                  <div
                    *ngFor="let subItem of item"
                    [ngClass]="{
                  'col-md-6': item.length === 2,
                  'col-md-4': item.length >= 3,
                  'col-md-12': item.length <= 1
                }"
                  >
                    <ng-container
                      *ngIf="
                    subItem?.propertyKey &&
                    (!subItem.readonly || (subItem.readonly && subItem.value))
                  "
                      [ngSwitch]="subItem.type"
                    >
                      <!-- STRING -->
                      <div *ngSwitchCase="'string'">
                        <div class="form-group">
                          <label [for]="subItem.propertyKey">{{
                            subItem.title
                            }}</label>
                          <input
                            [id]="subItem.propertyKey"
                            [name]="subItem.propertyKey"
                            type="text"
                            class="form-control"
                            [(ngModel)]="subItem.value"
                            [disabled]="subItem.readonly"
                          />
                          <small
                            *ngIf="subItem.danger"
                            class="form-text text-muted text-danger"
                          >{{ subItem.currentErrorMessage || 'Required' }}</small
                          >
                        </div>
                      </div>
                      <!-- DROPDOWN -->
                      <div *ngSwitchCase="'dropdown'" class="form-group">
                        <label [for]="subItem.propertyKey">{{
                          subItem.title
                          }}</label>
                        <input
                          [title]="subItem.title"
                          [id]="subItem.propertyKey"
                          [name]="subItem.propertyKey"
                          type="text"
                          class="form-control"
                          [(ngModel)]="subItem.value"
                        />
                        <small
                          *ngIf="subItem.danger"
                          class="form-text text-muted text-danger"
                        >{{ subItem.currentErrorMessage || 'Required' }}</small
                        >
                      </div>

                      <!-- PHOTO -->
                      <div *ngSwitchCase="'image'">
                        Image
                        <ov-suite-image-upload
                          [size]="'default'"
                          [id]="subItem.propertyKey"
                          [(imageUrl)]="subItem.value"
                        >
                        </ov-suite-image-upload>
                        <small
                          *ngIf="subItem.danger"
                          class="form-text text-muted text-danger"
                        >{{
                          subItem.currentErrorMessage || 'Image required'
                          }}</small
                        >
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="
                    subItem.dropdown &&
                    ((!data?.id && !subItem.generated) ||
                      (data?.id && !subItem.readonly))
                  "
                    >
                      <ov-suite-input
                        [statusColor]="subItem?.value?.color"
                        [(ngModel)]="subItem.value"
                        type="dropdown"
                        [inputLabel]="subItem.title"
                        [data]="getDataSource(subItem)"
                        [label]="subItem.dropdownKeys"
                      >
                      </ov-suite-input>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <hr *ngIf="!!data?.id" />
              <button
                class="btn btn-secondary btn-block btn-round edit-button"
                (click)="onCancel()"
              >
                Cancel
              </button>
              <button
                *ngIf="!!data?.id"
                class="btn btn-danger btn-block btn-round archive-button"
                (click)="onDelete()"
              >
                Archive
              </button>
              <button
                *ngIf="!!data?.id"
                class="btn btn-info btn-block btn-round archive-button"
                (click)="onResetPassword()"
              >
                Reset Password
              </button>
              <button
                *ngIf="!!data?.id"
                class="btn btn-warning btn-block btn-round archive-button"
                (click)="onSetPassword()"
              >
                Set Password
              </button>
              <button
                class="btn btn-primary btn-block btn-round save-button"
                type="submit"
                (click)="onSaveUser()"
                [disabled]="loading"
              >
            <span *ngIf="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
            ></span>
                Save & Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
