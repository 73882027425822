<div>
  <mat-drawer-container class="ov-sidenav-container" autosize>
    <mat-drawer
      class="ov-sidenav-drawer"
      mode="side"
      [opened]="sideNavOpen"
    >
      <ov-suite-sidebar>
      </ov-suite-sidebar>
    </mat-drawer>
    <mat-drawer-content>
      <ov-suite-navbar
        [sideNavOpen]="sideNavOpen"
        (onMenuSideNavToggle)="toggleDrawer($event)"
      ></ov-suite-navbar>
      <div class="ov-sidenav-drawer-content">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
