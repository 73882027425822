export interface DropdownItem {
  value: string;
  title: string;
}

const reason: DropdownItem[] = [
  { value: 'auto closed - IMEI in use', title: 'Auto closed - IMEI in use' },
  { value: 'expired', title: 'Expired' },
  { value: 'manually closed', title: 'Manually Closed' },
];

export const staticDropdownOptions = { reason };
