import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { ChildrenItems, RouteInfo } from '@ov-suite/ui';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { OvAutoService, UserAuthService } from '@ov-suite/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ov-suite-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: [ 'sidebar.component.scss' ],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  panelOpenState = true;

  public opened = true;

  public menuItems: RouteInfo[] = [];

  linkList = [];

  subList = [];

  routesSubscription: Subscription;

  selectedRoute = {};

  constructor(
    location: Location,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly element: ElementRef,
    private readonly ovAutoService: OvAutoService,
    private userAuth: UserAuthService,
  ) {
  }

  ngOnInit() {
    this.routesSubscription = this.userAuth.userRoutesSubject.subscribe((routes) => {
      this.menuItems = routes;

      this.setActiveApplication();
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.routesSubscription.unsubscribe();
  }

  openPanel(menuitem) {
    this.panelOpenState = true;
  }

  setActiveApplication() {
    let paths = window.location.pathname.substring(1).split('/');
    let menuItems_ = [ ...this.menuItems ];

    this.menuItems.forEach((parent, index) => {
      if (parent.path === '/' && parent.children) {
        parent.children.forEach(child => {
          if (child.path === paths[ 0 ]) {
            parent[ 'active' ] = true;
            child[ 'active' ] = true;
            this.selectedRoute = parent;
          }
        });
      }

      if (parent.path.includes(paths[ 0 ])) {
        if (parent.children && paths[ 1 ]) {
          parent.children.forEach(child => {
            if (child.path === paths[ 1 ]) {
              child[ 'active' ] = true;
              parent[ 'active' ] = true;
              this.selectedRoute = parent;
            }
          });
        } else {
          parent[ 'active' ] = true;
          this.selectedRoute = parent;
        }
      }
    });

    this.menuItems.forEach((item) => {
      switch (item.type) {
        case 'sub': {
          this.subList.push(item)
          break;
        }
        default: {
          this.linkList.push(item)
        }
      }
    });
  }

  resetActiveRoutes() {
    this.menuItems.forEach(item => {
      item.active = false;
    })
  }

  setTitle(parent, child) {
    this.resetActiveRoutes();

    parent.active = true;
    this.selectedRoute = parent;

    let title = parent.title.charAt(0).toUpperCase() + parent.title.slice(1);

    if (child) {
      title += ' - ' + child.title;
    }

    this.userAuth.pageTitleSubscription.next(title);
  }

  getArrow() {
    return this.opened ? 'arrow_left' : 'arrow_right';
  }
}
