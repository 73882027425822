import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OvAutoService, UserAuthService } from '@ov-suite/services';
import { Subscription } from 'rxjs';

interface INavbarDomains {
  id: number;
  title: string;
  url: string;
  hasPermission: boolean;
}

@Component({
  selector: 'ov-suite-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit, OnDestroy, OnInit {
  title = 'Dashboard';

  pageTitleSubscription: Subscription;

  @Input() sideNavOpen;

  @Output() onMenuSideNavToggle = new EventEmitter<boolean>();

  userAppsSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly ovAutoService: OvAutoService,
    private readonly userAuth: UserAuthService
  ) {
  }

  ngOnInit() {
    this.title = this.prepareTitle();

    this.pageTitleSubscription = this.userAuth.pageTitleSubscription.subscribe(title => {
      this.title = title;
    })
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.pageTitleSubscription.unsubscribe();
  }

  prepareTitle() {
    const pathname = window.location.pathname.substring(1);

    let temp = pathname.charAt(0).toUpperCase() + pathname.slice(1);

    return temp.split('/').join(' - ');
  }

  sideMenuToggle() {
    this.sideNavOpen = !this.sideNavOpen;
    this.onMenuSideNavToggle.emit(this.sideNavOpen);
  }
}
