<div class="main-content">
  <ov-suite-hierarchy-tabs *ngIf="!!options.tabs" [tabs]="options.tabs"> </ov-suite-hierarchy-tabs>
  <ov-suite-top-filters
    *ngIf="options.topFilters"
    [entity]="options.entity"
    [filters]="options.topFilters"
    (filterChange)="onChangeFilter($event)"
  ></ov-suite-top-filters>
  <div class="main-container">
    <ov-suite-hierarchy-breadcrumb
      *ngIf="isHierarchy"
      [ovAutoService]="ovAutoService"
      [formClass]="options.entity"
    ></ov-suite-hierarchy-breadcrumb>
    <ov-suite-hierarchy-table
      #table
      [title]="options.tableTitle"
      [formClass]="options.entity"
      [ovAutoService]="ovAutoService"
      [api]="options.api"
      [hideAddButton]="options.hideAdd"
      [hasBulkUpload]="!options.hideImport"
      [excludeColumns]="options.hideEditDelete ? 4 : 1"
      [hideColumnKeys]="options.hideColumns"
      [showFiller]="options.showFiller"
      [hasCreatePermission]="canAdd"
      [hasEditPermission]="canUpdate"
      [hasDeletePermission]="canDelete"
      [defaultOrderDirection]="options.defaultOrderDirection"
      [extraColumnEntity]="extraColumnEntity"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
