import gql from 'graphql-tag';

export function getGeofencesByCoordinates() {
  return gql(
    `query getGeofencesByCoordinates($latitude: String!, $longitude: String!) {
    getGeofencesByCoordinates(latitude: $latitude, longitude: $longitude) {
      id
      name
      description
      geojson
      key
    }
  }
`,
  );
}
