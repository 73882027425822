export function variableStringInterpolation(data: string, variables: Record<string, unknown>): string {
  let output = data;
  const hasDelimiter = data.includes("${");
  if(!hasDelimiter || !variables){
    return data;
  }

  const regex = /\${.*?}/gm;

  data.match(regex).forEach(pattern => {
    const value = variables[pattern.slice(2, pattern.length-1)] ?? null;
    if (value) {
      output = output.replace(pattern, String(value));
    }
  })

  return output;
}
