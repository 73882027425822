import { OVEntity, OVField, OVSearchable } from '@ov-suite/ov-metadata';

@OVEntity('Product', 'shared')
export class ProductPartial {
  @OVField({ type: 'number' })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;
}

@OVEntity({ name: 'ProductConfiguration', tableName: 'product_configuration' })
export class ProductConfiguration {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => ProductPartial })
  product: ProductPartial;
}
