import { Constructor, GenericHierarchy } from './form.metadata';

type GenericHierarchyType = GenericHierarchy;

export interface SearchableMetadata {
  fields: SearchableData[];
}

export type SearchableData = SearchableParams & {
  propertyKey: string;
};

export interface SearchableParams {
  test?: string;
}

export interface PageReturn<T extends GenericHierarchyType> {
  data: T[];
  totalCount: number;
}
export type PageGet<T extends GenericHierarchyType> = (
  params: unknown //todo Fix this
) => Promise<PageReturn<T>>;
export type SingleGet<T extends GenericHierarchyType> = (
  id: number
) => Promise<T>;
export type SingleUpdate<T extends GenericHierarchyType> = (
  data: Partial<T>
) => Promise<T>;
export type SingleCreate<T extends GenericHierarchyType> = (
  data: T
) => Promise<T>;
export type SingleDelete<T extends GenericHierarchyType> = (
  id: number
) => Promise<void>;
export type SingleEdit<T extends GenericHierarchyType> = (
  id: number
) => Promise<void>;

export function getSearchableMetadata<T>(
  constructor: Constructor<T>
): SearchableMetadata {
  return Reflect.getMetadata('OVSearchable', constructor) ?? { fields: [] };
}

export function OVSearchable(params?: SearchableParams) {
  return (target: Object, propertyKey: string) => {
    const constructor = target.constructor;
    const name = 'OVSearchable';
    const allMetadata: SearchableMetadata = Reflect.getMetadata(
      name,
      constructor
    ) ?? { fields: [] };

    const data: SearchableData = {
      ...params,
      propertyKey
    };

    allMetadata.fields.push(data);

    Reflect.defineMetadata(name, allMetadata, constructor);
  };
}
