import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OvAutoService, UserAuthService } from '@ov-suite/services';
import { UserAppsDialogComponent } from './user-apps-dialog/user-apps-dialog.component';
import { Subscription } from 'rxjs';

interface INavbarDomains {
  id: number;
  title: string;
  url: string;
  hasPermission: boolean;
}

@Component({
  selector: 'ov-suite-user-apps',
  templateUrl: './user-apps.component.html',
  styleUrls: ['./user-apps.component.css'],
})
export class UserAppsComponent implements OnInit, AfterViewInit, OnDestroy {
  appsDialogOpen = false;

  navbarApps: INavbarDomains[] = [];

  userAppsSubscription: Subscription;

  selectedAppUrl = '';

  constructor(
    public dialog: MatDialog,
    private readonly ovAutoService: OvAutoService,
    private userAuth: UserAuthService
  ) {
  }

  ngOnInit() {
    this.userAppsSubscription = this.userAuth.userAppsSubject.subscribe(apps => {
      this.navbarApps = apps;
    })

    this.selectedAppUrl = window.location.host;
  }

  ngAfterViewInit() {

  }

  openDialog(element) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data = {
      apps: this.navbarApps,
      selectedAppUrl: this.selectedAppUrl
    };

    dialogConfig.position = {
      top: `${element.offsetHeight + 50}px`,
      right: `${element.offsetWidth + 15}px`,
    };

    dialogConfig.panelClass = 'ov-custom-dialog';

    this.appsDialogOpen = true;
    const dialogRef = this.dialog.open(UserAppsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.appsDialogOpen = false;
    });
  }

  closeDialog() {
    this.appsDialogOpen = false;
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.userAppsSubscription.unsubscribe();
  }
}
