import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { UiModule } from '@ov-suite/ui';
import { UserComponent } from './user.component';
import { UserRoutes } from './user.routing';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(UserRoutes), FormsModule, JwBootstrapSwitchNg2Module, UiModule],
  declarations: [UserComponent],
})
export class UserModule {}
