import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icons } from './icons.constants';

@Component({
  selector: 'ov-suite-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css'],
})
export class IconsComponent implements OnInit {
  @Input() icon: string;

  @Input() size: string;

  constructor(private readonly matIconRegistry?: MatIconRegistry, private readonly domSanitizer?: DomSanitizer) {
    // Register svg icons.
    Icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }

  ngOnInit(): void {}
}
