import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import swal from 'sweetalert2';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { environment } from '@ov-suite/helpers-angular';
import { CognitoUser } from '../../models/cognito-user.model';

@Component({
  selector: 'ov-suite-login-cmp',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  focus;

  focus1;

  test: Date = new Date();

  private readonly toggleButton;

  private readonly sidebarVisible: boolean;

  private readonly nativeElement: Node;

  authState: string;

  user: CognitoUser = new CognitoUser();

  password: string;

  newPassword: string;

  confirmPassword: string;

  requiredResponse = [];

  loading: boolean;

  errorMessage: string;

  forgotPassword: boolean;

  verificationCode: string;

  passwordsMatch: boolean;

  passwordVisible = false;

  federated = !!environment.values.cognito.oauth.domain;

  federatedText = environment.values.cognito.federatedButtonText ?? 'Sign In';

  passwordPolicyText = environment.values.cognito.passwordPolicyText;

  constructor(
    private readonly element: ElementRef,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    this.forgotPassword = false;
    this.passwordsMatch = false;
    this.loading = false;

    if (this.federated) {
      Hub.listen('auth', cb => {
        if (cb.payload.event === 'signedIn') {
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Auth._storage.setItem('amplify-signin-with-hostedUI', 'true');
          this.setAuthTokenAndNavigate(cb.payload.data.user);
        }
      });
    }
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  async signIn() {
    this.trimInput();
    this.loading = true;
    if (this.forgotPassword) {
      this.forgottenPasswordSignIn();
    } else {
      this.authenticateUser();
    }
  }

  async federatedSignIn() {
    await Auth.federatedSignIn();
  }

  forgotPasswordClick() {
    this.forgotPassword = true;
    Auth.forgotPassword(this.user.email)
      .then(response => {
        swal.fire({
          title: 'A Verification Code has been sent.',
          text: `${response.CodeDeliveryDetails.DeliveryMedium} sent to ${response.CodeDeliveryDetails.Destination}`,
          showConfirmButton: true,
          type: 'success',
        });
      })
      .catch(() => {
        swal.fire({
          title: 'Code not sent. Please try again after some time.',
          showConfirmButton: true,
          type: 'warning',
        });
      });
  }

  async authenticateUser() {
    const user = await Auth.signIn(this.user.email, this.password).catch(() =>
      swal.fire({
        title: 'Incorrect Username or password',
        timer: 2000,
        showConfirmButton: false,
        type: 'warning',
      }),
    );

    this.authState = user.challengeName;

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      this.requiredResponse = requiredAttributes;
      if (!!this.newPassword && this.newPassword.length > 0 && this.passwordsMatch) {
        const loggedUser = await Auth.completeNewPassword(
          user,
          this.newPassword,
          // [user] = the Cognito User Object
          // OPTIONAL, the required attributes (From the documentation.)
          {
            name: this.user.name ? this.user.name : user.challengeParam.userAttributes.name,
            family_name: this.user.familyName ? this.user.familyName : user.challengeParam.userAttributes.family_name,
          }, // If not manually set from the user. Use the authentication attributes
        ).catch(err => {
          swal.fire({
            title: err.message,
            timer: 2000,
            showConfirmButton: false,
            type: 'warning',
          });
        });

        if (loggedUser) {
          this.setAuthTokenAndNavigate(loggedUser);
        }
      }
      this.loading = false;
    } else {
      // The user directly signs in
      this.setAuthTokenAndNavigate(user);
    }
  }

  forgottenPasswordSignIn() {
    if (!!this.newPassword && this.newPassword.length > 0 && this.passwordsMatch) {
      Auth.forgotPasswordSubmit(this.user.email, this.verificationCode, this.newPassword)
        .then(() => {
          return Auth.signIn(this.user.email, this.newPassword);
        })
        .then(user => {
          this.setAuthTokenAndNavigate(user);
        })
        .catch(e => {
          swal.fire({
            title: 'Did not change Password.',
            text: e?.message,
            showConfirmButton: true,
            type: 'warning',
          });
          this.loading = false;
        });
    }
  }

  trimInput() {
    Object.keys(this.user).forEach(key => {
      if (this.user[key]) {
        this.user[key] = this.user[key].trim();
      }
    });
  }

  confirmPasswordsMatch() {
    this.passwordsMatch = this.newPassword === this.confirmPassword;
  }

  setAuthTokenAndNavigate(user) {
    if (user.signInUserSession) {
      this.route.params
        .subscribe(() => {
          const urlParams = new URLSearchParams(window.location.search);
          const url = urlParams.get('redirect');
          if (url) {
            window.location.assign(url);
          } else {
            this.router.navigate(['/dashboard']).then(() => {
              this.loading = false;
            });
          }
        })
        .unsubscribe();
    }
    this.loading = false;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
