import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { FormComponent } from '@ov-suite/ui';
import { CompiledFieldData } from '@ov-suite/ov-metadata';
import { hasFeaturePermission, PermissionAction } from '@ov-suite/authguard-angular';
import { Profile, User } from '@ov-suite/models-idm';
import { Auth } from '@aws-amplify/auth';
import gql from 'graphql-tag';
import { OvAutoService } from '@ov-suite/services';
import { FeaturesConfig } from '../../features.config';
import { AWSStorageService } from '../../services/aws-storage.service';
import { CognitoUser } from '../../models/cognito-user.model';

export class DetectChanges {
  newChanges: CompiledFieldData[] = [];

  diff(formData: unknown, field: CompiledFieldData): void {
    if (formData && field) {
      if (formData[field.propertyKey] !== field.value) {
        this.newChanges.push(field);
      } else {
        this.removeField(field);
      }
    }
  }

  removeField(field: CompiledFieldData): void {
    this.newChanges = this.newChanges.filter(data => data.propertyKey !== field.propertyKey);
  }
}

interface FileUpload {
  target: {
    files: {
      item: (number: number) => File;
      length: number;
    };
  };
}

@Component({
  // moduleId: module.id,
  selector: 'ov-suite-user-cmp',
  templateUrl: 'user.component.html',
})
export class UserComponent implements OnInit {
  cognitoUser: CognitoUser = new CognitoUser();

  uploadedImage: File;

  loading: boolean;

  profileForm = Profile;

  userData: Profile;

  @ViewChild('form') form: FormComponent;

  detect = new DetectChanges();

  interval = null;

  canUpdateProfile = false;

  constructor(private readonly storageService: AWSStorageService, private readonly ovAutoService: OvAutoService) {}

  ngOnInit(): void {
    this.checkPermissions();
    this.loadCurrentUser().catch(err => console.log(err));
  }

  checkPermissions() {
    hasFeaturePermission(FeaturesConfig.ViewProfile, PermissionAction.UPDATE).then(res => {
      this.canUpdateProfile = res;
    });
  }

  async loadCurrentUser() {
    Auth.currentUserInfo()
      .then(user => {
        const { attributes } = user;
        this.cognitoUser = new CognitoUser().fromAWS(attributes);

        const initial = new Profile();
        initial.id = 123;
        initial.name = this.cognitoUser.name;
        initial.familyName = this.cognitoUser.familyName;
        initial.email = this.cognitoUser.email;
        initial.phoneNumber = this.cognitoUser.phoneNumber;
        this.userData = initial;
      })
      .then(async () => {
        const res = await this.ovAutoService.apollo
          .use('idmlink')
          .query({
            query: gql(
              `query GetUserProfile($cognitoId: String!) {
               getUserProfile(cognitoId: $cognitoId) {
                  id
                  smsNotification
                  emailNotification
                  notificationWindowStart
                  notificationWindowEnd
               }
          }`,
            ),
            fetchPolicy: 'no-cache',
            variables: {
              cognitoId: this.cognitoUser.id,
            },
          })
          .toPromise();
        // @ts-ignore
        const item: User = res.data.getUserProfile as User;
        const obj = {
          id: item.id,
          emailNotification: item.emailNotification,
          notificationWindowEnd: item.notificationWindowEnd,
          notificationWindowStart: item.notificationWindowStart,
          smsNotification: item.smsNotification,
        };
        this.userData = { ...this.userData, ...obj };
      });
  }

  async onSave() {
    this.detect.newChanges = [];
    let proceed = true;
    const formData = (await this.form.submit(true)) as CognitoUser;
    proceed = !!formData;

    if (!proceed) return;

    this.cognitoUser = new CognitoUser(
      this.cognitoUser.id,
      this.cognitoUser.username,
      formData.name,
      formData.familyName,
      this.cognitoUser.preferredName,
      formData.email,
      formData.phoneNumber,
      this.cognitoUser.avatarUrl,
    );
    await this.updateUser();
  }

  async updateDbUser() {
    const {
      name,
      familyName,
      password,
      confirmPassword,
      newPassword,
      emailNotification,
      smsNotification,
      ...rest
    } = this.userData;

    await this.ovAutoService.apollo
      .use('idmlink')
      .mutate({
        mutation: gql(
          `mutation UpdateUserProfile($data: UserUpdateProfileInput!) {
               updateUserProfile(data: $data) {
                  id
               }
          }`,
        ),
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            firstName: name,
            lastName: familyName,
            cognitoId: this.cognitoUser.id,
            emailNotification: !!emailNotification,
            smsNotification: !!smsNotification,
            ...rest,
          },
        },
      })
      .toPromise();
  }

  async updateUser() {
    this.trimInput();
    this.loading = true;

    const user = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(user, this.cognitoUser.toAWS())
      .then(async () => {
        if (!!this.form?.data['password'] && !!this.form?.data['newPassword'] && !!this.form?.data['confirmPassword']) {
          this.updateDbUser().then(async () => {
            await this.saveNewPassword(user);
          });
        } else {
          this.updateDbUser().then(async () => {
            await swal.fire({
              title: 'Success!',
              text: 'Information updated successfully',
              type: 'success',
              timer: 2000,
              onClose: () => {
                window.location.reload();
              },
            });
          });
        }
      })
      .catch(async err => {
        console.log(err);
        await swal.fire({
          title: 'Something went wrong!',
          text: 'Information not updated.',
          footer: err.message,
          type: 'error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async saveNewPassword(user) {
    Auth.changePassword(user, this.form?.data['password'], this.form?.data['newPassword'])
      .then(async res => {
        if (res === 'SUCCESS') {
          await swal.fire({
            title: 'Success!',
            text: 'Password has been changed.',
            type: 'success',
            timer: 2000,
            onClose: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch(async err => {
        console.log(err);
        await swal.fire({
          title: 'Password did not change!',
          text: 'Please make sure your password is 8 characters long and includes a uppercase and lowercase letter.',
          footer: err.message,
          type: 'error',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onFileUpload(event: FileUpload) {
    const imageFormatsAllowed = ['jpeg', 'gif', 'png'];
    if (!!event.target.files && event.target.files.length > 0) {
      this.uploadedImage = event.target.files.item(0);

      if (!imageFormatsAllowed.some(format => this.uploadedImage.type.includes(format))) {
        this.uploadedImage = null;
      } else {
        const imageElement = document.getElementById('uploadedImage') as HTMLImageElement;

        imageElement.src = window.URL.createObjectURL(this.uploadedImage);
        this.loading = true;
        (await this.storageService.uploadFile(this.uploadedImage)).subscribe(
          res => {
            this.cognitoUser.avatarUrl = res.body.thumbnail;
            this.loading = false;
          },
          () => {
            this.loading = false;
          },
        );
      }
    }
  }

  trimInput() {
    Object.keys(this.cognitoUser).forEach(key => {
      if (this.cognitoUser[key]) {
        if (typeof this.cognitoUser[key] === 'string') {
          this.cognitoUser[key] = this.cognitoUser[key].trim();
        }
      }
    });
  }

  detectChanges(event: CompiledFieldData) {
    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.detect.diff(this.form.data, event);
      const passwordField = this.detect.newChanges.find(field => field.propertyKey === 'password');
      if (passwordField) {
        this.detect.removeField(passwordField);
      }
    }, 400);
  }
}
