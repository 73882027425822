import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { v4 } from 'uuid';
import { AbstractValueAccessor, MakeProvider } from './abstruct-value-accessor';

@Component({
  selector: 'ov-suite-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [MakeProvider(InputComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent<T> extends AbstractValueAccessor<T | boolean> {
  @Input() id: string = v4();

  @Input() data: T[] | number | string | boolean;

  @Input() type: string;

  @Input() placeholder: string | number;

  @Input() tooltip: string;

  @Input() disabled: boolean;

  @Input() inputLabel: string;

  @Input() danger: boolean;

  @Input() step = '1';

  @Input() title: string; // todo: cleanup title and inputLabel

  // Exclusive to Multi-Select
  @Input() label: string[];

  @Input() statusColor: string;

  @Output() submitOverride = new EventEmitter();

  @Input() style: Record<string, unknown> = {};

  @Input() minDate: NgbDateStruct = { day: 1, month: 2, year: 1900 };

  @Input() showTime = true;

  @Input() hideClearButton: boolean;

  dateValue: NgbDate;

  @ViewChild('dateRange', { static: false }) dateRange;

  constructor(private readonly calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    super();
  }

  getDate() {
    if (!this.dateValue) {
      if (this.value) {
        const jsDate: Date = typeof this.value === 'string' ? moment(this.value).toDate() : (this.value as unknown as Date);
        this.dateValue = NgbDate.from({
          year: jsDate.getFullYear(),
          day: jsDate.getDate(),
          month: jsDate.getMonth() + 1,
        });
      }
    }
    return this.dateValue;
  }

  setDate(date: NgbDate) {
    this.dateValue = date;
    if (this.calendar.isValid(date)) {
      const jsDate = new Date(date.year, date.month - 1, date.day);
      const minDate = new Date(this.minDate?.year, this.minDate?.month - 1, this.minDate?.day);
      if (jsDate >= minDate) {
        this.valueChange(jsDate as unknown as T);
      }
    }
  }

  dateChange() {
    if (this.calendar.isValid(this.dateValue)) {
      const ngbDate = this.dateValue;
      const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      this.valueChange(jsDate as unknown as T);
    }
  }

  valueChange(item: T) {
    if (item !== undefined) {
      // null is acceptable
      this.submitOverride.emit(item);
      this.writeValue(item);
      this.value = item;
      this.onChange(item);
    }
  }

  check() {
    this.val = !this.val;
    this.onChange(this.val);
  }

  compare(object1, object2): boolean {
    return object1 && object2 ? object1.id === object2.id : object1 === object2;
  }

  clearDateRange(): void {
    this.dateRange.clearDateRange();
  }
}
