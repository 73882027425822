<mat-card class="example-card shadow-none">
  <div class="filter-card-header">
    <div class="card-title">ADVANCED SEARCH</div>
    <span
      type="button"
      (click)="closeDialog()"
    >
      <ov-suite-icons icon="cross" size="icon-md"></ov-suite-icons>
    </span>
  </div>
  <mat-card-content>
    <div class="row">
      <div *ngFor="let field of fields, let i = index" class="col-6" [ngSwitch]="field.type">

        <div *ngSwitchCase="'date-time'">
          <label [for]="field.key">{{ field.title }}</label>
          <div class="input-group">
            <input
              [value]="getDisplayDate(field)"
              (dateSelect)="onDateSelection($event, field, datepicker)"
              [dayTemplate]="t"
              [autoClose]="'outside'"
              container="body"
              class="form-control"
              ngbDatepicker
              datepickerClass="input-datepicker-popover"
              #datepicker="ngbDatepicker"
              placeholder="Date Range"
              outsideDays="hidden"
              tabindex="-1"
            >
            <span
              type="button"
              *ngIf="field.value.from && field.value.to"
              (click)="clearFieldInput(field)"
              class="input-group-text"
            >
            <ov-suite-icons icon="cross" size="icon-sm"></ov-suite-icons>
          </span>
            <span
              type="button"
              (click)="toggleDatepicker(datepicker)"
              class="input-group-text"
            >
            <ov-suite-icons icon="calendar" size="icon-md"></ov-suite-icons>
          </span>
            <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date, field.value)"
                  [class.faded]="isHovered(date, field.value) || isInside(date, field.value)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
            </ng-template>
          </div>
        </div>

        <div *ngSwitchCase="'string'" class="form-group">
          <label [for]="field.key">{{ field.title }}</label>
          <div class="input-group">
            <input
              [(ngModel)]="field.value"
              (ngModelChange)="onInputChange($event, field)"
              [value]="field.value"
              [id]="field.id"
              autocomplete="off"
              [name]="field.id"
              type="text"
              class="form-control"
            />
            <span
              type="button"
              *ngIf="field.value"
              (click)="clearFieldInput(field)"
              class="input-group-text"
            >
            <ov-suite-icons icon="cross" size="icon-sm"></ov-suite-icons>
          </span>
          </div>
        </div>

        <div *ngSwitchCase="'number'" class="form-group">
          <label [for]="field.key">{{ field.title }}</label>
          <div class="input-group">
            <input
              [(ngModel)]="field.value"
              (ngModelChange)="onInputChange($event, field)"
              [value]="field.value"
              [id]="field.id"
              [name]="field.id"
              autocomplete="off"
              type="number"
              class="form-control"
            />
            <span
              type="button"
              *ngIf="field.value"
              (click)="clearFieldInput(field)"
              class="input-group-text"
            >
            <ov-suite-icons icon="cross" size="icon-sm"></ov-suite-icons>
          </span>
          </div>
        </div>

        <div *ngSwitchCase="'dropdown'" class="form-group">
          <label [for]="field.key">{{ field.title }}</label>
          <div class="input-group">
            <select
              [(ngModel)]="field.value"
              (ngModelChange)="onInputChange($event, field)"
              [value]="field.value"
              [id]="field.id"
              [name]="field.id"
              autocomplete="off"
              class="form-control"
            >
              <option value="null" disabled selected>Select {{ field.title }}</option>
              <option
                *ngFor="let item of dropdownOptions[field.key]"
                [value]="item.value"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="grouped-card-actions">
      <button mat-button color="warn" (click)="closeDialog()">Cancel</button>
      <button mat-button color="accent" (click)="resetFields()">Clear</button>
    </div>
    <button mat-button color="primary" (click)="applyFilter()">Apply</button>
  </mat-card-actions>
</mat-card>
