import Auth from '@aws-amplify/auth';

export class SessionStorage {
  static setSelectedDomain(value: string) {
    window.sessionStorage.setItem('selectedDomain', value);
  }

  static getSelectedDomain() {
    return window.sessionStorage.getItem('selectedDomain');
  }

  static isValidDomain(value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then(session => {
        const currentUserTokenInfo = session.getIdToken().decodePayload();
        const domains = 'lambdaDomainIds';
        currentUserTokenInfo[domains] = JSON.parse(currentUserTokenInfo[domains]);
        resolve(currentUserTokenInfo[domains].some(domainId => domainId === value));
      });
    });
  }

  static setDomainLocation(domain: string, value: string) {
    window.sessionStorage.setItem(`domainLocation_${domain}`, value);
  }

  static getDomainLocation(domain: string) {
    const loc = window.sessionStorage.getItem(`domainLocation_${domain}`);
    if (!loc) {
      return null;
    }

    return JSON.parse(loc);
  }

  static setSelectedMapProvider(value: string) {
    window.sessionStorage.setItem('selectedMapProvider', value);
  }

  static getSelectedMapProvider() {
    return window.sessionStorage.getItem('selectedMapProvider');
  }
}
