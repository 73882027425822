import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { OvAutoService } from '@ov-suite/services';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { QRCodeModule } from 'angularx-qrcode';
import { TableComponent } from './table/table.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateOrEditComponent } from './create-or-edit/create-or-edit.component';
import { FormComponent } from './form/form.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { HierarchyBreadcrumbComponent } from './hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { InputComponent } from './input/input.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { HierarchyTabsComponent } from './hierarchy-tabs/hierarchy-tabs.component';
import { PermissionMatrixComponent } from './permission-matrix/permission-matrix.component';
import { UserProfileWidgetComponent } from './user-profile-widget/user-profile-widget.component';
import { AgmMapComponent } from './agm-map/agm-map.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { TableTopBarComponent } from './table-top-bar/table-top-bar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PillMultiSelectComponent } from './pill-multi-select/pill-multi-select.component';
import { DateTimeModule } from './date-time/date-time.module';
import { RecurrenceComponent } from './reccurence/recurrence.component';
import { MapDeepObjectChangeDiff, MapDeepObjects } from './pipes/deep-object.pipe';
import { NormaliseString } from './pipes/text-transform.pipe';
import { CardTableComponent } from './card-table/card-table.component';
import { PopupTableComponent } from './popup-table/popup-table.component';
import { DateRangeComponent } from './date-time/date-range.component';
import { HierarchyListComponent } from './hierarchy-list/hierarchy-list.component';
import { TrudaHierarchyListComponent } from './hierarchy-list/sub-lists/truda-list/truda-hierarchy-list.component';
import { CeramicHierarchyListComponent } from './hierarchy-list/sub-lists/ceramic-list/ceramic-hierarchy-list.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { TopFiltersComponent } from './top-filters/top-filters.component';
import { UiIconsModule } from './ui-icons.module';

import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { DomainsComponent } from './dialogs-mat/domains/domains.component';
import { UserAccountComponent } from './dialogs-mat/user-account/user-account.component';
import { UserAppsComponent } from './dialogs-mat/user-apps/user-apps.component';
import { UserAppsDialogComponent } from './dialogs-mat/user-apps/user-apps-dialog/user-apps-dialog.component';
import { UserAccountDialogComponent } from './dialogs-mat/user-account/user-account-dialog/user-account-dialog.component';
import { DomainsDialogComponent } from './dialogs-mat/domains/domains-dialog/domains-dialog.component';

import { DynamicDatePickerComponentNew } from './dynamic-date-picker/dynamic-date-picker.component';
import { DateRangePickerComponentNew } from './dynamic-date-picker/date-range-picker/date-range-picker.component';
import { IconsComponent } from './icons/icons.component';
import { MatDialogComponent } from './dialogs-mat/mat-dialog/mat-dialog.component';
import { MatDialogContentComponent } from './dialogs-mat/mat-dialog/mat-dialog-content/mat-dialog-content.component';
import { AdvancedSearchDialogComponent } from './advanced-search/advanced-search-dialog/advanced-search-dialog.component';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { HierarchyListActionsComponent } from './hierarchy-list/hierarchy-list-actions/hierarchy-list-actions.component';
import { DrawableMapComponent } from './drawable-map/drawable-map.component';

export interface ApiOptions {
  apiUrl?: string;
  imageUploadUrl?: string;
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    DateTimeModule,
    MatOptionModule,
    MatSelectModule,
    LeafletModule,
    QRCodeModule,
    UiIconsModule,
    MatSliderModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
    MatListModule,
    MatGridListModule,
    MatSidenavModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    LeafletDrawModule,
  ],
  declarations: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    PopupTableComponent,
    InputComponent,
    TreeSelectComponent,
    HierarchyBreadcrumbComponent,
    ImageUploadComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    MapDeepObjectChangeDiff,
    NormaliseString,
    RecurrenceComponent,
    CardTableComponent,
    DateRangeComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    AdvancedSearchComponent,
    TopFiltersComponent,
    DomainsComponent,
    UserAccountComponent,
    UserAppsComponent,
    UserAppsDialogComponent,
    UserAccountDialogComponent,
    DomainsDialogComponent,
    DynamicDatePickerComponentNew,
    DateRangePickerComponentNew,
    IconsComponent,
    MatDialogComponent,
    MatDialogContentComponent,
    AdvancedSearchDialogComponent,
    HierarchyListActionsComponent,
    DrawableMapComponent,
  ],
  exports: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    InputComponent,
    PopupTableComponent,
    ImageUploadComponent,
    HierarchyBreadcrumbComponent,
    TreeSelectComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    PermissionMatrixComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    MapDeepObjectChangeDiff,
    NormaliseString,
    RecurrenceComponent,
    CardTableComponent,
    DateRangeComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    AdvancedSearchComponent,
    TopFiltersComponent,
    DynamicDatePickerComponentNew,
    DateRangePickerComponentNew,
    AdvancedSearchDialogComponent,
    DrawableMapComponent,
    IconsComponent,
    MatDialogComponent,
  ],
  providers: [OvAutoService],
})
export class UiModule {
  static forRoot(options: ApiOptions): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [{ provide: 'OPTIONS', useValue: options }],
    };
  }

  static forChild() {
    return {
      ngModule: UiModule,
    };
  }
}

export { RouteInfo, ChildrenItems } from './sidebar/sidebar.model';
export { NavbarComponent } from './navbar/navbar.component';

export * from './form/form.component';
export * from './hierarchy-tabs/hierarchy-tabs.model';
