import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@ov-suite/helpers-angular';
import { Auth } from '@aws-amplify/auth';
import { UserAccount } from '../user-account';

@Component({
  selector: 'ov-suite-user-account-dialog',
  templateUrl: './user-account-dialog.component.html',
  styleUrls: ['./user-account-dialog.component.css']
})
export class UserAccountDialogComponent implements OnInit {
  userAccount: UserAccount;

  @Output() signOutEvent = new EventEmitter();

  constructor(private readonly dialogRef: MatDialogRef<UserAccountDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.userAccount = data.user;
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  manageUserAccount() {
    window.location.assign(`${environment.values.webUrl.idm}/user`);
  }

  // TODO Move this into user auth service and throw the error
  signOut() {
    const federated = !!environment.values.cognito.oauth.domain;
    this.signOutEvent.emit();
    // Auth.SignOut is broken with Federated Login, this is a hack makes it work
    if (federated) {
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      Auth._storage.setItem('amplify-signin-with-hostedUI', 'true');
    }
    Auth.signOut()
      .then(signOutData => console.log(signOutData))
      .catch(e => {
        throw new Error(e.message);
      });

    if (!federated) {
      window.location.href = `${environment.values.webUrl.idm}/auth/login`;
    }
  }
}
