import { Component, Input, OnInit } from '@angular/core';
import { AutoService, OvAutoService } from '@ov-suite/services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Constructor, GenericHierarchy } from '@ov-suite/ov-metadata';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-hierarchy-breadcrumb',
  templateUrl: './hierarchy-breadcrumb.component.html',
  styleUrls: ['./hierarchy-breadcrumb.component.scss'],
})
export class HierarchyBreadcrumbComponent<T extends GenericHierarchyType> implements OnInit {
  @Input() service: AutoService<T>;

  @Input() ovAutoService: OvAutoService;

  @Input() formClass: Constructor<T>;

  data: T[];

  showTooltip = [];

  timeoutRef = [];

  all: T[];

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getData();
      }
    });

    this.getData();
  }

  getData(): void {
    const entityId = this.activatedRoute.snapshot.queryParams['_parentId'] ?? -1;
    this.data = new Array(0) as [T];

    if (this.service) {
      this.service.listAncestors(+entityId).then(result => {
        this.data = result.map(r => {
          return { ...r, id: r.id > 0 ? r.id : null } as T;
        }) as [T];
        this.showTooltip = result.map(() => false);
      });
    } else {
      this.ovAutoService.listAncestors(this.formClass, 'shared', +entityId).then(result => {
        this.data = result.map(r => {
          return { ...r, id: r.id > 0 ? r.id : null } as T;
        }) as [T];
        this.showTooltip = result.map(() => false);
      });
    }
  }

  viewDropdown(i: number) {
    clearTimeout(this.timeoutRef[i]);
    this.showTooltip = this.showTooltip.map(() => false);
    this.showTooltip[i] = true;
  }

  viewMenuDropdown(i: number) {
    clearTimeout(this.timeoutRef[i]);
    this.showTooltip = this.showTooltip.map(() => false);
    this.showTooltip[i] = true;
  }

  setDropdownTimeout(i: number) {
    clearTimeout(this.timeoutRef[i]);
    this.timeoutRef[i] = setTimeout(() => {
      this.showTooltip[i] = false;
    }, 1000);
  }
}
