import { Component, Inject, OnInit } from '@angular/core';
import { RouteInfo } from '../sidebar/sidebar.model';
import { AdminLayoutParams } from './admin-layout.types';
import { UserAuthService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
})
export class AdminLayoutComponent implements OnInit {
  public routes: RouteInfo[] = [];

  public projectName = '';

  sideNavOpen: boolean;

  constructor(
    @Inject('SIDEBAR_OPTIONS') options: AdminLayoutParams,
    private userAuth: UserAuthService
    ) {
    this.routes = options.sidebarRoutes;
    this.projectName = options.projectName;
  }

  ngOnInit() {
    console.log(JSON.stringify(sessionStorage))

    // TODO define a constant instead of duplicating this 4 times
    if (sessionStorage.getItem('sidebar-state') === null) {
      this.sideNavOpen = true;
      sessionStorage.setItem('sidebar-state', JSON.stringify(this.sideNavOpen));
    } else {
      this.sideNavOpen = JSON.parse(sessionStorage.getItem('sidebar-state'));
    }

    this.userAuth.setUserRoutes(this.routes);
  }

  toggleDrawer(state: boolean) {
    sessionStorage.setItem('sidebar-state', JSON.stringify(state));
    this.sideNavOpen = state;
  }

  isMac(): boolean {
    const platform = navigator.platform.toUpperCase();
    return platform.includes('MAC') || platform.includes('IPAD');
  }
}
