import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';

@OVEntity('VehicleClassStatus', 'shared')
export class VehicleClassStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('VehicleClass', 'shared')
@OVForm([
  ['#Vehicle Class Details'],
  ['vehicleClassId', 'name'],
  ['description', 'classType'],
  ['#Measurements'],
  ['axelWeightInformation', 'weightLoadAllowed', ''],
  ['length', 'height', 'width']
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<VehicleClass>([
  {
    key: 'vehicleClassId',
    title: 'Vehicle Class ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string'
  },
  {
    title: 'Weight Load Allowed',
    type: 'other',
    action: item =>
      item.weightLoadAllowed ? `${item.weightLoadAllowed} g` : '',
    keys: ['weightLoadAllowed']
  },
  {
    title: 'Dimensions',
    type: 'other',
    action: item => {
      const message = `${item.length} cm x ${item.height} cm x ${item.width} cm`;
      return message.includes('null') ? '' : message;
    },
    keys: ['length', 'height', 'width']
  }
])
export class VehicleClass {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Class ID',
    tooltip: 'Used as a unique id throughout the site.',
    placeholder: 'Required',
    required: true
  })
  vehicleClassId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Class Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Class Type',
    placeholder: 'Required',
    required: true
  })
  classType: string;

  @OVField({
    type: 'number',
    title: 'Weight Load Allowed',
    required: true
  })
  weightLoadAllowed: number;

  @OVField({
    type: 'string',
    title: 'Axel Weight Information',
    required: false
  })
  axelWeightInformation?: string;

  @OVField({
    type: 'number',
    title: 'Length'
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height'
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width'
  })
  width: number;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => VehicleClassStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
    required: false
  })
  status: VehicleClassStatus;
}
