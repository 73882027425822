import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { Personnel, PersonnelStatus } from '@ov-suite/models-idm';

@Injectable()
export class PersonnelService extends AutoService<Personnel> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), Personnel, 'Personnel', 'Personnel', 'idmlink');
  }
}

@Injectable()
export class PersonnelStatusService extends AutoService<PersonnelStatus> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), PersonnelStatus, 'PersonnelStatus', 'PersonnelStatuses', 'idmlink');
  }
}
