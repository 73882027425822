import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { PersonnelPosition } from '@ov-suite/models-idm';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: PersonnelPosition,
      api: 'idmlink',
      createTitle: 'Personnel Position',
      route: ['management/personnel-position'],
      tableTitle: 'Personnel Positions',
      tabs: [
        {
          title: 'Personnel',
          path: '/management/personnel',
        },
        {
          title: 'Personnel Positions',
          path: '/management/personnel-position',
        },
      ],
    }),
  ],
})
export class PersonnelPositionModule {}
