/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AutoService, OvAutoService } from '@ov-suite/services';
import {
  Constructor,
  FieldMetadata,
  FieldParams,
  GenericHierarchy,
  getFieldMetadata,
  PageReturn,
  SearchableMetadata,
} from '@ov-suite/ov-metadata';
import { ColumnData, ColumnDataBase, QueryParams, queryParams } from '@ov-suite/helpers-shared';
import { hasPermissionByFeature, PermissionAction, verifyPermission } from '@ov-suite/authguard-angular';
import { cloneDeep } from 'lodash';
import { BulkUploadComponent } from '../bulk-upload/bulk-upload.component';

type GenericHierarchyType = GenericHierarchy;

export interface ObjectModel<T extends GenericHierarchyType> {
  table: ColumnData<T>[];
  filter: ColumnData<T>[];
  fields: FieldParams[];
  'table-display': ColumnData<T>[];
}

export interface AdvancedSearchChange {
  filter: Record<string, string[] | number[]>;
  query: Record<string, string[]>;
  search: Record<string, string[]>;
}

export type CustomColumnData<T extends GenericHierarchyType> = ColumnData<T> & { keys: string[]; key: string };

@Component({
  selector: 'ov-suite-hierarchy-table',
  templateUrl: './hierarchy-list.component.html',
  styleUrls: ['./hierarchy-list.component.scss'],
})
export class HierarchyListComponent<T extends GenericHierarchyType> implements OnInit {
  // To be Deprecated
  @Input() service: AutoService<T>;

  // To Be deprecated
  @Input() ovAutoService: OvAutoService;

  // To be Deprecated in favour of Fetching from Metadata
  @Input() api: string;

  @Input() title: string;

  @Input() formClass: Constructor<T>;

  @Input() hasBulkUpload = true;

  @Input() hasBulkExport = true;

  @Input() excludeColumns = 1;

  @Input() showTopBar = false;

  @Input() filterEnabled = true;

  @Input() showPageSelect = true;

  @Input() pageChangeEnabled = true;

  @Input() selectableRows = false;

  @Input() hideAddButton = false;

  @Input() showFiller = true;

  @Input() showScroll = false;

  @Input() editableRows = false;

  @Input() dropdownData = {};

  @Input() overrideServiceMethod = null;

  @Input() dataOverride: T[];

  @Input() emptyComponent;

  @Input() bulkActionComponent;

  @Input() searchDropdownPosition?: string;

  @Input() extraColumnEntity?: string;

  @Input()
  set reFetch(reFetch: number) {
    if (reFetch > 0) {
      this.getData();
    }
  }

  @Output() itemSelect = new EventEmitter<T[]>();

  @Output() editedItems = new EventEmitter<T[]>();

  @Output() executedBulkUpload = new EventEmitter();

  @Output() originalData = new EventEmitter<T[]>();

  @Input() overrideAddButtonClick = false;

  @Output() addButtonClick = new EventEmitter<T>();

  @Input() overrideEditButtonClick = false;

  @Output() editButtonClick = new EventEmitter<T>();

  @Output() deleteButtonClick = new EventEmitter<T>();

  loading = false;

  customizing = false;

  selected: T[];

  _idFilter: Record<string, (string | number)[]>;

  _hideColumnKeys: string[] = [];

  updatedItems: Record<number, { newItem: T; oldItem: T }>;

  @Input() hasDeletePermission = true;

  @Input() hasEditPermission = true;

  @Input() hasCreatePermission = true;

  @Input() defaultOrderDirection: 'ASC' | 'DESC' = 'ASC';

  @ViewChild('uploadComponent') uploadComponent: BulkUploadComponent;

  @Input() enableRefresh = false;

  filter: Record<string, QueryParams[]> = {};

  search: Record<string, QueryParams[]> = {};

  query: Record<string, QueryParams[]> = {};

  @Input('filter') set setFilter(filter: Record<string, QueryParams[]>) {
    this.filter = filter;
    if (!this.loading) {
      this.getData();
    }
  }

  @Input('search') set setSearch(search: Record<string, QueryParams[]>) {
    this.filter = search;
    if (!this.loading) {
      this.getData();
    }
  }

  @Input('query') set setQuery(query: Record<string, QueryParams[]>) {
    this.filter = query;
    if (!this.loading) {
      this.getData();
    }
  }

  @Input()
  set advancedSearchChange(event: AdvancedSearchChange) {
    this.filterChange(event);
  }

  get advancedSearchChange(): AdvancedSearchChange {
    return this.customFilter;
  }

  @Input()
  set hideColumnKeys(event: string[]) {
    this._hideColumnKeys = event;
  }

  get hideColumnKeys() {
    return this._hideColumnKeys;
  }

  data: T[] = [];

  originalDataCopy: T[] = [];

  // Paging Variables
  totalCount = 0;

  _page = 0;

  set page(page: number) {
    this._page = page;
    this.fetchDataByUrl({ [queryParams.PAGE]: page });
  }

  get page() {
    return this._page;
  }

  pageSize = 10;

  searchableMetadata: SearchableMetadata;

  searchFilter = '';

  // Checks what is not being filtered anymore and sets url params to null or removes them
  filterHistory: Record<string, string[]> = {};

  orderColumn = 'id';

  orderDirection: 'ASC' | 'DESC' = this.defaultOrderDirection;

  objectModel: ObjectModel<T>;

  // Standard Metadata
  metadata: FieldMetadata<T>;

  // Table Columns
  rawTableMetadata: ColumnData<T>[];

  // Types we do not need to search on
  forbiddenTypes = ['pills'];

  buttons: ColumnData<T>[] = [];

  customFilter: AdvancedSearchChange = {
    filter: {},
    query: {},
    search: {},
  };

  @Input() import: () => void = () => {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly autoService: OvAutoService,
  ) {
    this.route.data.subscribe(perm => {
      if (perm?.feature?.id) {
        hasPermissionByFeature(perm.feature.id).then(permission => {
          this.hasDeletePermission = verifyPermission(permission, PermissionAction.DELETE);
          this.hasEditPermission = verifyPermission(permission, PermissionAction.UPDATE);
          this.hasCreatePermission = verifyPermission(permission, PermissionAction.CREATE);
        });
      }
    });
  }

  ngOnInit() {
    this.orderDirection = this.defaultOrderDirection;
    this.objectModel = this.customLoadMetaData();
    if (!this.loading) {
      this.getData();
    }
    this.getMetadata();
  }

  getMetadata() {
    const extraFields: ColumnData<T>[] = [];

    if (this.excludeColumns >= 1) {
      const buttons = [];
      const editButton = {
        tooltip: 'Edit',
        classes: 'btn-primary btn-sm fa pt-1 pb-1 fa-pencil',
        action: (item: T) => {
          this.edit(item);
        },
      };
      const deleteButton = {
        tooltip: 'Delete',
        classes: 'btn-primary ml-1 btn-sm fa fa-trash-o',
        action: async (item: T) => {
          await this.delete(item);
        },
      };

      if (this.excludeColumns === 1) {
        if (this.hasEditPermission) {
          buttons.push(editButton);
        }

        if (this.hasDeletePermission) {
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 2) {
        if (this.hasDeletePermission) {
          buttons.push(deleteButton);
        }
      }

      if (this.excludeColumns === 3) {
        if (this.hasEditPermission) {
          buttons.push(editButton);
        }
      }

      if (this.excludeColumns < 4 && buttons.length) {
        extraFields.push({
          type: 'buttons',
          title: 'Fast Actions',
          buttons,
          keys: [],
          disableFiltering: true,
          disableSorting: true,
          id: 'fast_actions',
        });
      }
    }
    if (this.excludeColumns === 0) {
      extraFields.push({
        type: 'status',
        title: 'Status',
        key: 'status',
        id: 'status',
      });
    }
    return extraFields;
  }

  customLoadMetaData(): ObjectModel<T> {
    this.metadata = getFieldMetadata<T>(this.formClass);

    const table = this.prepareTableMetadata(this.metadata['table'] as CustomColumnData<T>[]);

    const object: ObjectModel<T> = {
      table,
      filter: this.prepareFilterableMetadata(table),
      fields: this.prepareFieldsMetadata(this.metadata['fields'], table),
      'table-display': [...this.prepareDisplayFields(this.metadata['table']), ...this.getMetadata()],
    };

    this.rawTableMetadata = object['table-display'];

    return object;
  }

  prepareDisplayFields(fields: ColumnData<T>[]): ColumnData<T>[] {
    const hiddenFields = this.hideColumnKeys ? this.hideColumnKeys : [];

    return fields.filter(item => !hiddenFields.includes(item.hideColumnKey));
  }

  prepareFilterableMetadata(tableFields: ColumnData<T>[]): ColumnData<T>[] {
    return tableFields.filter(field => !field.disableFiltering);
  }

  prepareFieldsMetadata(fields: FieldParams[], tableFields: ColumnData<T>[]): FieldParams[] {
    const tableKeys = tableFields.map(field => (field as ColumnDataBase<T>).key).filter(key => key);

    return fields.filter(item => tableKeys.includes(item['propertyKey']));
  }

  prepareTableMetadata(meta: CustomColumnData<T>[]): ColumnData<T>[] {
    const formatted: ColumnData<T>[] = [];

    meta.forEach(col => {
      // Break keys apart
      if (col.keys) {
        const { keys } = col;

        keys.forEach(key => {
          const temp: CustomColumnData<T> = { ...col };

          delete temp.keys;

          temp.key = key;

          const allowed = this.validateCol(temp);
          if (allowed) {
            formatted.push(allowed);
          }
        });
      } else {
        const allowed = this.validateCol(col);
        if (allowed) {
          formatted.push(allowed);
        }
      }
    });

    return formatted;
  }

  validateCol(col: ColumnData<T>): ColumnData<T> | null {
    // Defines a default if hideColumnKeys is not passed in.
    const hiddenFields = this.hideColumnKeys ? this.hideColumnKeys : [];

    // Check if key is hidden on this table.
    if (!hiddenFields.includes(col.hideColumnKey) && !this.forbiddenTypes.includes(col.type)) {
      return col;
    }

    return null;
  }

  getData(): void {
    if (this.dataOverride) {
      this.data = this.dataOverride;
      this.totalCount = this.dataOverride.length;
      this.loading = false;
      this.originalData.emit(this.data);

      return;
    }

    const keys = this.objectModel?.table.map(item => item['key']);

    if (keys?.indexOf('id')) {
      keys.push('id');
    }

    this.loading = true;

    const handlePromise = (promise: Promise<PageReturn<T>>) => {
      promise
        .then(result => {
          this.data = result.data;
          this.originalDataCopy = cloneDeep(result.data);
          this.totalCount = result.totalCount;
          this.loading = false;
          this.originalData.emit(this.data);
        })
        .catch(() => {
          this.loading = false;
        });
    };

    const baseParams = {
      search: { ...this.search, ...this.customFilter.search },
      filter: { ...this.filter, ...this.customFilter.filter },
      query: { ...this.query, ...this.customFilter.query },
      specifiedApi: this.api,
      limit: this.pageSize,
      offset: this.page * this.pageSize,
      orderDirection: this.orderDirection,
      orderColumn: this.orderColumn,
      keys,
    };

    if (this.overrideServiceMethod) {
      handlePromise(this.service[this.overrideServiceMethod](baseParams));
    } else if (this.service) {
      handlePromise(this.service.list(baseParams));
    } else {
      handlePromise(this.autoService.list({ ...baseParams, entity: this.formClass }));
    }
  }

  refreshData(refresh) {
    if (refresh) {
      this.getData();
    }
  }

  fetchDataByUrl(param: Record<string, unknown>) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { ...param },
        skipLocationChange: this.router.isActive(this.router.url, true), // Floods url history - this fixes it
        queryParamsHandling: 'merge',
      } as NavigationExtras)
      .then(() => this.getData());
  }

  pageSizeInput(pageSize: number) {
    if (pageSize > 0) {
      this.pageSize = +pageSize;
      this.getData();
    }
  }

  changePage(page: number): void {
    this.page = page;
    this.fetchDataByUrl({ [queryParams.PAGE]: this.page });
  }

  customize() {
    this.customizing = !this.customizing;
  }

  add(): void {
    const options: NavigationExtras = {};

    if (this.overrideAddButtonClick) {
      this.addButtonClick.emit(null);
    } else {
      this.router.navigate([this.router.url.slice(1).split('?')[0], 'add'], options);
    }
  }

  edit(item) {
    if (this.overrideEditButtonClick) {
      this.editButtonClick.emit(item);
    } else {
      const options: NavigationExtras = {
        queryParams: { id: item.id },
      };
      this.router.navigate([this.router.url.slice(1).split('?')[0], 'edit'], options);
    }
  }

  async delete(item) {
    if (window.confirm('Are you sure you want to delete this?')) {
      if (this.service) {
        await this.service.delete(item?.id);
      } else {
        await this.autoService.delete(this.formClass, this.api, item?.id);
      }
      this.getData();
      this.deleteButtonClick.emit(item?.id);
    }
  }

  getService() {
    if (this.service) {
      return this.service;
    }
    return this.autoService;
  }

  select = (item: T) => {
    const options: NavigationExtras = {
      queryParams: {
        _parentId: item.id,
      },
    };

    this.router.navigate([this.router.url.slice(1).split('?')[0]], options);
  };

  onItemSelected(event: T[]) {
    this.selected = event;
    this.itemSelect.emit(event);
  }

  filterChange(change: AdvancedSearchChange) {
    this.page = 0;

    this.customFilter = change;

    this.getData();
  }

  orderChange(order: { column: string; direction: 'ASC' | 'DESC'; data: ColumnData<unknown> }) {
    this.orderColumn = order.data.orderKey;
    this.orderDirection = order.direction;
    this.getData();
  }

  isObject(object): object is Object {
    return object != null && typeof object === 'object';
  }

  isObjectEqual(object1: Object, object2: Object) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.isObjectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  onItemEdit(item: T) {
    const original = this.originalDataCopy.find(it => it.id === item.id);

    if (Object.keys(item).some(key => key === 'hasChanged' || key === 'isEditable' || key === 'newId')) {
      if (Object.keys(item).some(key => key === 'hasChanged')) {
        delete item['hasChanged'];
      }
      if (Object.keys(item).some(key => key === 'isEditable')) {
        delete item['isEditable'];
      }
      if (Object.keys(item).some(key => key === 'newId')) {
        delete item['newId'];
      }
      if (Object.keys(item).some(key => key === 'isSelected')) {
        delete item['isSelected'];
      }
    }

    if (!this.isObjectEqual(original, item)) {
      this.updatedItems = { [item.id]: { newItem: item, oldItem: original }, ...this.updatedItems };
      const updatedList = this.convertUpdatedItemsToArray(this.updatedItems);
      this.editedItems.emit(updatedList);
    }
  }

  convertUpdatedItemsToArray(data: Record<string, unknown>) {
    const results: T[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const updatedItemsKey of Object.keys(data)) {
      const item = this.updatedItems[updatedItemsKey];
      results.push(item.newItem);
    }
    return results;
  }

  uploaded(uploaded: boolean) {
    if (uploaded) {
      this.getData();
      this.executedBulkUpload.emit();
    }
  }

  public setFilters(input: Record<string, unknown>): void {
    const change: AdvancedSearchChange = {
      filter: {},
      query: {},
      search: {},
    };
    Object.entries(input).forEach(([key, value]) => {
      change.filter[key] = [`${value}`];
    });
    this.filterChange(change);
  }

  onExport(): void {
    this.uploadComponent.exportFunc();
  }

  // hasFilters(): boolean {
  //   return Object.keys(this.filterMap).length > 0;
  // }
}
