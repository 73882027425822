<span mat-dialog-title class="mat-subheading-2">App Switcher</span>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-nav-list>
    <mat-list-item
      class="ov-nav-list-item"
      *ngFor="let link of apps"
      (click)="navigate(link['url'])"
    >
      <span
        [ngStyle]="{ 'font-weight' : link['url'].includes(selectedAppUrl) ? 'bolder' : '' }"
        class="mat-subheading-1"
      >
        {{ link['title'] }}
      </span>
    </mat-list-item>
  </mat-nav-list>
</div>
