/* eslint-disable */
export function Memo() {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = memoize(oldFunc);
    descriptor.value = function () {
      return newFunc.apply(this, arguments);
    };
  };
}

export function memoize(fn: any) {
  return function () {
    const args = Array.prototype.slice.call(arguments);
    fn.cache = fn.cache || {};
    return fn.cache[args] ? fn.cache[args] : (fn.cache[args] = fn.apply(this, args));
  };
}
