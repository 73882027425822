import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ov-suite-user-apps-dialog',
  templateUrl: './user-apps-dialog.component.html',
  styleUrls: [ './user-apps-dialog.component.css' ]
})
export class UserAppsDialogComponent {
  apps: [];

  selectedAppUrl = '';

  constructor(private readonly dialogRef: MatDialogRef<UserAppsDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.apps = data.apps;
    this.selectedAppUrl = data.selectedAppUrl;
  }

  close() {
    this.dialogRef.close();
  }

  navigate(url: string) {
    window.location.assign(url);
  }
}
