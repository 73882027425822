/**
 * Icons come from the styles' folder.
 *
 * This is done through the angular.json file.
 *
 * The following lines where added.
 */

// "assets": [
//   {
//     "glob": "**/*",
//     "input": "./libs/styles/src/lib/img",
//     "output": "./assets/img"
//   }
// ]

/**
 * Also to note,
 *
 * The svg icons were updated, replace 'id' with name of the icon,
 * This will ensure the icon is unique and usable in the application.
 *
 * Refer to './assets/img/icons/search.svg' as an example.
 */

export const Icons = [
  { name: 'search', path: './assets/img/icons/search.svg' },
  { name: 'cross', path: './assets/img/icons/small-cross.svg' },
  { name: 'filter', path: './assets/img/icons/filter-list.svg' },
  { name: 'cog', path: './assets/img/icons/cog.svg' },
  { name: 'share', path: './assets/img/icons/share.svg' },
  { name: 'tick', path: './assets/img/icons/tick.svg' },
  { name: 'calendar', path: './assets/img/icons/calendar.svg' },
  { name: 'add-to-artifact', path: './assets/img/icons/add-to-artifact.svg' },
  { name: 'import', path: './assets/img/icons/import.svg' },
  { name: 'export', path: './assets/img/icons/export.svg' },
  { name: 'add', path: './assets/img/icons/add.svg' },
  { name: 'eye-open', path: './assets/img/icons/eye-open.svg' },
  { name: 'trash', path: './assets/img/icons/trash.svg' },
  { name: 'repeat', path: './assets/img/icons/repeat.svg' },
  { name: 'mobile-video', path: './assets/img/icons/mobile-video.svg' },
]
