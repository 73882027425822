<label class="mt-2" for="tree-select">
  {{ title }}
  <span
    *ngIf="tooltip"
    aria-hidden="true"
    class="fa fa-info-circle"
    placement="right-top"
    [ngbTooltip]="!!tooltip ? tipContent : ''"
  ></span>
  <ng-template #tipContent><div [innerHTML]="tooltip"></div></ng-template>
</label>
<div>
  <div id="tree-select" class="row ts" [ngClass]="{ 'border-danger': danger }">
    <div class="col-4 ts-left">
      <input
        class="form-control"
        placeholder="Search"
        [(ngModel)]="searchValue"
        (change)="onSearchChange($event)"
      />
      <div *ngIf="!this.flat" class="ts-header">
        <button *ngIf="filteredPath.length" class="ts-back" (click)="back()">
          <i aria-hidden="true" class="fa fa-chevron-left"></i>
        </button>
        <div class="ts-title">{{ this.currentTitle }}</div>
      </div>
      <table class="table table-hover" [attr.aria-describedby]="title">
        <thead>
          <tr class="ts-row-header">
            <th scope="col">ID</th>
            <th scope="col">{{nameColumnLabel || 'Name'}}</th>
            <th scope="col">
              <ng-container>
                <button
                  *ngIf="flat"
                  (click)="addAll()"
                  class="float-right ts-button text-center"
                >
                  <ov-suite-icon *ngIf="!hasAll" class="text-dark" [name]="'plus'"> </ov-suite-icon>
                  <ov-suite-icon *ngIf="hasAll" class="text-danger" [name]="'minus'"> </ov-suite-icon>
                </button>
                <small class="float-right">(All)&nbsp;</small>
              </ng-container>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of currentData"
            (click)="select(item)"
            [ngClass]="{
              'ts-selectable':
                !this.flat && selectionMap[item.id] !== 'selected'
            }"
          >
            <th scope="row">{{ item.id }}</th>
            <td>{{ item[nameColumnKey] || item.name }}</td>
            <td>
              <ng-container [ngSwitch]="selectionMap[item.id]">
                <button
                  *ngSwitchCase="'not'"
                  class="float-right ts-button text-center"
                  (click)="add($event, item)"
                >
                  <ov-suite-icon class="text-dark" [name]="'plus-circle'"> </ov-suite-icon>
                </button>
                <button
                  *ngSwitchCase="'partial'"
                  class="float-right ts-button text-center"
                  (click)="cautionAdd($event, item)"
                >
                  <ov-suite-icon class="text-warning" [name]="'plus-circle'"> </ov-suite-icon>
                </button>
                <button
                  *ngSwitchCase="'selected'"
                  class="float-right ts-button text-center"
                  (click)="remove($event, item)"
                >
                  <ov-suite-icon class="text-info" [name]="'plus-circle'"> </ov-suite-icon>
                </button>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-8 ts-right">
      <table
        *ngIf="multiValue.length; else noItems"
        class="table table-striped"
        aria-describedby="Link Table"
      >
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">{{nameColumnLabel || 'Name'}}</th>
            <th *ngIf="withQuantity" scope="col">Quantity</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of multiValue">
            <th scope="row">
              {{ withQuantity ? item[quantityKey]?.id : item?.id }}
            </th>
            <td>{{ withQuantity ? item[quantityKey][nameColumnKey || 'name'] : item[nameColumnKey || 'name'] }}</td>
            <td *ngIf="withQuantity">
              <span class="ts-quantity">
                <input
                  placeholder="Quantity"
                  type="number"
                  (change)="forceUpdate()"
                  [(ngModel)]="item.quantity"
                  [ngClass]="{
                    'border-danger': danger && (item.quantity || 0) <= 0
                  }"
                  min="0"
                />
              </span>
              <span
                *ngIf="danger && (item.quantity || 0) <= 0"
                class="text-danger"
              >
                Required
              </span>
            </td>
            <td>
              <button
                class="float-right ts-button text-center"
                (click)="remove($event, item)"
              >
                <ov-suite-icon class="text-dark" [name]="'minus-circle'"> </ov-suite-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noItems>
        <div class="ts-no-items-container">
          <span class="ts-no-items-text">No Items Selected</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
