import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { environment } from '@ov-suite/helpers-angular';
import { Auth } from '@aws-amplify/auth';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserAccountDialogComponent } from './user-account-dialog/user-account-dialog.component';
import { Domain } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { UserAccount } from './user-account';

@Component({
  selector: 'ov-suite-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: [ './user-account.component.css' ]
})
export class UserAccountComponent implements OnInit {
  userDialogOpen = false;

  // From Widget
  userAccount: UserAccount = {
    avatarUrl: './assets/img/placeholder.jpg',
    username: 'Loading',
    email: 'Loading'
  };

  domains: Domain[] = [];

  constructor(
    public dialog: MatDialog,
    private readonly ovAutoService: OvAutoService,
  ) {
  }

  ngOnInit() {
    Auth.currentUserInfo().then(user => {
      this.prepareUser(user);
    });
  }

  navigate() {
    window.location.assign(`${environment.values.webUrl.idm}/user`);
  }

  prepareUser(user) {
    if (user) {
      this.userAccount = {
        avatarUrl: user.attributes.picture,
        email: user.attributes.email,
        username: user.attributes[ 'name' ] || user.attributes[ 'family_name' ]
          ? `${user.attributes[ 'name' ]} ${user.attributes[ 'family_name' ]}`
          : 'Not Available',
      }
    } else {
      this.userAccount = {
        avatarUrl: './assets/img/placeholder.jpg',
        email: 'Not Available',
        username: 'Not Available',
      }
    }
  }

  openDialog(element) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data = {
      user: this.userAccount
    };

    dialogConfig.position = {
      top: `${element.offsetHeight + 50}px`,
      right: `${element.offsetWidth - 20}px`,
    };

    dialogConfig.panelClass = 'ov-custom-dialog';

    this.userDialogOpen = true;
    const dialogRef = this.dialog.open(UserAccountDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.userDialogOpen = false;
    });
  }

  closeDialog() {
    this.userDialogOpen = false;
    this.dialog.closeAll();
  }
}
