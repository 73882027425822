<mat-nav-list class="ov-sidebar">
  <mat-list-item
    mat-line
    class="drawer-header"
  >
    <a href="/">
      <img
        class="img-open"
        *ngIf="opened"
        src="/assets/img/linksuite/account/logo-large.svg"
        alt="linksuite-logo"
        height="50"
      >

      <img
        class="img-close"
        *ngIf="!opened"
        src="/assets/img/linksuite/account/logo-small.svg"
        alt="linksuite-logo"
        height="48"
      >
    </a>
  </mat-list-item>
  <ov-suite-domains
    [opened]="opened"
  ></ov-suite-domains>
<!--  TODO Please do not remove. Undecided on final actions on this item -->
<!--  <mat-list-item-->
<!--    *ngFor="let menuitem of linkList"-->
<!--    class="ov-sidebar-list-item ov-side-bar-titles"-->
<!--    routerLinkActive="active"-->
<!--    [ngClass]="{ 'active' : menuitem.title === selectedRoute['title'], 'closed' : !opened }"-->
<!--    [routerLink]="[menuitem.path]"-->
<!--    (click)="setTitle(menuitem, null)"-->
<!--  >-->
<!--    <a matLine>-->
<!--      <i-->
<!--        class="{{ menuitem.icontype }} nav-title-icon"-->
<!--        aria-hidden="true"-->
<!--      ></i>-->
<!--      <span-->
<!--        class="mat-subheading-2"-->
<!--        *ngIf="opened"-->
<!--      >-->
<!--        {{ menuitem.title.charAt(0).toUpperCase() + menuitem.title.slice(1) }}-->
<!--      </span>-->
<!--    </a>-->
<!--  </mat-list-item>-->
</mat-nav-list>

<mat-accordion
  [displayMode]="'flat'"
  [hideToggle]="!opened"
  multi="true"
>
  <mat-expansion-panel
    class="mat-elevation-z0 ov-sidebar"
    [ngClass]="menuitem.active === true ? 'active' : ''"
    *ngFor="let menuitem of subList"
    (opened)="openPanel(menuitem)"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="ov-mat-expansion-panel-header"
      [expandedHeight]="'60px'"
      [collapsedHeight]="'60px'"
      *ngIf="menuitem.children.length > 0"
    >
      <mat-panel-title
        [ngClass]="opened ? '' : 'ov-sidebar-expansion-panel-header'"
      >
        <i
          class="{{ menuitem.icontype }} nav-title-icon"
          aria-hidden="true">
        </i>
        <span class="mat-subheading-2" *ngIf="opened">{{ menuitem.title.charAt(0).toUpperCase() + menuitem.title.slice(1) }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
      <mat-list-item
        class="ov-sidebar-item-content"
        [ngClass]="{'closed' : !opened}"
        *ngFor="let childitem of menuitem.children"
        routerLinkActive="active"
        [routerLink]="[menuitem.path, childitem.path]"
        (click)="setTitle(menuitem, childitem)"
      >
        <a mat-line>
          <i aria-hidden="true">{{ childitem.ab }}</i>
          <span class="mat-subheading-1" *ngIf="opened">{{ childitem.title.charAt(0).toUpperCase() + childitem.title.slice(1) }}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-accordion>

<div class="ov-collapse-icon">
  <mat-icon
    class="expand-icon"
    (click)="opened = !opened">compare_arrows
  </mat-icon>
</div>
