import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

@Component({
  selector: 'ov-suite-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss'],
  providers: [MakeProvider(DaySelectorComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DaySelectorComponent extends AbstractValueAccessor<number>
  implements OnInit {

  // new ones
  @Input() id: string;
  @Input() data: number;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Input() inputLabel: string;
  @Input() danger: boolean;
  @Input() title: string;

  @Input() size: 'xs' | 'sm' | 'md' | 'lg';
  _value: number;
  days = days;

  ngOnInit(): void {}

  writeValue(value: number) {
    this._value = value;
    this.onChange(value);
    if (!value) {
      this._value = 0;
    }
  }

  isSelected(value: number, column: 1 | 2 | 4 | 8 | 16 | 32 | 64): boolean {
    return (value | column) === value;
  }

  onSelect(value: number, column: 1 | 2 | 4 | 8 | 16 | 32 | 64): void {
    if ((value | column) === value) {
      this._value -= column;
    } else {
      this._value += column;
    }
    this.writeValue(this._value);
  }
}

const days = [
  {
    name: 'S',
    value: 64
  },
  {
    name: 'M',
    value: 32
  },
  {
    name: 'T',
    value: 16
  },
  {
    name: 'W',
    value: 8
  },
  {
    name: 'T',
    value: 4
  },
  {
    name: 'F',
    value: 2
  },
  {
    name: 'S',
    value: 1
  }
];
