<div class="card-body">
  <div *ngFor="let item of metadata; let i = index">
    <div class="row align-items-end">
      <div
        *ngFor="let subItem of item"
        [ngClass]="{
          'col-md-6': item.length === 2,
          'col-md-4': item.length >= 3,
          'col-md-12': item.length <= 1
        }"
      >
        <div
          *ngIf="subItem?.propertyKey; else titleCard"
          [ngSwitch]="subItem.type"
        >
          <!-- TITLE -->
          <ng-container *ngSwitchCase="'title'">
            <hr *ngIf="i > 0" />
            <h6>{{ subItem.title }}</h6>
          </ng-container>

          <!-- BUTTON -->
          <ng-container *ngSwitchCase="'button'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <button
                [class]="subItem.classes.join(' ')"
                (click)="buttonAction(subItem, metadata)"
              >
                {{ subItem.title }}
              </button>
            </div>
          </ng-container>

          <!-- STRING -->
          <ng-container *ngSwitchCase="'string'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'text'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || ''"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
                [disabled]="!subItem.editable"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!-- DATE -->
          <ng-container *ngSwitchCase="'date'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'date'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || ''"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!-- DATE TIME-->
          <ng-container *ngSwitchCase="'date-time'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'date-time'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || ''"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!--TIME-->
          <ng-container *ngSwitchCase="'time'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'time'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || ''"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
              >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!-- PASSWORD -->
          <ng-container *ngSwitchCase="'password'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'password'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || ''"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>
          <!-- NUMBER -->
          <ng-container *ngSwitchCase="'number'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'number'"
                [step]="subItem.step"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || 0"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>
          <!-- Checkbox -->
          <ng-container *ngSwitchCase="'boolean'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-input
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [type]="'checkbox'"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.placeholder || 0"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-input>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>
          <!-- IMAGE -->
          <ng-container *ngSwitchCase="'image'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">
              <ov-suite-image-upload
                [size]="'large'"
                [id]="subItem.propertyKey"
                [(imageUrl)]="subItem.value"
                (ngModelChange)="modelChange(subItem)"
              >
              </ov-suite-image-upload>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'map'">
            <ov-suite-input
              [id]="subItem.propertyKey"
              [type]="'map'"
              [(ngModel)]="subItem.value"
              (ngModelChange)="modelChange(subItem)"
              [danger]="subItem.danger"
            ></ov-suite-input>
            <small
              *ngIf="subItem.danger"
              class="form-text text-muted text-danger"
              >{{ subItem.currentErrorMessage || 'Required' }}</small
            >
          </ng-container>
          <!-- DROPDOWN -->
          <ng-container
            *ngIf="
              subItem.selectionType === 'simple' &&
              ((!data?.id && !subItem.generated) ||
                (data?.id && !subItem.readonly))
            "
          >
            <ov-suite-input
              [(ngModel)]="subItem.value"
              type="dropdown"
              [title]="subItem.title"
              [data]="getDataSource(subItem)"
              [label]="subItem.dropdownKeys"
              (ngModelChange)="validateArrayField(subItem)"
              [danger]="subItem.danger"
              [placeholder]="subItem.placeholder"
            >
            </ov-suite-input>
            <small
              *ngIf="subItem.danger"
              class="form-text text-muted text-danger"
            >{{ subItem.currentErrorMessage || 'Required' }}</small>
          </ng-container>
          <ng-container
            *ngIf="
              subItem.selectionType === 'multiple' &&
              ((!data?.id && !subItem.generated) ||
                (data?.id && !subItem.readonly))
            "
          >
            <ov-suite-tree-select
              [subItem]="subItem"
              [tooltip]="subItem.tooltip"
              [single]="false"
              [nameColumnKey]="subItem.nameColumnKey"
              [nameColumnLabel]="subItem.nameColumnLabel"
              [flat]="subItem.flat"
              [title]="subItem.title"
              [formClass]="subItem?.type()"
              [service]="
                dataSources ? dataSources[subItem.propertyKey] : undefined
              "
              [ovAutoService]="ovAutoService"
              [(ngModel)]="subItem.value"
              [danger]="subItem.danger"
              (ngModelChange)="validateArrayField(subItem)"
              [withQuantity]="subItem.withQuantity"
            >
            </ov-suite-tree-select>
            <small
              *ngIf="subItem.danger"
              class="form-text text-muted text-danger"
              >{{ subItem.currentErrorMessage || 'Required' }}</small
            >
          </ng-container>
          <ng-container
            *ngIf="
              subItem.selectionType === 'single' &&
              ((!data?.id && !subItem.generated) ||
                (data?.id && !subItem.readonly))
            "
          >
            <ov-suite-tree-select
              [subItem]="subItem"
              [tooltip]="subItem.tooltip"
              [single]="true"
              [nameColumnKey]="subItem.nameColumnKey"
              [nameColumnLabel]="subItem.nameColumnLabel"
              [flat]="subItem.flat"
              [title]="subItem.title"
              [formClass]="subItem?.type()"
              [service]="
                dataSources ? dataSources[subItem.propertyKey] : undefined
              "
              [ovAutoService]="ovAutoService"
              [(ngModel)]="subItem.value"
              [danger]="subItem.danger"
              (ngModelChange)="validateArrayField(subItem)"
              [withQuantity]="subItem.withQuantity"
            >
            </ov-suite-tree-select>
            <small
              *ngIf="subItem.danger"
              class="form-text text-muted text-danger"
              >{{ subItem.currentErrorMessage || 'Required' }}</small
            >
          </ng-container>
          <!-- PERMISSION -->
          <ng-container *ngSwitchCase="'permission'">
            <div class="form-group">
              <ov-suite-permission-matrix
                [id]="subItem.propertyKey"
                [(ngModel)]="subItem.value"
                (ngModelChange)="modelChange(subItem)"
                [service]="getDataSource(subItem)"
              >
              </ov-suite-permission-matrix>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
                >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!-- RECURRENCE SELECTOR -->
          <ng-container *ngSwitchCase="'recurrence'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item)">

              <ov-suite-recurrence
                [id]="subItem.propertyKey"
                [name]="subItem.propertyKey"
                [inputLabel]="subItem.title"
                [tooltip]="subItem.tooltip"
                [(ngModel)]="subItem.value"
                (change)="validateField(subItem)"
                (ngModelChange)="modelChange(subItem)"
                [danger]="subItem.danger"
              >
              </ov-suite-recurrence>
              <small
                *ngIf="subItem.danger"
                class="form-text text-muted text-danger"
              >{{ subItem.currentErrorMessage || 'Required' }}</small
              >
            </div>
          </ng-container>

          <!-- RECURRENCE SELECTOR -->
          <ng-container *ngSwitchCase="'code-preview'">
            <div class="form-group" *ngIf="subItem.hidden && !subItem.hidden(item) && metadataMap[subItem.codeKey].value">
              <qrcode [qrdata]="metadataMap[subItem.codeKey].value" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
          </ng-container>

        </div>
        <ng-template #titleCard>{{ subItem.propertyKey }}</ng-template>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showSave" class="card-footer p-3">
  <button
    class="btn font-weight-normal rounded-0 btn-dark float-right"
    (click)="submit()"
  >
    Save & Update&nbsp;&nbsp;<em class="fa fa-check text-white" aria-hidden="true"></em>
  </button>
  <button
    class="btn font-weight-normal rounded-0 border bg-white text-dark"
    (click)="cancel()"
  >
    Cancel&nbsp;&nbsp;<em class="fa fa-times text-dark"></em>
  </button>
</div>
