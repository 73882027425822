<div *ngIf="header">
  <h4
    mat-dialog-title
    class="mat-subheading-2"
  >
    Some fancy title
  </h4>
</div>

<div
  mat-dialog-content
  *ngIf="content"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div
  mat-dialog-actions
  *ngIf="actions"
>
  <button mat-button mat-dialog-close>Close</button>
</div>
