import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { Feature } from './feature.model';
import { UserType } from './user-type.model';

@OVEntity('UserTypeFeature', 'idmlink')
export class UserTypeFeature {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'number' })
  permission: number;
  @OVField({ type: () => Feature })
  feature: Feature;
  @OVField({ type: () => UserType })
  userType: UserType;
}
