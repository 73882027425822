import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity,
} from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';
import { Area } from './area.model';
import { Domain } from './domain.model';

@OVEntity('FactoryStatus', 'shared')
export class FactoryStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'Factory', api: 'shared' })
@OVForm([
  ['#Factory Details'],
  ['factoryCode', 'name'],
  ['description'],
  ['domains'],
  ['area'],
  ['map'],
])
@OVForm([
  ['#Factory Details'],
  ['factoryCode', 'name'],
  ['description'],
  ['domains'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Factory>([
  {
    key: 'factoryCode',
    title: 'Factory ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Factory Name',
    type: 'string',
  },
])
export class Factory {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Factory Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Factory Code',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  factoryCode: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
 type: 'image', sidebar: true, required: false, title: 'Image',
})
  imageUrl?: string;

  @OVField({
    type: () => FactoryStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: FactoryStatus;

  @OVField({
    type: () => [Domain],
    tooltip: 'Add multiple domains for this factory.',
    selectionType: 'multiple',
    title: 'Domains',
  })
  domains: Domain[];

  @OVField({
    type: () => Area,
    selectionType: 'single',
    title: 'Area',
    tooltip: 'Add a single area for this factory.',
    required: true,
  })
  area?: Area;

  @OVField({
    type: 'map',
    required: false,
  })
  map?: MapLocation;

  floorPlanURL: string;
}
