import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { App, Feature, UserType, UserTypeFeature, UserTypeStatus } from '@ov-suite/models-idm';

@Injectable()
export class UserTypeService extends AutoService<UserType> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), UserType, 'UserType', 'UserTypes', 'idmlink');
  }
}

@Injectable()
export class UserTypeStatusService extends AutoService<UserTypeStatus> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), UserTypeStatus, 'UserTypeStatus', 'UserTypeStatuses', 'idmlink');
  }
}

@Injectable()
export class FeatureService extends AutoService<Feature> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), Feature, 'Feature', 'Features', 'idmlink');
  }
}

@Injectable()
export class AppService extends AutoService<App> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), App, 'App', 'Apps', 'idmlink');
  }
}

@Injectable()
export class UserTypeFeatureService extends AutoService<UserTypeFeature> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('idmlink'), UserTypeFeature, 'UserTypeFeature', 'UserTypeFeatures', 'idmlink');
  }
}
