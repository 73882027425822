<div class="modal-header">
  <h4 class="modal-title mt-2">Set user password</h4>
  <button type="button" class="close mr-3" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <div class="input-group">
        <ov-suite-input
          id="userPassword"
          [name]="'userPassword'"
          [type]="'text'"
          [inputLabel]="'Password'"
          [tooltip]="'Set new password for user'"
          [(ngModel)]="userPassword"
          [placeholder]="'Required'"
          (ngModelChange)="errors=''"
          [danger]="errors.length > 1"
          [disabled]="false"
        >
        </ov-suite-input>
        <small *ngIf="errors.length > 1" class="form-text text-muted text-danger">{{ errors }}</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer mb-4 ml-5 mr-5">
  <button type="button" [disabled]="!userPassword" class="btn btn-outline-dark" (click)="onSetPassword()">Save</button>
</div>
