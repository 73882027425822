import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { Factory } from './factory.model';

@OVEntity('FactoryAreaStatus', 'adminlink')
export class FactoryAreaStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('FactoryAreaType', 'adminlink')
export class FactoryAreaType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('FactoryArea', 'adminlink')
@OVForm([['#Factory Area Details'], ['factoryAreaId', 'name'], ['factory', 'description'], ['type', ''], ['barcodeType', 'barcodeData']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<FactoryArea>([
  {
    key: 'factoryAreaId',
    title: 'Area ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Area Name',
    type: 'string',
  },
  {
    type: 'other',
    title: 'Number of Sub Areas',
    action: item => item?.childrenCount?.toString() ?? '0',
    routerLink: () => [],
    keys: ['childrenCount'],
    queryParams: (item: { id: number }) => ({ _parentId: item.id }),
  },
])
export class FactoryArea {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Area Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Area ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  factoryAreaId: string;

  @OVField({
    type: () => FactoryAreaType,
    tooltip: 'Add a single area type.',
    selectionType: 'simple',
    title: 'Area Types',
    placeholder: 'Required',
    required: true,
  })
  type: FactoryAreaType;

  @OVField({
    type: () => Factory,
    tooltip: 'Add associated factory',
    keys: ['id', 'name'],
    selectionType: 'simple',
    title: 'Factory',
    required: false,
  })
  factory: Factory;

  @OVField({
    type: () => FactoryArea,
    title: 'Parent',
    bulkDependency: 'factoryAreaId',
  })
  parent?: FactoryArea;

  @OVField({ type: () => FactoryArea })
  children?: FactoryArea[];

  @OVField({ type: 'number', generated: true, readonly: true })
  childrenCount?: number;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
  })
  imageUrl?: string;

  @OVField({
    type: () => FactoryAreaStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: FactoryAreaStatus;

  @OVField({ type: 'string', title: 'Barcode Type' })
  barcodeType: string;

  @OVField({ type: 'string', title: 'Barcode Data' })
  barcodeData: string;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;
}
