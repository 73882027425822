import { Component } from '@angular/core';
import swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@ov-suite/helpers-angular';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'ov-suite-user-set-password',
  templateUrl: './user-set-password-modal.component.html',
  styleUrls: ['./user-set-password-modal.component.scss'],
})
export class UserSetPasswordModalComponent {
  userId: number;

  errors = '';

  userPassword = '';

  constructor(public userService: UserService, public activeModal: NgbActiveModal) {}

  onSetPassword() {
    if (this.userPassword.length < 8) {
      this.errors = environment.values.cognito.passwordPolicyText;
      return;
    }

    if (window.confirm(`Are you sure you want to change this user's password?`)) {
      this.userService
        .setUserPassword(this.userId, this.userPassword)
        .then(() => {
          this.activeModal.close('success');
          swal.fire({
            title: 'Success!',
            text: 'Password changed successfully',
            type: 'success',
            timer: 2000,
          });
        })
        .catch(err => {
          swal.fire({
            title: 'Something went wrong!',
            text: 'Password not updated.',
            footer: err.message,
            type: 'error',
          });
        });
    }
  }
}
