<div class="hierarchy-table shadow-sm">
  <ng-container
    *ngIf="data.length > 0 || !emptyComponent || searchFilter || loading; else emptyComponent">
  <div class="hierarchy-table-card card slide-in-right">
    <div class="row d-flex flex-row justify-content-between align-items-center m-2 table-header-component">
      <div class="ht-header">
        <span class="table-title">{{ title }}</span>
      </div>
      <div class="hierarchy-input-container">
        <ov-suite-advanced-search
          [service]="getService()"
          [hideColumnKeys]="hideColumnKeys"
          [model]="objectModel"
          (advancedSearchChange)="filterChange($event)"
        >
        </ov-suite-advanced-search>
        <div>
          <button mat-icon-button (click)="customize()">
            <span *ngIf="!customizing">
               <ov-suite-icons [icon]="'cog'"></ov-suite-icons>
            </span>
            <span *ngIf="customizing">
              <ov-suite-icons [icon]="'tick'"></ov-suite-icons>
            </span>
          </button>
        </div>
        <div *ngIf="hasBulkExport">
          <button mat-icon-button (click)="onExport()">
            <ov-suite-icons [icon]="'export'"></ov-suite-icons>
          </button>
        </div>
        <div
          class="hierarchy-input"
          *ngIf="hasCreatePermission && (hasBulkUpload || hasBulkExport)"
          [hidden]="hasBulkExport && !hasBulkUpload"
          >
          <ov-suite-bulk-upload
            class="ov-suite-bulk-upload"
            *ngIf="hasCreatePermission" [metadata]="metadata"
            [filterQuery]="customFilter.filter"
            [mapQuery]="customFilter.query"
            [searchQuery]="customFilter.search"
            (hasUploaded)="uploaded($event)"
            [extraColumnEntity]="extraColumnEntity"
            #uploadComponent
          ></ov-suite-bulk-upload>
        </div>
        <div *ngIf="hasCreatePermission && !hideAddButton">
          <button
            mat-icon-button
            *ngIf="hasCreatePermission"
            (click)="add()"
          >
            <ov-suite-icons [icon]="'add'"></ov-suite-icons>
          </button>
        </div>
        <div *ngIf="bulkActionComponent && hasEditPermission" class="hierarchy-bulk-action-input">
          <div>
            <div ngbDropdown display="dynamic" placement="left-top" class="d-inline-block">
              <button class="btn btn-outline-primary bulk-action-btn" id="bulkActionDropdown" ngbDropdownToggle>Bulk Actions &nbsp; &nbsp;</button>
              <div ngbDropdownMenu aria-labelledby="bulkActionDropdown">
                <ng-container *ngTemplateOutlet="bulkActionComponent"> </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ov-suite-hierarchy-list-actions
      *ngIf="enableRefresh"
      (refreshEmitter)="refreshData($event)"
    ></ov-suite-hierarchy-list-actions>
    <div
      class="card-body" [ngClass]="{ 'show-scrollbar': true }"
    >
      <ov-suite-table
        [showIndex]="false"
        [striped]="true"
        [filterEnabled]="false"
        [selectableRows]="selectableRows"
        [showFiller]="showFiller"
        [data]="data"
        [loading]="loading"
        [columnData]="rawTableMetadata"
        [totalCount]="totalCount"
        [pageSize]="pageSize"
        [currentPage]="page"
        (changePage)="changePage($event)"
        (itemSelect)="onItemSelected($event)"
        [select]="select"
        [ovAutoService]="ovAutoService"
        [hideColumnKeys]="hideColumnKeys"
        [keyStore]="customMetadata?.name"
        (orderChange)="orderChange($event)"
        [columnHider]="customizing"
        [editableRows]="editableRows"
        (editActionButton)="edit($event)"
        (deleteActionButton)="delete($event)"
        (itemEdited)="onItemEdit($event)"
        [dropdownData]="dropdownData"
        (changePageSize)="pageSizeInput($event)"
      ></ov-suite-table>
    </div>
  </div>
  </ng-container>
</div>
