import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout.component';
import { UiModule } from '../ui.module';
import { AdminLayoutParams } from './admin-layout.types';
import '@angular/compiler';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule()
export class AdminLayoutModule {
  static forRoot(options: AdminLayoutParams): ModuleWithProviders<AdminLayoutModule> {
    @NgModule({
      imports: [ RouterModule.forRoot(options.angularRoutes), UiModule, MatSidenavModule ],
      declarations: [AdminLayoutComponent],
      exports: [RouterModule],
    })
    class AppRoutingModule {}

    return {
      ngModule: AppRoutingModule,
      providers: [{ provide: 'SIDEBAR_OPTIONS', useValue: options }],
    };
  }
}
