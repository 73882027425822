import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { staticDropdownOptions } from './advanced-search-dialog.constants';
import { CustomDateRange, FilterFieldData } from '../advanced-search.component';
/**
 * Sole purpose is the render the fields and capture the user input.
 */

@Component({
  selector: 'ov-suite-advanced-search-dialog',
  templateUrl: './advanced-search-dialog.component.html',
  styleUrls: ['./advanced-search-dialog.component.css'],
})
export class AdvancedSearchDialogComponent implements OnInit {
  @Input() fields: FilterFieldData[];

  @Input() listener: BehaviorSubject<unknown>;

  hoveredDate: NgbDate | null = null;

  @ViewChild('datepicker', { static: false }) ngbDatepicker: NgbDatepicker;

  dropdownOptions: Record<string, string>[] = [];

  constructor(public formatter: NgbDateParserFormatter) {}

  ngOnInit(): void {
    this.prepareDropdownOptions();

    this.parseDateFields();
  }

  prepareDropdownOptions() {
    const filteredKeys = this.fields.filter(item => item.type === 'dropdown');

    filteredKeys.forEach(field => {
      this.dropdownOptions[field.key] = staticDropdownOptions[field.key];
    });
  }

  clearFieldInput(field: FilterFieldData) {
    this.listener.next({ operation: 'clear', field });
    this.nullifyField(field);
  }

  getDisplayDate(field: FilterFieldData) {
    if (typeof field.value !== 'string') {
      const from = !field.value.from ? 'Date From' : this.formatter.format(field.value.from as NgbDateStruct);
      const to = !field.value.to ? 'Date To' : this.formatter.format(field.value.to as NgbDateStruct);
      return `${from} - ${to}`;
    }
    return '';
  }

  // For date picker:
  isRange(date: NgbDate, dateRange: CustomDateRange<NgbDateStruct>) {
    return (
      date.equals(dateRange.from) ||
      (dateRange.to && date.equals(dateRange.to)) ||
      this.isInside(date, dateRange) ||
      this.isHovered(date, dateRange)
    );
  }

  isHovered(date: NgbDate, dateRange: CustomDateRange<NgbDateStruct>) {
    return dateRange.from && !dateRange.to && this.hoveredDate && date.after(dateRange.from) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate, dateRange: CustomDateRange<NgbDateStruct>) {
    return dateRange.to && date.after(dateRange.from) && date.before(dateRange.to);
  }

  onDateSelection(date: NgbDate, field: FilterFieldData, picker: NgbInputDatepicker): void {
    const d: CustomDateRange = <CustomDateRange<string | NgbDateStruct | NgbDate>>field.value;

    if (!d.from && !d.to) {
      d.from = date;
    } else if (d.from && !d.to && date && !date.before(d.from as NgbDateStruct)) {
      d.to = date;
      this.setFieldValue(d as CustomDateRange<NgbDate>, field);
      picker.toggle();
    } else {
      d.to = null;
      d.from = date;
    }
  }

  toggleDatepicker(picker: NgbInputDatepicker): void {
    this.hoveredDate = null;
    picker.toggle();
  }

  /**
   * If input value is cleared, set field to value to null.
   * @param e
   * @param field
   */
  onInputChange(e: FilterFieldData, field: FilterFieldData): void {
    this.setFieldValue(e as string, field);
  }

  setFieldValue(value: CustomDateRange<NgbDate> | string, field: FilterFieldData): void {
    if (value) {
      if (field.range) {
        if (typeof value !== 'string') {
          field.value = {
            from: value.from,
            to: value.to,
          };
        }
      } else {
        field.value = value;
      }
    } else {
      this.nullifyField(field);
    }
  }

  nullifyField(field: FilterFieldData): void {
    if (field.range) {
      field.value = {
        from: null,
        to: null,
      };
      this.hoveredDate = null;
    } else {
      field.value = null;
    }
  }

  /**
   * Resets field value to null.
   */
  resetFields(): void {
    this.fields.forEach(item => {
      this.nullifyField(item);
    });

    this.listener.next('reset');
  }

  /**
   * Get all the fields that have changed.
   */
  applyFilter(): void {
    this.formatDateFields();

    this.listener.next({ operation: 'apply', fields: this.fields });
  }

  formatDateFields(): void {
    this.fields.forEach(d => {
      if (d.range) {
        const val: CustomDateRange = <CustomDateRange<string | NgbDateStruct | NgbDate>>d.value;
        if (val.from && val.to) {
          val.type = 'date-range';
          val.from = new Date(this.formatter.format(val.from as NgbDateStruct)).toISOString();
          val.to = new Date(this.formatter.format(val.to as NgbDateStruct)).toISOString();
        }
      }
    });
  }

  parseDateFields(): void {
    this.fields.forEach(d => {
      if (d.range) {
        const val: CustomDateRange = <CustomDateRange<string | NgbDateStruct | NgbDate>>d.value;
        if (val.from && val.to) {
          val.from = this.formatter.parse(val.from as string);
          val.to = this.formatter.parse(val.to as string);
        }
      }
    });
  }

  closeDialog(): void {
    this.listener.next('close');
  }
}
