import { Component, Inject, ViewChild } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { getFieldMetadata, HasId } from '@ov-suite/ov-metadata';
import { PermissionType } from '@ov-suite/helpers-shared';
import { OvGenericModuleParams } from '../ov-generic.types';
import { HierarchyListComponent } from '../../hierarchy-list/hierarchy-list.component';
import { getPermissionValue, verifyPermission } from '../ov-generic.guard';

@Component({
  selector: 'ov-suite-mobile-start-config',
  templateUrl: './ov-generic-list.component.html',
  styleUrls: ['./ov-generic-list.component.scss'],
})
export class OvGenericListComponent {
  canAdd = true;

  canDelete = true;

  canUpdate = true;

  extraColumnEntity?: string;

  @ViewChild('table') table: HierarchyListComponent<HasId>;

  public isHierarchy = false;

  constructor(public readonly ovAutoService: OvAutoService, @Inject('ENTITY_OPTIONS') public readonly options: OvGenericModuleParams) {
    const metadata = getFieldMetadata(options.entity);
    const hierarchyMarkers = [];

    if (options.permissionId) {
      getPermissionValue(options.permissionId).then(permissionValue => {
        this.canAdd = verifyPermission(permissionValue, PermissionType.create);
        this.canDelete = verifyPermission(permissionValue, PermissionType.delete);
        this.canUpdate = verifyPermission(permissionValue, PermissionType.update);
      });
    }
    this.extraColumnEntity = options.extraColumnEntity;

    for (const field of metadata.fields) {
      if (field.propertyKey === 'parent' || field.propertyKey === 'children') {
        hierarchyMarkers.push(true);
      }
      if (hierarchyMarkers.length > 1) {
        this.isHierarchy = true;
        break;
      }
    }
  }

  onChangeFilter(input: Record<string, unknown>): void {
    this.table.setFilters(input);
  }
}
