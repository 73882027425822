import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Personnel } from '@ov-suite/models-idm';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Personnel,
      api: 'idmlink',
      createTitle: 'Personnel',
      route: ['management/personnel'],
      tableTitle: 'Personnel',
      tabs: [
        {
          title: 'Personnel',
          path: '/management/personnel',
        },
        {
          title: 'Personnel Positions',
          path: '/management/personnel-position',
        },
      ],
    }),
  ],
})
export class PersonnelModule {}
