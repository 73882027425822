import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { PermissionType } from '@ov-suite/helpers-shared';
import { OvGenericModuleParams } from './ov-generic.types';

export async function getPermissionValue(id: number): Promise<number> {
  return Auth.currentSession().then(session => {
    const permissionsRaw: string = session.getIdToken().payload.lambdaPermissionIds;
    if (permissionsRaw) {
      const permissions: { id: number; value: number }[] = JSON.parse(permissionsRaw);
      return permissions.find(p => p.id === id)?.value ?? null;
    }
    return null;
  });
}

export function verifyPermission(value: number, permission: PermissionType): boolean {
  if (value === null) {
    return false;
  }
  let column = 1;

  switch (permission) {
    case PermissionType.create:
      column = 8;
      break;
    case PermissionType.read:
      column = 4;
      break;
    case PermissionType.update:
      column = 2;
      break;
    case PermissionType.delete:
      column = 1;
      break;
    default:
  }
  // eslint-disable-next-line no-bitwise
  return (value | column) === value;
}

@Injectable()
export class OvGenericGuard implements CanActivate, CanActivateChild {
  permissionMap: Record<number, number> = {};

  appMap: Record<number, boolean> = {};

  loaded = false;

  constructor(@Inject('ENTITY_OPTIONS') public readonly options: OvGenericModuleParams) {
    Hub.listen('auth', () => {
      this.updateAppsAndPermissions();
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.loaded) {
      await this.updateAppsAndPermissions();
      this.loaded = true;
    }
    if (this.options.permissionId) {
      return verifyPermission(this.permissionMap[this.options.permissionId] ?? null, route.data.permissionType);
    }
    return true;
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return true;
  }

  async updateAppsAndPermissions(): Promise<void> {
    return Auth.currentSession().then(session => {
      this.permissionMap = {};
      this.appMap = {};
      const permissionsRaw: string = session.getIdToken().payload.lambdaPermissionIds;
      const appsRaw: string = session.getIdToken().payload.lambdaAppIds;

      if (permissionsRaw) {
        const permissions: { id: number; value: number }[] = JSON.parse(permissionsRaw);
        permissions.forEach(perm => {
          this.permissionMap[perm.id] = perm.value;
        });
      }
      if (appsRaw) {
        const apps: number[] = JSON.parse(appsRaw);
        apps.forEach(app => {
          this.appMap[app] = true;
        });
      }
    });
  }
}
