// const hostIp = `10.96.92.173`;
const hostIp = `10.0.2.2`;

export const mobileEnvironment = {
  federated: false,
  idmLink: `http://${hostIp}:3332`,
  adminLink: `http://${hostIp}:3333`,
  warehouseLink: `http://${hostIp}:3334`,
  yardLink: `http://${hostIp}:3335`,
  executionLink: `http://${hostIp}:3336`,
};
