<!--
  If no icon is provided, then component is code
  driven and is probably being reused in a smart
   way ;)
-->
<button
  *ngIf="icon"
  mat-icon-button
  (click)="openDialog()"
>
  <ov-suite-icons [icon]="icon"></ov-suite-icons>
</button>
