<div class="outer">
  <table
    class="table table-sm"
    [ngClass]="{ 'table-striped': striped }"
    [ngbTooltip]="editableRows ? editItemTip : ''"
    aria-describedby="Link Table"
  >
    <thead>
      <tr>
        <th class="table-checkbox" *ngIf="selectableRows" scope="col">
          <input
            [id]="'selectAll'"
            type="checkbox"
            [checked]="isAllSelected()"
            (change)="onSelect($event)"
          />
        </th>
        <th *ngIf="showIndex" scope="col">#</th>
        <ng-container *ngFor="let col of filteredColumnData; let i = index">
          <th
            scope="col"
            *ngIf="col.type != 'extra-columns'"
            [ngClass]="{ 'table-status-label': col.type === 'status' }"
            (click)="onColumnHeaderClick(col)"
          >
            <span *ngIf="filterMapInput[col?.filterKey || col?.key || col?.orderKey]"
                  class="text-danger filtered-column-icon">*
              <span class="filtered-tooltip-text">
                {{ filterMapInput[col?.filterKey || col?.key || col?.orderKey] }}
              </span>
            </span>

            {{ col?.title }}
            <i
              *ngIf="!col.disableSorting && order.column !== col.id"
              class="fa fa-fw fa-sort" aria-hidden="true"
            ></i>
            <i
              *ngIf="order.column === col.id"
              class="fa fa-fw"
              [ngClass]="{
                'fa-sort-asc': order.direction === 'ASC',
                'fa-sort-desc': order.direction === 'DESC'
              }" aria-hidden="true"
            ></i>
            <input
              *ngIf="columnHider"
              type="checkbox"
              [checked]="isColVisible(col.id)"
              (click)="toggleCol(col.id, $event)"
              class="col-hider"
            />
          </th>
          <ng-container *ngIf="col.type == 'extra-columns'">
              <th scope="col"
                  *ngFor="let col of dynamicExtraColumns" scope="col">
                {{ col.title }}
              </th>
          </ng-container>
        </ng-container>

        <th *ngFor="let extraCol of extraColumns.data" scope="col">
          {{ extraCol }}
        </th>
        <th *ngIf="hasChanges()" scope="col"></th>
      </tr>
    </thead>
    <tbody *ngIf="filterEnabled">
      <tr>
        <th *ngIf="selectableRows" class="table-checkbox" scope="row"> </th>
        <td *ngFor="let col of filteredColumnData; let j = index" class="custom-filter-row">
          <ng-container [ngSwitch]="col?.type">
            <ng-container *ngSwitchCase="'date'">
              <ov-suite-input
                *ngIf="
                  col.id !== 'fast_actions' &&
                  col.id !== 'status' &&
                  !col.disableFiltering
                "
                [style]="{'height': '38px'}"
                type="date-range"
                [placeholder]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                (submitOverride)="onFilterChange(col, $event)"
                (change)="onFilterChange(col, $event)"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchCase="'other'">
              <ov-suite-input
                *ngIf="
                  col.id !== 'fast_actions' &&
                  col.id !== 'status' &&
                  !col.disableFiltering
                "
                [style]="{'height': '38px', 'width': '100%'}"
                [(ngModel)]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                type="text"
                (change)="onFilterChange(col, $event)"
              ></ov-suite-input>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ov-suite-input
                *ngIf="
                  col.id !== 'fast_actions' &&
                  col.id !== 'status' &&
                  !col.disableFiltering
                "
                [style]="{'height': '38px', 'width': '100%'}"
                [(ngModel)]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                type="text"
                (change)="onFilterChange(col, $event)"
              ></ov-suite-input>
            </ng-container>
          </ng-container>
        </td>
        <td *ngFor="let dummy of extraColumns.data"></td>
      </tr>
    </tbody>
    <tbody>
      <tr
        *ngFor="let item of data; let i = index"
        [ngClass]="{ selected: i === selectedIndex, 'selectable-rows': clickableRows }"
        (click)="onClick(item)"
      >
        <th class="table-checkbox" *ngIf="selectableRows" scope="row">
          <input
            [id]="'row' + i"
            type="checkbox"
            class="width-400"
            [checked]="item.isSelected"
            (change)="onSelect($event, item)"
          />
        </th>
        <th *ngIf="showIndex" scope="row">{{ i + 1 }}</th>
        <ng-container *ngFor="let col of filteredColumnData; let j = index">
          <td *ngIf="col.type != 'extra-columns'">
            <ng-container [ngSwitch]="col?.type">
              <ng-container *ngSwitchCase="'string'">
                <span *ngIf="!col.editable">
                  {{ item[col?.key] }}
                </span>
                <ov-suite-input
                  *ngIf="col.editable"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="text"
                  [disabled]="col?.key === 'id'"
                  [style]="{'height': '38px'}"
                  [(ngModel)]="item[col?.key]"
                  (ngModelChange)="itemChanged(item)"
                ></ov-suite-input>
              </ng-container>

              <ng-container *ngSwitchCase="'deep-string'">
                <span *ngIf="!col.editable">
                  {{ getDeepValue(item.id, item, col) }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                <span *ngIf="!col.editable">
                  {{ item[col?.key] }}
                </span>
                <ov-suite-input
                  *ngIf="item[isEditable]"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="number"
                  [style]="{'height': '38px'}"
                  [(ngModel)]="item[col?.key]"
                  (ngModelChange)="itemChanged(item)"
                ></ov-suite-input>
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                <span *ngIf="!col.editable">
                  {{ item[col?.key] }}
                </span>
                <ov-suite-input
                  *ngIf="col.editable"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="checkbox"
                  [style]="{'height': '38px'}"
                  [(ngModel)]="item[col?.key]"
                  (ngModelChange)="itemChanged(item)"
                ></ov-suite-input>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <span *ngIf="!col.editable">
                  {{ getDate(item[col?.key], col) }}
                </span>
                <ov-suite-input
                  *ngIf="col.editable"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="date"
                  [style]="{'height': '38px'}"
                  [(ngModel)]="item[col?.key]"
                  (ngModelChange)="itemChanged(item)"
                ></ov-suite-input>
              </ng-container>
              <ng-container *ngSwitchCase="'date-time'">
                <span *ngIf="!col.editable">
                  {{ getDateTime(item[col?.key]) }}
                </span>

                <ov-suite-input
                  *ngIf="col.editable"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="date-time"
                  [style]="{'height': '38px'}"
                  [(ngModel)]="item[col?.key]"
                  (ngModelChange)="itemChanged(item)"
                ></ov-suite-input>
              </ng-container>
              <ng-container *ngSwitchCase="'time'">
                <span *ngIf="!col.editable">
                  {{ item[col?.key] }}
                </span>

                <ov-suite-input
                  class="bg-dark"
                  *ngIf="col.editable"
                  [id]="item.id + 'row' + i + 'col' + j"
                  type="time"
                  [style]="{'height': '38px'}"
                  [ngModel]="(col.editable ? (item | displayKeyUpdate: col.key) : (item | displayKey: col.key))"
                  (ngModelChange)="setValueByKey($event, col?.key, item)"
                  [disabled]="userPermissions[col.permissionId]
                    ? !userPermissions[col.permissionId][col.permissionType]
                    : false"
                ></ov-suite-input>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdown'">

                <span *ngIf="!col.editable">
                 {{ item[col?.key] ? (item[col?.key] | displayKey: 'name') : ''}}
                </span>

                <ov-suite-input
                  *ngIf="col.editable"
                  [id]="item?.id + 'row' + i + 'col' + j"
                  type="dropdown"
                  [label]="col?.displayKeys"
                  [style]="{'height': '38px'}"
                  [data]="dropdownData[col.key.split('.').pop()]"
                  [ngModel]="(col.editable ? (item | displayKeyUpdate: col.key) : (item | displayKey: col.key))"
                  (ngModelChange)="setValueByKey($event, col?.key, item)"
                  [disabled]="userPermissions[col.permissionId]
                    ? !userPermissions[col.permissionId][col.permissionType]
                    : false"
                ></ov-suite-input>

              </ng-container>
              <ng-container *ngSwitchCase="'other'">
                <a
                  *ngIf="col?.queryParams || col?.routerLink; else simple"
                  [routerLink]="col?.routerLink(item)"
                  [queryParams]="col?.queryParams(item)"
                >
                  {{ col?.action(item) }}
                </a>
                <ng-template #simple>
                  <div [innerHTML]="col?.action(item)"></div>
                </ng-template>
              </ng-container>
              <ng-container *ngSwitchCase="'buttons'">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <ng-container *ngFor="let button of col?.buttons">
                  <button
                    *ngIf="
                      !button.permissionId || (
                        userPermissions[button.permissionId]
                        &&
                        userPermissions[button.permissionId][button.permissionType]
                      )"
                    type="button"
                    class="btn btn-secondary table-button action-btn-tooltip"
                    [style.display]="
                      button.hide
                        ? button.hide(item)
                          ? 'none'
                          : 'block'
                        : 'block'
                    "
                    [ngClass]="(getObjectType(button.classes) === 'function') ? button.classes(item) : button.classes"
                    (click)="
                      button.action ? button.action(item, {
                            ngbModal: ngbModal,
                            apiService: service || ovAutoService,
                            columnAction: columnItemAction(item, button.actionType)
                          })
                        : routerLink(button, item)
                    "
                  >
                    {{ getObjectType(button?.title) === 'function' ? button?.title(item) : button?.title }}
                    <i *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></i>
                    <span *ngIf="button.tooltip" class="action-btn-tooltip-text">{{button.tooltip}}</span>
                  </button>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'status'">
                <div
                  aria-hidden="true"
                  class="fa fa-circle table-status-icon"
                  [ngStyle]="{ color: item[col?.key]?.color }"
                ></div>
                {{ item[col?.key]?.name }}
              </ng-container>

              <ng-container *ngSwitchCase="'pills'">
                  <span
                    class="badge badge-pill custom-badge-pill ml-1"
                    *ngFor="let itemValue of col?.action(item) | slice:0: (showMoreLessLimits[j] || col.limit);
                    let pillIndex = index">
                   {{ itemValue }}
                  </span>
                <span *ngIf="col.limit < col?.action(item).length"  #lessMore
                      class="ml-1 custom-badge-cursor-pointer btn-link"
                      (click)="onToggleLessMore(col.limit, j, col?.action(item).length, lessMore)"
                > show more </span>
              </ng-container>

            </ng-container>

          </td>
          <ng-container *ngIf="col.type == 'extra-columns'">
            <td *ngFor="let col of dynamicExtraColumns; let k = index">
            <span>
              {{ item[col.key][k]?.value }}
            </span>
            </td>
          </ng-container>
        </ng-container>

        <td *ngFor="let extraCol of extraColumns.data">
          <span *ngIf="!item[isEditable]">
            {{
              !!item['column_' + extraCol]
                ? extraColumns.columnData?.formatter(item['column_' + extraCol])
                : extraColumns.columnData?.formatter(extraColumns.columnData?.rowAction(item, extraCol))
            }}
          </span>
          <ov-suite-input
            *ngIf="item[isEditable]"
            [id]="item.id + 'row' + i + 'col' + extraCol"
            [type]="extraColumns.columnData?.rowReturnType"
            [(ngModel)]="item['column_' + extraCol]"
            (ngModelChange)="itemChanged(item)"
          ></ov-suite-input>
        </td>

      </tr>
      <tr *ngFor="let blank of filler; let i = index">
        <th *ngIf="selectableRows" scope="row"></th>
        <th *ngIf="showIndex" scope="row" style="opacity:0;">{{ i + 1 }}</th>
        <ng-container *ngFor="let col of filteredColumnData; let j = index">
          <td *ngIf="col.type != 'extra-columns'"
            style="opacity:0;"
          >
            x
          </td>
          <ng-container *ngIf="col.type == 'extra-columns'">
            <td
              *ngFor="let col of dynamicExtraColumns; let j = index"
              style="opacity:0;"
            >
              x
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>

  </table>
  <div class="row pt-0" [ngClass]="{ 'table-footer': showFiller }">
    <div class="mt-0 col-md-6 pager">

      Showing {{ data.length }} of {{ totalCount }} Entries
      <div class="float-right" *ngIf="loading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    &nbsp; &nbsp;
      <mat-form-field *ngIf="pageChangeEnabled">
        <mat-select [value]="pageSize.toString()" matNativeControl (valueChange)="updatePageSize($event)">
          <mat-option value="10">10 rows</mat-option>
          <mat-option value="20">20 rows</mat-option>
          <mat-option value="30">30 rows</mat-option>
          <mat-option value="40">40 rows</mat-option>
          <mat-option value="50">50 rows</mat-option>
          <mat-option value="100">100 rows</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="showPageSelect" class="col-md-6">
      <nav
        *ngIf="totalPages > 1"
        aria-label="Page navigation example"
        class="float-right"
      >
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageFirst()">
              <span aria-hidden="true">FIRST</span>
              <span class="sr-only">FIRST</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageBack()">
              <span aria-hidden="true">PREVIOUS</span>
              <span class="sr-only">PREVIOUS</span>
            </a>
          </li>
          <li
            *ngFor="let i of pages"
            class="page-item"
            [ngClass]="{ active: i === currentPage }"
            (click)="selectPage(i)"
          >
            <a class="page-link" aria-current="page"
              >{{ i + 1 }} <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageForward()">
              <span aria-hidden="true">NEXT</span>
              <span class="sr-only">NEXT</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageLast()">
              <span aria-hidden="true">LAST</span>
              <span class="sr-only">LAST</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
