<div class="advanced-search">
  <div class="input-group">
    <input
      class="form-control quick-search"
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="simpleSearch"
      (keyup)="onSimpleSearch()"
    >
    <span
      class="input-group-text"
      type="button"
      (click)="onSimpleSearch()"
    >
      <ov-suite-icons
        [icon]="'search'"
      ></ov-suite-icons>
    </span>
  </div>

  <ov-suite-mat-dialog
    [icon]="'filter'"
    [content]="advancedSearchDialog"
    [header]="false"
    [actions]="false"
    [toggler]="dialogListener"
  ></ov-suite-mat-dialog>
</div>

<ng-template #advancedSearchDialog>
  <ov-suite-advanced-search-dialog
    [fields]="fields"
    [listener]="dataListener"
  ></ov-suite-advanced-search-dialog>
</ng-template>
