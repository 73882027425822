import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentLoadService {
  private readonly configUrl = 'assets/config.json';

  constructor(private readonly http: HttpClient) {}

  public getJSON(): Observable<unknown> {
    return this.http.get(this.configUrl);
  }

  public getSavedServerPath() {
    return localStorage?.getItem('serverPath');
  }
}
