import {
  FieldMetadata,
  FieldParamsConstructor,
  getFieldMetadata,
  Constructor,
  GenericHierarchy,
  getTypeMetadata,
  HasId,
  PageGet,
  SingleCreate,
  SingleDelete,
  SingleGet,
  SingleUpdate,
} from '@ov-suite/ov-metadata';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export interface Query {
  [key: string]: unknown;
}

export interface DynamicService<T extends GenericHierarchy> {
  list: PageGet<T>;
  get: SingleGet<T>;
  create: SingleCreate<T>;
  update: SingleUpdate<T>;
  delete: SingleDelete<T>;
}

export function getUpdate<T extends GenericHierarchy>(newItem: T, oldItem: T): Partial<T> & { id: string | number } {
  const update = { ...newItem };
  const metadata = getFieldMetadata(newItem.constructor as Constructor<T>);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete update[field.propertyKey];
    } else if (field.readonly) {
      delete update[field.propertyKey];
    } else if ((field as FieldParamsConstructor).withQuantity) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[`${field.propertyKey}QuantityList`] = newItem[field.propertyKey].map(obj => {
          const { id, quantity, ...otherFields } = obj;
          const quantityField = { id, quantity };
          Object.keys(otherFields).forEach(key => {
            quantityField[`${key}Id`] = otherFields[key].id;
          });
          return quantityField;
        });
      }
    } else if (typeof field.type === 'function' && Array.isArray(field.type())) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[`${field.propertyKey}IdList`] = newItem[field.propertyKey].map(obj => obj.id);
      }
    } else if (typeof field.type === 'function' && (typeof field.type() === 'function' || typeof field.type() === 'string')) {
      const value = update[field.propertyKey];
      if (value?.id) {
        if (newItem[field.propertyKey]?.id !== oldItem[field.propertyKey]?.id) {
          update[`${field.propertyKey}Id`] = value.id;
        }
      }
      delete update[field.propertyKey];
    } else if (field.type === 'permission') {
      delete update[field.propertyKey];

      update[`${field.propertyKey}IdList`] = newItem[field.propertyKey].map(userTypeFeature => ({
        id: userTypeFeature.id,
        featureId: userTypeFeature.feature.id,
        permission: userTypeFeature.permission,
      }));
    } else if (field.type === 'boolean') {
      if (!!newItem[field.propertyKey] === !!oldItem[field.propertyKey]) {
        delete update[field.propertyKey];
      } else {
        update[field.propertyKey] = !!update[field.propertyKey];
      }
    } else if (newItem[field.propertyKey] === oldItem[field.propertyKey]) {
      delete update[field.propertyKey];
    }
  });

  update.id = oldItem.id;
  return update;
}

export function getCreate<T extends GenericHierarchy>(item: T): T {
  const output = { ...item };
  const metadata = getFieldMetadata<T>(item.constructor as Constructor<T>);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete output[field.propertyKey];
    } else if (field.generated) {
      delete output[field.propertyKey];
    } else if ((field as FieldParamsConstructor).withQuantity) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}QuantityList`] = item[field.propertyKey].map(obj => {
          const { quantity, ...otherFields } = obj;
          const quantityField = { quantity };
          Object.keys(otherFields).forEach(key => {
            quantityField[`${key}Id`] = otherFields[key].id;
          });
          return quantityField;
        });
      }
    } else if (typeof field.type === 'function' && Array.isArray(field.type())) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}IdList`] = item[field.propertyKey].map(obj => obj.id);
      }
    } else if (field.type === 'permission') {
      delete output[field.propertyKey];
      output[`${field.propertyKey}IdList`] = item[field.propertyKey].map(userTypeFeature => ({
        featureId: userTypeFeature.feature.id,
        permission: userTypeFeature.permission,
      }));
    } else if (typeof field.type === 'function' && (typeof field.type() === 'function' || typeof field.type() === 'string')) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}Id`] = item[field.propertyKey].id;
      }
    } else if (field.type === 'boolean') {
      output[field.propertyKey] = !!output[field.propertyKey];
    }
  });

  return output;
}

interface ListWithCountQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  specificKeys?: string[];
  api?: string;
}

export function listWithCountQueryKeys<T extends HasId>(params: ListWithCountQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys<T>(params);

  const header = `${name}($orderDirection: String, $orderColumn: String, $filter: String, $limit: Int, $offset: Int)`;
  const request = `${name}(orderDirection: $orderDirection, orderColumn: $orderColumn, filter: $filter, limit: $limit, offset: $offset)`;
  const body = `data { ${keys} } totalCount`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface ListQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  specificKeys?: string[];
  keys?: string[];
  api?: string;
}

export function listQueryKeys<T extends HasId>(params: ListQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys(params);

  const header = `${name}($params: ListParamsInput!)`;
  const request = `${name}(params: $params)`;
  const body = `data { ${keys} } totalCount`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

export function getQueryString(documentNode: DocumentNode): string {
  return documentNode.loc?.source.body;
}

interface GetAncestorQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
}

export function getAncestorQueryKeys<T>(params: GetAncestorQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  return gql(`query ${name}($id: Int!) {\n ${name}(id: $id) {\nid\nname\nchildren { id name }\n}\n}`);
}

interface GetQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
}

export function getQueryKeys<T extends HasId>(params: GetQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys(params);

  const header = `${name}($id: Int!)`;
  const request = `${name}(id: $id)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface GetByIdsQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
}

export function getByIdsQueryKeys<T extends HasId>(params: GetByIdsQueryKeysParams<T>): DocumentNode {
  const { name, input } = params;
  const keys = getAllKeys({
    ...params,
    input: [input],
  });

  const header = `${name}($ids: [Int!]!)`;
  const request = `${name}(ids: $ids)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface GetQueryKeysStringParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
}

export function getQueryKeysString<T extends HasId>(params: GetQueryKeysStringParams<T>): DocumentNode {
  const { name, input } = params;
  const keys = getAllKeys({
    ...params,
    input: [input],
  });

  const header = `${name}($id: String!)`;
  const request = `${name}(id: $id)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface CreateMutationKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
}

export function createMutationKeys<T extends HasId>(params: CreateMutationKeysParams<T>): DocumentNode {
  const { name, metadata } = params;
  const keys = getKeys(params);

  const header = `${name}($data: ${metadata.name}CreateInput!)`;
  const request = `${name}(data: $data)`;
  const body = `${keys}`;

  return gql(`mutation ${header} { ${request} { ${body} } }`);
}

export interface UpdateMutationKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
}

export function updateMutationKeys<T extends HasId>(params: UpdateMutationKeysParams<T>): DocumentNode {
  const { name, metadata } = params;
  const keys = getKeys(params);

  const header = `${name}($data: ${metadata.name}UpdateInput!)`;
  const request = `${name}(data: $data)`;
  const body = `${keys}`;

  return gql(`mutation ${header} { ${request} { ${body} } }`);
}

interface DeleteMutationKeysParams {
  name: string;
}

export function deleteMutationKeys(params: DeleteMutationKeysParams): DocumentNode {
  const { name } = params;
  return gql(`mutation ${name}($id: Int!) {\n ${name}(id: $id)\n}`);
}

interface GetAllKeysParams<T> {
  name: string;
  input: (Constructor<T> | Constructor<T>[])[];
  metadata: FieldMetadata<T>;
  noRepeat?: boolean;
  api?: string;
}

export function getAllKeys<T extends HasId>(params: GetAllKeysParams<T>): string {
  const { name, input, metadata, noRepeat = false, api } = params;
  const lines: string[] = [];

  const currentApi = (!metadata.api || metadata.api === 'shared') && api !== 'unknown' ? api : metadata.api;

  const fields = metadata.fields.filter(item => !item.unnecessary && (!item.apis.length || item.apis.includes(currentApi)));

  fields.forEach(field => {
    if (typeof field.type === 'string') {
      switch (field.type) {
        case 'boolean':
        case 'date':
        case 'date-time':
        case 'time':
        case 'number':
        case 'string':
        case 'json':
        case 'image':
          lines.push(field.propertyKey);
          break;
        case 'map':
          lines.push(field.propertyKey);
          lines.push(' {\n address\nlongitude\nlatitude\ngeofence\n}\n');
          break;
        case 'permission':
          lines.push(field.propertyKey);
          lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
          break;
        // Ignore case "Title"
        default:
      }
    } else if (!noRepeat) {
      if (field.idOnly) {
        lines.push(field.propertyKey);
      } else {
        const { metadata: newMetadata } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
        lines.push(`${field.propertyKey} { `);
        if ((field as FieldParamsConstructor)?.keys?.length) {
          lines.push((field as FieldParamsConstructor).keys.filter(key => !key.includes('.')).join('\n'));
          const deepKeys = (field as FieldParamsConstructor)?.keys.filter(key => key.includes('.'));
          if (deepKeys.length) {
            deepKeys.forEach(key => {
              const arrayKeys = key.split('.');
              let gqlLine = arrayKeys.join(' {\n');
              gqlLine += '\n}'.repeat(arrayKeys.length - 1);
              lines.push(gqlLine);
            });
          }
        } else {
          const { entity: fieldType } = getTypeMetadata(field.type);
          lines.push(
            getAllKeys({
              name,
              input: [...input, [fieldType]],
              metadata: newMetadata,
              noRepeat: input.some(item => item.toString() === fieldType.toString()),
            }),
          );
        }
        lines.push('}');
      }
    }
  });

  return lines.join('\n');
}

interface GetSpecificKeysParams<T> {
  name?: string;
  input: (Constructor<T> | Constructor<T>[])[];
  keys: string[];
  metadata?: FieldMetadata<T>;
  noRepeat?: boolean;
  api?: string;
}

export function getSpecificKeys<T extends HasId>(params: GetSpecificKeysParams<T>): string {
  const { input, keys, metadata, name, noRepeat = false, api } = params;
  const lines: string[] = [];

  const localMetadata = metadata ?? getFieldMetadata(Array.isArray(input) ? (input[0] as Constructor<T>) : input);
  const localName = name ?? localMetadata.plural;

  const currentApi = (!metadata.api || metadata.api === 'shared') && api !== 'unknown' ? api : metadata.api;

  let fields = localMetadata.fields.filter(item => !item.unnecessary && (!item.apis.length || item.apis.includes(currentApi)));

  const currentKeys = keys.map(key => key.split('.')[0]);

  fields = fields.filter(field => currentKeys.includes(field.propertyKey));

  fields.forEach(field => {
    if (typeof field.type === 'string') {
      switch (field.type) {
        case 'boolean':
        case 'date':
        case 'date-time':
        case 'time':
        case 'number':
        case 'numbers':
        case 'string':
        case 'json':
        case 'image':
          lines.push(field.propertyKey);
          break;
        case 'map':
          lines.push(field.propertyKey);
          lines.push(' {\n address\nlongitude\nlatitude\ngeofence\n}\n');
          break;
        case 'permission':
          lines.push(field.propertyKey);
          lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
          break;
        // Ignore case "Title"
        default:
      }
    } else if (!noRepeat) {
      if (field.idOnly) {
        lines.push(field.propertyKey);
      } else {
        const { metadata: newMetadata } = getTypeMetadata(field.type);
        lines.push(`${field.propertyKey} { `);
        if ((field as FieldParamsConstructor)?.keys?.length) {
          lines.push((field as FieldParamsConstructor).keys.filter(key => !key.includes('.')).join('\n'));
          const deepKeys = (field as FieldParamsConstructor)?.keys.filter(key => key.includes('.'));
          if (deepKeys.length) {
            deepKeys.forEach(key => {
              const arrayKeys = key.split('.');
              let gqlLine = arrayKeys.join(' {\n');
              gqlLine += '\n}'.repeat(arrayKeys.length - 1);
              lines.push(gqlLine);
            });
          }
        } else {
          const { entity: fieldType } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
          const newKeys = keys
            .filter(key => key.startsWith(field.propertyKey))
            .map(key => key.split('.').slice(1).join('.'))
            .filter(key => !!key);

          if (!newKeys.length) {
            lines.push(
              getAllKeys({
                name: localName,
                input: [...input, fieldType],
                metadata: newMetadata,
                noRepeat: input.some(item => item.toString() === fieldType.toString()),
              }),
            );
          } else {
            lines.push(
              getSpecificKeys({
                input: [...input, fieldType],
                keys: newKeys,
                metadata: newMetadata,
                name: localName,
                noRepeat: field.propertyKey === 'parent' ? false : input.some(item => item.toString() === fieldType.toString()),
              }),
            );
          }
        }
        lines.push('}');
      }
    }
  });

  return lines.join('\n');
}

type AllParams<T> =
  | (GetQueryKeysParams<T> & { type: 'get' })
  | (ListQueryKeysParams<T> & { type: 'list' })
  | (DeleteMutationKeysParams & { type: 'delete' })
  | (UpdateMutationKeysParams<T> & { type: 'update' })
  | (CreateMutationKeysParams<T> & { type: 'create' });

export type MultipleKeysParams<T> = Record<string, AllParams<T>>;

export function multipleQueryKeys<T extends HasId>(params: MultipleKeysParams<T>): DocumentNode {
  const variableParams: string[] = [];
  const query: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    let filler: string;
    let method: string;
    let content = '';

    switch (value.type) {
      case 'get':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      case 'create':
        variableParams.push(`$${key}Data: ${value.metadata.name}CreateInput!`);
        method = `${value.name}(data: $${key}Data!)`;
        break;
      case 'list':
        variableParams.push(`$${key}Data: ListParamsInput!`);
        method = `${value.name}(params: $${key}Data)`;
        break;
      case 'update':
        variableParams.push(`$${key}Data: ${value.metadata.name}UpdateInput!`);
        method = `${value.name}(data: $${key}data)`;
        break;
      case 'delete':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      default:
    }

    if (value.type !== 'delete') {
      filler = getKeys(value);
    }

    switch (value.type) {
      case 'get':
      case 'create':
      case 'update':
        content = `{ ${filler} }`;
        break;
      case 'list':
        content = `{ data { ${filler} } totalCount }`;
        break;
      default:
    }

    const str = `${key}: ${method} ${content}\n`;
    query.push(str);
  });

  const headerParams = variableParams.join(', ');
  const body = query.join('\n');

  return gql(`query Multiple(${headerParams}) { ${body} }`);
}

export function multipleMutationKeys<T extends HasId>(params: MultipleKeysParams<T>): DocumentNode {
  const variableParams: string[] = [];
  const query: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    let filler: string;
    let method: string;
    let content = '';

    switch (value.type) {
      case 'create':
        variableParams.push(`$${key}Data: ${value.metadata.name}CreateInput!`);
        method = `${value.name}(data: $${key}Data)`;
        break;
      case 'update':
        variableParams.push(`$${key}Data: ${value.metadata.name}UpdateInput!`);
        method = `${value.name}(data: $${key}Data)`;
        break;
      case 'delete':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      default:
    }

    if (value.type !== 'delete') {
      filler = getKeys(value);
    }

    switch (value.type) {
      case 'create':
      case 'update':
        content = `{ ${filler} }`;
        break;
      default:
    }

    const str = `${key}: ${method} ${content}\n`;
    query.push(str);
  });

  const headerParams = variableParams.join(', ');
  const body = query.join('\n');

  return gql(`mutation Multiple(${headerParams}) { ${body} }`);
}

interface GetKeysParams<T> {
  specificKeys?: string[];
  keys?: string[];
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  name: string;
}

function getKeys<T extends HasId>(params: GetKeysParams<T>) {
  const { specificKeys, input, metadata, name, api, keys } = params;
  const keysToUse = specificKeys ?? keys;
  return keysToUse?.length
    ? getSpecificKeys({
        input: [input],
        keys: keysToUse,
        metadata,
        api,
      })
    : getAllKeys({
        name,
        input: [input],
        metadata,
        api,
      });
}
