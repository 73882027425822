<hr />
<h6 class="mb-4">User Type Permissions</h6>
<div class="col-12">
  <div class="row mb-4" *ngFor="let app of allApps; let a = index">
    <div class="mb-1">
      {{ app.name }}
      <label>
        <i
          aria-hidden="true"
          class="fa fa-info-circle"
          placement="right-top"
          [ngbTooltip]="
            'Select the permissions you would like this user type to have in ' +
            app.name
          "
        ></i>
      </label>
    </div>
    <div class="col-12 permission-border">
      <div class="row">
        <div class="col-12">
          <div *ngFor="let featureKey of app.features; let i = index">
            <div class="text-dark" [ngClass]="{ 'mt-3': i !== 0 }">
              {{ featureKey.name }}
            </div>
            <div class="form-group mt-1">
              <div class="input-group mb-0">
                <div class="form-checkbox">
                  <input
                    [id]="a.toString() + i.toString() + 'A'"
                    type="checkbox"
                    [checked]="this.helperMap[featureKey.id].permission === 15"
                    [disabled]="featureKey.defaultPermission === 15"
                    (change)="toggleAll(featureKey)"
                  />
                  <label [for]="a.toString() + i.toString() + 'A'">All</label>
                </div>

                <div class="form-checkbox">
                  <input
                    [id]="a.toString() + i.toString() + 'C'"
                    type="checkbox"
                    [checked]="
                      hasPermission(this.helperMap[featureKey.id].permission, 8)
                    "
                    [disabled]="featureLock[featureKey.id]?.create"
                    (change)="checkPermission(featureKey, 8)"
                  />
                  <label
                    [for]="a.toString() + i.toString() + 'C'"
                    class="form-check-label"
                    >Create</label
                  >
                </div>

                <div class="form-checkbox">
                  <input
                    [id]="a.toString() + i.toString() + 'R'"
                    type="checkbox"
                    [checked]="
                      hasPermission(this.helperMap[featureKey.id].permission, 4)
                    "
                    [disabled]="featureLock[featureKey.id]?.read"
                    (change)="checkPermission(featureKey, 4)"
                  />
                  <label
                    [for]="a.toString() + i.toString() + 'R'"
                    class="form-check-label"
                    >View</label
                  >
                </div>

                <div class="form-checkbox">
                  <input
                    [id]="a.toString() + i.toString() + 'U'"
                    type="checkbox"
                    [checked]="
                      hasPermission(this.helperMap[featureKey.id].permission, 2)
                    "
                    [disabled]="featureLock[featureKey.id]?.update"
                    (change)="checkPermission(featureKey, 2)"
                  />
                  <label
                    [for]="a.toString() + i.toString() + 'U'"
                    class="form-check-label"
                    >Edit</label
                  >
                </div>

                <div class="form-checkbox">
                  <input
                    [id]="a.toString() + i.toString() + 'D'"
                    type="checkbox"
                    [checked]="
                      hasPermission(this.helperMap[featureKey.id].permission, 1)
                    "
                    [disabled]="featureLock[featureKey.id]?.delete"
                    (change)="checkPermission(featureKey, 1)"
                  />
                  <label
                    [for]="a.toString() + i.toString() + 'D'"
                    class="form-check-label"
                    >Delete</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
