<div mat-dialog-content>
  <mat-card class="user-account">
    <img
      mat-card-lg-image
      class="user-avatar"
      [src]="userAccount.avatarUrl"
      alt=""
    />

    <mat-card-header class="ov-card-header">
      <mat-card-title class="mat-subheading-2">{{ userAccount.username }}</mat-card-title>
      <mat-card-subtitle class="mat-caption">{{ userAccount.email }}</mat-card-subtitle>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
      <mat-nav-list>
        <mat-list-item class="ov-nav-list-item" (click)="manageUserAccount()">
          <mat-icon>manage_accounts</mat-icon>
          <span class="mat-subheading-1">Manage Account</span>
        </mat-list-item>
        <mat-list-item class="ov-nav-list-item" (click)="signOut()">
          <mat-icon>logout</mat-icon>
          <span class="mat-subheading-1">Sign Out</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>
</div>
