import gql from 'graphql-tag';
import { Resource } from '@ov-suite/models-warehouse';

export function listResourcesQuery(keys: (keyof Resource)[]) {
  return gql(`query ListResource($params: ListParamsInput!) {
    listResource(params: $params) {
      data {
        ${keys.join(`\n`)}
      }
      totalCount
     }
   }`);
}
