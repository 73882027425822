import { OVEntity, OVField, OVForm, OVSidebar, OVTable } from '@ov-suite/ov-metadata';

@OVEntity('PersonnelPositionStatus', 'idmlink')
export class PersonnelPositionStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('PersonnelPosition', 'shared')
@OVForm([['#Personnel Position Details'], ['Personnel Position Details'], ['name'], ['description']])
@OVSidebar([
  // ['image'],
  ['status'],
  ['id'],
])
@OVTable<PersonnelPosition>([
  {
    key: 'id',
    title: 'Personnel ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Personnel Position Name',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description Name',
    type: 'string',
  },
])
export class PersonnelPosition {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  // @Searchable()
  @OVField({
    type: 'string',
    title: 'Personnel Position Name',
    tooltip: 'Create a Personnel Position Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    tooltip: 'Add a description to this personnel position',
    title: 'Description',
    required: false,
  })
  description?: string;

  // @SidebarField({ type: 'dropdown', title: 'Status' })
  @OVField({
    type: () => PersonnelPositionStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
  })
  status: PersonnelPositionStatus;
}
