import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FieldMetadata, GenericHierarchy } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-table-top-bar',
  templateUrl: './table-top-bar.component.html',
  styleUrls: ['./table-top-bar.component.scss']
})
export class TableTopBarComponent<T extends GenericHierarchyType>
  implements OnInit {
  @Input() barTitle: string;
  @Input() hasBulkUpload = false;
  @Input() metadata: FieldMetadata<T>;
  @Input() constructorName: string;
  @Output() search = new EventEmitter();
  @Input() customizing = false;
  @Input() filterQuery: Record<string, string[]>;
  @Input() hideBulkUpload = false;
  @Output() customize = new EventEmitter();
  @Output() hasUploaded = new EventEmitter();
  active: string;
  trigger = 0;
  showSearch = false;

  constructor(private readonly router: Router,
              private readonly modalService: NgbModal,
  ) {}

  ngOnInit() {}

  onSearchChange(searchEvent: Event) {
    this.search.emit(searchEvent);
  }

  onCustomize() {
    this.customize.emit();
  }

  selectedDate(date) {
    if (date)
      this.search.emit(date);
  }

  uploaded(uploaded: boolean) {
    this.hasUploaded.emit(uploaded);
  }
}
