import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'ov-suite-hierarchy-list-actions',
  templateUrl: './hierarchy-list-actions.component.html',
  styleUrls: ['./hierarchy-list-actions.component.css'],
})
export class HierarchyListActionsComponent implements OnInit, OnDestroy {
  timeObs: Subscription = new Subscription();

  live = false;

  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.timeObs = interval(30000).subscribe(() => {
      if (this.live) {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.timeObs.unsubscribe();
  }

  onLiveClick() {
    this.live = !this.live;
  }

  refresh() {
    this.refreshEmitter.emit(true);
  }

  getButtonColor() {
    return this.live ? 'accent' : 'blank';
  }
}
