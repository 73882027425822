<!--<a (click)="domainsDialogOpen ? closeDialog() : openDialog($event)">-->
<!--  <i class="fa fa-globe fa-3x domains-global"></i>-->
<!--&lt;!&ndash;  <li>&ndash;&gt;-->
<!--    <span class="mat-subheading-2" *ngIf="opened">Selected Domain</span>-->
<!--    <span class="mat-subheading-1" *ngIf="opened">4 Domains</span>-->
<!--&lt;!&ndash;  </li>&ndash;&gt;-->
<!--</a>-->
<mat-nav-list class="ov-sidebar">
  <mat-list-item
    class="ov-sidebar-list-item ov-side-bar-titles"
    (click)="domainsDialogOpen ? closeDialog() : openDialog(domainsIcon._getHostElement())"
    [ngClass]="opened ? '' : 'closed'"
    #domainsIcon
  >
    <i
      mat-list-icon
      class="fa fa-globe fa-2x domains-global"
      aria-hidden="true"
    >
    </i>
    <div *ngIf="opened" class="domains-title-info">
      <div mat-line><span class="mat-title">{{ selectedDomain.name }}</span></div>
      <div mat-line><span class="mat-caption">{{ domains.length }} Domains</span></div>
    </div>
  </mat-list-item>
</mat-nav-list>
