import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GenericHierarchy } from '@ov-suite/ov-metadata';
import { AutoService, OvAutoService } from '@ov-suite/services';
import { BehaviorSubject } from 'rxjs';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedSearchChange, ObjectModel } from '../hierarchy-list/hierarchy-list.component';

export interface CustomDateRange<T = string | NgbDateStruct | NgbDate> {
  type?: string;
  from: T;
  to: T;
}

export interface FilterFieldData<T = string | NgbDateStruct | NgbDate> {
  dropdown?: string;
  type?: string;
  range?: string;
  data?: unknown;
  value?: CustomDateRange<T> | string;
  title?: string;
  key?: string;
  changed?: boolean;
  placeholder?: string;
}

const createInput = (field: FilterFieldData) => ({
  type: field.dropdown ? 'dropdown' : field.type,
  range: field.type === 'date-time',
  data: null,
  value: field.type === 'date-time' ? { from: null, to: null } : null,
  title: field.title,
  key: field['key'],
  changed: false,
  placeholder: field.dropdown ? `Select ${field.title}` : `Enter ${field.title}`,
});

@Component({
  selector: 'ov-suite-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent<T extends GenericHierarchy> implements OnInit, OnDestroy {
  @Input() service: AutoService<T> | OvAutoService;

  @Input() hideColumnKeys: string[] = [];

  @Input() model: ObjectModel<T>;

  fields: FilterFieldData[] = [];

  // Listen to results from the dialog itself.
  dataListener: BehaviorSubject<FilterFieldData[] | string>;

  // Listen to result events on the dialog.
  dialogListener: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  @Output() advancedSearchChange: EventEmitter<AdvancedSearchChange> = new EventEmitter<AdvancedSearchChange>();

  advancedSearch: AdvancedSearchChange;

  simpleSearch = '';

  ngOnInit() {
    this.advancedSearch = {
      filter: {},
      query: {},
      search: {},
    };

    this.fields = this.prepareFormFields();

    this.dataListener = new BehaviorSubject<FilterFieldData[] | string>(this.fields);

    this.dataListener.subscribe((result: string | FilterFieldData[]) => {
      switch (result) {
        case 'close':
          // When close dialog is selected
          this.closeDialog();
          break;
        case 'reset':
          // When reset fields is selected
          this.resetSearchFields();
          break;
        default:
          if (result['operation'] === 'clear') {
            this.clearField(result['field']);
          } else if (result['operation'] === 'apply') {
            this.prepareFilterResponse(result['fields']);
          }
          break;
      }
    });
  }

  ngOnDestroy() {
    this.dataListener.unsubscribe();
    this.dialogListener.unsubscribe();
  }

  clearField(field: FilterFieldData) {
    if (field.range) {
      delete this.advancedSearch.query[field.key];
    } else {
      delete this.advancedSearch.filter[field.key];
    }
  }

  onSimpleSearch() {
    if (this.simpleSearch) {
      this.fields.forEach(item => {
        this.advancedSearch['search'][item['key']] = [this.simpleSearch];
      });
    } else {
      this.advancedSearch['search'] = {};
    }

    this.advancedSearchChange.emit(this.advancedSearch);
  }

  resetSearchFields() {
    this.fields.forEach(item => {
      // Reset field inputs
      if (item['range']) {
        item['value'] = {
          from: null,
          to: null,
        };
        // Reset filter value
        delete this.advancedSearch['query'][item['key']];
      } else {
        item['value'] = null;
        // Reset Filter
        delete this.advancedSearch['filter'][item['key']];
      }
    });
  }

  closeDialog(): void {
    this.dialogListener.next(false);
  }

  prepareFilterResponse(formattedFields: FilterFieldData[]): void {
    this.fields = formattedFields;

    // Apply field values to the filter
    formattedFields.forEach(field => {
      const temp = [];

      if (field.range) {
        if (typeof field.value !== 'string' && field.value.from && field.value.to) {
          temp.push(field.value);
        }
      } else if (field.value) {
        temp.push(field.value);
      }

      if (temp.length) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        field.range ? (this.advancedSearch['query'][field.key] = temp) : (this.advancedSearch['filter'][field.key] = temp);
      }
    });

    this.advancedSearchChange.emit(this.advancedSearch);

    this.closeDialog();
  }

  prepareFormFields() {
    return this.prepareFieldInputs(this.model.filter as FilterFieldData[]);
  }

  prepareFieldInputs(fields: FilterFieldData[]) {
    const filterFields = [];

    fields.forEach(field => {
      const f = createInput(field);
      filterFields.push(f);
    });

    return filterFields;
  }
}
