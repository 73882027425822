<h4 mat-dialog-title class="mat-subheading-2">Domain Switcher</h4>
<div mat-dialog-content>
  <mat-form-field class="input-form-field" appearance="standard">
    <mat-label>Search Domains</mat-label>
    <input
      #search
      matInput
      placeholder=""
      (keyup)="filterDomains()"
    >
    <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-nav-list>
    <mat-list-item
      class="ov-nav-list-item"
      *ngFor="let domain of domainsFilter"
      (click)="changeDomain(domain)"
    >
      <span
        class="mat-subheading-1"
        [ngStyle]="{ 'font-weight' : domain.name === selectedDomain.name ? 'bolder' : ''}"
      >
        {{ domain['name'] }}
      </span>
    </mat-list-item>
  </mat-nav-list>
</div>
