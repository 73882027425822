<div class="row card-table-container">

    <div class="col-4">

      <div class="add-new-card-item-container">
        <div class="add-new-card-item-body" (click)="onAddNewItem()">
          <i class="fa fa-plus-square-o fa-3x text-muted" aria-hidden="true"> </i>
          <p class="text-muted">New Wave</p>
        </div>
      </div>

    </div>

    <div *ngFor="let item of compiledData" class="col-4">
      <div class="card pt-2 pl-3 pr-3">
        <div class="card-body m-1">
          <div class="row flex-row justify-content-between align-items-start">
            <h4 class="card-title m-0">{{ item.title }}</h4>
            <div>
              <button (click)="onEditItem(item)" class="icon-btn"> <i class="fa fa-pencil fa-2x text-muted" aria-hidden="true"> </i> </button>
              <button (click)="onRemoveItem(item)"  class="icon-btn"> <i class="fa fa-trash-o fa-2x text-muted" aria-hidden="true"> </i> </button>
              <button (click)="onCopyItem(item)"  class="icon-btn"> <i class="fa fa-clone fa-2x text-muted" aria-hidden="true"> </i> </button>
            </div>
          </div>

          <div *ngFor="let subItem of item.body" class="row">
            <p class="card-text">{{ subItem }}</p>
          </div>

        <div class="card-footer text-muted">
          <div class="row flex-row justify-content-between">
            <div> {{ item.footer }} </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineRecurringCheckbox">Recurring</label>
              <input class="form-check-input" type="checkbox" id="inlineRecurringCheckbox" [value]="item.recurring" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>

  </div>

</div>
