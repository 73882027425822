import { Constructor } from '@ov-suite/ov-metadata';

export function OVTag<T>(tag: string, entity: Constructor<T>): ClassDecorator {
  return <U = unknown>(constructor: U): U | void => {
    let output: U = Reflect.getMetadata(tag, entity);
    output = constructor;
    Reflect.defineMetadata(tag, output, entity);
  };
}

export function OVTagMethod<T>(
  tag: string,
  entity: Constructor<T>
): MethodDecorator {
  return <U = unknown>(
    constructor: U,
    name: string,
    descriptor: TypedPropertyDescriptor<U>
  ): U | void => {
    let output: U = Reflect.getMetadata(tag, entity);
    output = descriptor.value;
    Reflect.defineMetadata(tag, output, entity);
  };
}

export function getTaggedClass<T, U>(
  tag: string,
  constructor: Constructor<T>
): Constructor<U> {
  return Reflect.getMetadata(tag, constructor);
}
export function getTaggedMethod<T, U>(
  tag: string,
  constructor: Constructor<T>
): Function {
  return Reflect.getMetadata(tag, constructor);
}
