import { OVEntity, OVField } from '@ov-suite/ov-metadata';

@OVEntity('FeatureStatus', 'idmlink')
export class FeatureStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}
