import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Domain } from '@ov-suite/models-admin';
import { environment, SessionStorage } from '@ov-suite/helpers-angular';
import { OvAutoService } from '@ov-suite/services';
import { Subject } from 'rxjs';
import { RouteInfo } from '@ov-suite/ui';
import { getPermissionIds } from '@ov-suite/authguard-angular';
import { Hub } from '@aws-amplify/core';

interface OvApps {
  id,
  title,
  url,
  hasPermission,
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  userDomains: Domain[] = [];
  userApps: OvApps[];
  routePermissions: number[];
  userRoutes: RouteInfo[] = [];

  userDomainsSubject = new Subject<Domain[]>();
  userAppsSubject = new Subject<OvApps[]>();
  userRoutesSubject = new Subject<RouteInfo[]>();

  currentUserDomainSubject = new Subject<Domain>();

  pageTitleSubscription = new Subject<string>();

  apps = [];

  constructor(private readonly ovAutoService: OvAutoService) {
    Hub.listen('token', () => {
      this.initUser();
    });

    this.initUser();

    getPermissionIds()
      .then(permissionIds => {
        this.routePermissions = permissionIds;
      });

    this.getDefaultApps();
  }

  initUser() {
    Auth.currentSession().then(session => {
      const currentUserTokenInfo = session.getIdToken().decodePayload();

      const domains = JSON.parse(currentUserTokenInfo.lambdaDomainIds);
      const apps = JSON.parse(currentUserTokenInfo.lambdaAppIds);

      // Get AppsPermissions
      this.userApps = this.setUserAppsPermissions(apps);
      setTimeout(() => this.userAppsSubject.next(this.userApps), 0);

      // Get Domains
      this.setUserDomains(domains).then((allowedDomains) => {
        this.userDomains = allowedDomains;
        setTimeout(() => this.userDomainsSubject.next(this.userDomains), 0);
      }).catch(e => {
        throw new Error(e.message);
      }).finally(() => {
        this.getUserDomain();
      });
    });
  }

  async setUserDomains(domains: number[]): Promise<Domain[]> {
    return this.ovAutoService
      .getAll(Domain, domains, 'adminlink')
      .then(result => {
        return ([ ...result ].sort((a, b) => a.name.localeCompare(b.name)));
      });
  }

  setUserAppsPermissions(appIds: number[]): OvApps[] {
    return this.apps
      .filter(app => {
        if (appIds.includes(app.id)) {
          app.hasPermission = true;
          return true;
        }
      });
  }

  setUserRoutes(routes: RouteInfo[]) {
    routes.forEach(parent => {
      const route = Object.assign(parent, {});
      route[ 'active' ] = false;

      parent.children?.forEach((itemChild, index) => {
        itemChild[ 'active' ] = false;
        if (itemChild.permissionId && !this.routePermissions.includes(itemChild.permissionId)) {
          route.children.splice(index);
        }
      });

      this.userRoutes.push(route);
    });

    setTimeout(() => this.userRoutesSubject.next(this.userRoutes), 0);
  }

  setUserDomain(domain: Domain) {
    SessionStorage.setSelectedDomain(domain.id.toString());
    window.location.reload();
  }

  getUserDomain() {
    const selectedDomain = SessionStorage.getSelectedDomain();

    if (!selectedDomain && this.userDomains.length > 0) {
      SessionStorage.setSelectedDomain(this.userDomains[ 0 ].id.toString());
    }

    let selectedDomain_ = this.userDomains.find(item => item.id === +SessionStorage.getSelectedDomain());

    this.currentUserDomainSubject.next(selectedDomain_);
  }

  getDefaultApps() {
    this.apps = [
      {
        id: +environment.values.appId.admin,
        title: 'Admin Link',
        url: environment.values.webUrl.admin,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.execution,
        title: 'Execution Link',
        url: environment.values.webUrl.execution,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.idm,
        title: 'Account Link',
        url: environment.values.webUrl.idm,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.ceramicPortal,
        title: 'Invoice Portal',
        url: environment.values.webUrl.ceramicPortal,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.order,
        title: 'Order Link',
        url: environment.values.webUrl.order,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.warehouse,
        title: 'Warehouse Link',
        url: environment.values.webUrl.warehouse,
        hasPermission: false,
      },
      {
        id: +environment.values.appId.yard,
        title: 'Yard Link',
        url: environment.values.webUrl.yard,
        hasPermission: false,
      },
    ];
  }

}
