export interface FormioModel {
  display?: string;
  settings?: {
    pdf: {
      id: string;
      src: string;
    };
  };
  fault?: string;
  components: FormioComponent[];
}

export type ComponentNames =
  | 'textfield'
  | 'textarea'
  | 'number'
  | 'button'
  | 'signature'
  | 'fieldset'
  | 'content'
  | 'checkbox'
  | 'select'
  | 'photo'
  | 'table'
  | 'activeflows'
  | 'pdf417'
  | 'lookup'
  | 'imagefield'
  | 'multibuttonselect'
  | 'maptrigger'
  | 'svgicon';

export enum LocationModelOptions {
  DEVICE_COORDINATES = 'DEVICE_COORDINATES',
  STATION = 'STATION',
  GEOFENCES = 'GEOFENCES',
}

export type FormioComponent =
  | TextFieldModel
  | TextModel
  | NumberFieldModel
  | ButtonModel
  | TextAreaFieldModel
  | SignatureModel
  | FieldSetLayout
  | ContentLayout
  | CheckboxModel
  | SelectFieldModel
  | PhotoModel
  | TableModel
  | ActiveFlowsModel
  | PDF417ScanModel
  | LookupModel
  | ImageFieldModel
  | RackControlModel
  | MultiButtonSelectModel
  | UnknownModel
  | MapTriggerModel
  | AddressModel
  // | ModelFieldModel
  | IconModel
  | LocationModel
  | QRFieldModel
  | FullVehicleScanModel;

export interface CommonProps {
  id: string;
  index: number;
  testID?: string;
  // setValue: SetValue;
  // getValue: GetValue;
  variables?: Record<string, unknown>;
  testCallback?: () => void;
}

export interface UnknownModel {
  key: string;
  type: 'unknown';
  input: boolean;
  validate?: {
    required?: boolean;
    pattern?: string;
    customMessage?: string;
    minLength?: number;
    maxLength?: number;
    minWords?: number;
    maxWords?: number;
  };
  label?: string;
  variables?: Record<string, unknown>;
}

export interface TextFieldModel {
  label: string;
  labelPosition?: 'top' | 'bottom'; // 'top'
  placeholder?: string;
  description?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  // widget?: any;
  inputMask?: string; // see documentation
  inputMasks?: { label: string; mask: string }[];
  tabindex?: string;
  validate?: {
    required?: boolean;
    pattern?: string;
    customMessage?: string;
    minLength?: number;
    maxLength?: number;
    minWords?: number;
    maxWords?: number;
  };
  unique?: boolean; // false
  errorLabel?: string;
  hidden?: boolean; // false
  hideLabel?: boolean; // false
  showWordCount?: boolean; // false
  showCharCount?: boolean; // false
  mask?: boolean; // false
  autofocus?: boolean; // false
  disabled?: boolean; // false
  alwaysEnabled?: boolean;
  key: string;
  conditional?: Conditional;
  type: 'textfield';
  input: true;
  attributes?: { [key: string]: string };
}

// export interface ModelFieldModel {
//   label: string;
//   labelPosition?: 'top' | 'bottom'; // 'top'
//   placeholder?: string;
//   description?: string;
//   tooltip?: string;
//   prefix?: string;
//   suffix?: string;
//   // widget?: any;
//   inputMask?: string; // see documentation
//   inputMasks?: { label: string; mask: string }[];
//   tabindex?: string;
//   validate?: {
//     required?: boolean;
//     pattern?: string;
//     customMessage?: string;
//     minLength?: number;
//     maxLength?: number;
//     minWords?: number;
//     maxWords?: number;
//   };
//   unique?: boolean; // false
//   errorLabel?: string;
//   hidden?: boolean; // false
//   hideLabel?: boolean; // false
//   showWordCount?: boolean; // false
//   showCharCount?: boolean; // false
//   mask?: boolean; // false
//   autofocus?: boolean; // false
//   disabled?: boolean; // false
//   alwaysEnabled?: boolean;
//   key: string;
//   conditional?: Conditional;
//   type: 'modelfield';
//   input: true;
//   model: string;
//   api: 'yard';
//   pickerLabel?: string;
//   attributes?: { [key: string]: string };
// }

export interface TextModel {
  key: string;
  text: string;
  fontSize: number;
  type: 'text';
  input: false;

  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
  hidden?: boolean; // false
  conditional?: Conditional;
  color?: string;
  attributes?: { [key: string]: string };
}

export interface AddressModel {
  hidden?: boolean; // false
  key: string;
  conditional?: Conditional;
  type: 'address';
  useCurrent?: boolean;
  input: true;
  attributes?: { [key: string]: string };
}
export interface TextAreaFieldModel {
  label: string;
  labelPosition?: 'top' | 'bottom'; // 'top'
  placeholder?: string;
  description?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  // widget?: any;
  autoExpand: boolean;
  tabindex?: string;
  validate?: {
    required?: boolean;
    pattern?: string;
    customMessage?: string;
    minLength?: number;
    maxLength?: number;
    minWords?: number;
    maxWords?: number;
  };
  unique?: boolean; // false
  errorLabel?: string;
  hidden?: boolean; // false
  hideLabel?: boolean; // false
  showWordCount?: boolean; // false
  showCharCount?: boolean; // false
  autofocus?: boolean; // false
  disabled?: boolean; // false
  alwaysEnabled?: boolean;
  key: string;
  conditional?: Conditional;
  type: 'textarea';
  rows?: number; // 3
  input: true;
  attributes?: { [key: string]: string };
}

export interface NumberFieldModel {
  label: string;
  labelPosition?: 'top' | 'bottom';
  placeholder?: string;
  description?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  tabindex?: string;
  hidden?: boolean;
  hideLabel?: boolean;
  mask?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  delimiter?: boolean;
  requireDecimal?: boolean;
  inputFormat?: 'plain';
  validate?: {
    required?: boolean;
    customMessage?: string;
    min?: number;
    max?: number;
  };
  conditional?: Conditional;
  errorLabel?: string;
  key: string;
  type: 'number';
  input: true;
  attributes?: { [key: string]: string };
}

type SvgIcon = 'done' | 'cancel' | 'right-arrow';

export interface ButtonModel {
  label: string;
  action?:
    | 'event'
    | 'submit'
    | 'saveState'
    | 'custom'
    | 'reset'
    | 'oauth'
    | 'url';
  state?: string; // Used when action = saveState;
  custom?: string; // code snippet used when action = custom;
  url?: string; // url used by action = url;
  headers?: { header: string; value: string }[]; // header used by action = url;
  showValidations?: boolean;
  theme?: 'primary' | 'secondary' | 'info' | 'success' | 'danger' | 'warning'; // primary
  size?: 'xs' | 'sm' | 'md' | 'lg'; // md
  block?: boolean;
  leftIcon?: SvgIcon;
  rightIcon?: SvgIcon;
  shortcut?: string;
  description?: string;
  tooltip?: string;
  tabindex?: string;
  disableOnInvalid?: boolean;
  hidden?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  key: string;
  conditional?: Conditional;
  type: 'button';
  input: true;
  attributes?: { [key: string]: string };
}

export interface SignatureModel {
  label: string;
  footer?: string; // Sign Above
  labelPosition?: 'bottom' | 'top'; // top
  width?: string; // 100%
  height?: string; // 150px
  backgroundColor?: string; // rgb(245,245,245)
  penColor?: string; // black
  description?: string;
  tooltip?: string;
  tabindex?: string;
  hidden?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  errorLabel?: 'err label';
  key: string;
  conditional?: Conditional;
  type: 'signature';
  input: true;
  attributes?: { [key: string]: string };
}

export interface LocationModel {
  label: string;
  backgroundColor?: string; // rgb(245,245,245)
  description?: string;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  errorLabel?: 'err label';
  key: string;
  type: 'location';
  option: LocationModelOptions;
  input: true;
  attributes?: { [key: string]: string };
}

export interface FieldSetLayout {
  legend?: string;
  tooltip?: string;
  key: string;
  type: 'fieldset';
  label?: string;
  input: false;
  components: FormioComponent[];
  conditional?: Conditional;
  path?: string;
  attributes?: { [key: string]: string };
}

export interface ContentLayout {
  html?: string;
  url?: string;
  urlKey?: string;
  label?: string;
  refreshOnChange?: boolean;
  conditional?: Conditional;
  key: string;
  type: 'content';
  input: false;
  textZoom?: number;
  attributes?: { [key: string]: string };
}

export interface CheckboxModel {
  label: string;
  description?: string;
  tooltip?: string;
  shortcut?: string;
  tabindex?: string;
  hidden?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  errorLabel?: string;
  key: string;
  conditional?: Conditional;
  type: 'checkbox';
  input: true;
  attributes?: { [key: string]: string };
}

export interface SelectFieldModel {
  label: string;
  labelPosition?: 'top' | 'bottom';
  widget?: string;
  placeholder?: string;
  description?: string;
  tooltip?: string;
  tabindex?: string;
  hidden?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  multiple?: boolean;
  data: {
    values: { label?: string; value?: string }[];
  };
  selectThreshold: number;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  unique?: boolean;
  errorLabel?: string;
  key: string;
  conditional?: Conditional;
  type: 'select';
  input: true;
  attributes?: { [key: string]: string };
}

export interface PhotoModel {
  label: string;
  labelPosition?: 'top' | 'bottom';
  description?: string;
  tooltip?: string;
  tabindex?: string;
  hidden?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  // multiple?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  errorLabel?: string;
  key: string;
  conditional?: Conditional;
  type: 'photo';
  input: true;
  attributes?: { [key: string]: string };
  hideGallery?: boolean; // false
  hideCamera?: boolean; // false
  size?: 'xs' | 'sm' | 'md' | 'lg'; // md
  theme?: 'primary' | 'secondary' | 'info' | 'success' | 'danger' | 'warning'; // primary
  imageHeight?: number; // 400
  imageWidth?: number; // 600
  allowCrop?: boolean;
}

export interface TableModel {
  label: string;
  cellAlignment?: 'left' | 'center' | 'right'; // left
  customClass?: string;
  striped?: boolean; // false
  bordered?: boolean; // false
  hover?: boolean;
  condensed?: boolean; // false
  hidden?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  key: string;
  conditional?: Conditional;
  attributes?: { [key: string]: string };
  type: 'table';
  numRows: number; // 3
  numCols: number; // 3
  input: false;
  rows: { components: FormioComponent[] }[][]; // row[] -> column[] -> cell -> components[]
  path: string;
}

export interface ActiveFlowsModel {
  label: string;
  conditional?: Conditional;
  hidden?: boolean;
  disabled?: boolean;
  type: 'activeflows';
  key: string;
  input: false;
  description?: string;
}

export interface PDF417ScanModel {
  label: string;
  conditional?: Conditional;
  hidden?: boolean;
  disabled?: boolean;
  type: 'pdf417';
  key: string;
  input: true;
  description?: string;
  limit?: string[];
  visible?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  attributes?: { [key: string]: string };
}
export interface FullVehicleScanModel {
  label?: string;
  conditional?: Conditional;
  hidden?: boolean;
  disabled?: boolean;
  type: 'full-vehicle-scan';
  key: string;
  driverKey?: string;
  vehicleKey?: string;
  additionalVehicleKey?: string;
  input: true;
  visible?: boolean;
  validate?: {
    required?: boolean;
    customMessage?: string;
  };
  attributes?: { [key: string]: string };
}

export interface LookupModel {
  apolloKey: string;
  label: string;
  labelPosition?: 'top' | 'bottom'; // 'top'
  placeholder?: string;
  description?: string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  // widget?: any;
  inputMask?: string; // see documentation
  inputMasks?: { label: string; mask: string }[];
  tabindex?: string;
  validate?: {
    required?: boolean;
    pattern?: string;
    customMessage?: string;
    minLength?: number;
    maxLength?: number;
    minWords?: number;
    maxWords?: number;
  };
  unique?: boolean; // false
  errorLabel?: string;
  hidden?: boolean; // false
  hideLabel?: boolean; // false
  showWordCount?: boolean; // false
  showCharCount?: boolean; // false
  mask?: boolean; // false
  autofocus?: boolean; // false
  disabled?: boolean; // false
  alwaysEnabled?: boolean;
  key: string;
  keyPrefix?: string;
  conditional?: Conditional;
  type: 'lookup';
  input: true;
  data: DynamicData;
  attributes?: { [key: string]: string };
}

/**
 * query: GraphqlQL Query string
 * fields: List of keys with labels to display based dynamically on the search list
 * searchFields: List of keys to match for searching on the front end
 * returnKey: Which keys are returned and stored on Workflow
 */
interface DynamicData {
  query: string;
  fields: LookupFieldType[];
  searchFields: string[];
  returnKeys: string[];
}

export type LookupFieldType = LookupFieldSingle | LookupFieldMulti;

export interface LookupFieldSingle {
  key: string;
  label: string;
  join?: undefined;
}
export interface LookupFieldMulti {
  key: string[];
  label: string;
  join: string;
}

export interface Conditional {
  show: boolean;
  when: string;
  eq: string;
}

export interface MultiButtonSelectItem {
  id: string | number;
  label: string;
  textColor?: string;
  contentType?: 'icon' | 'text' | 'link'; // icon, text, link;
  content?: string;
  backgroundImage?: string;
}

export interface Field<T = string | number | boolean | null | string[]> {
  value: T;
  required: boolean;
  visible: boolean;
  fulfilled: boolean;
}
export type GetValue<T = string | number | boolean | null | string[]> = (
  key: string
) => Field<T>;
export type SetValue = (
  key: string,
  value: string | number | null | boolean | (string | number)[],
  required: boolean,
  visible: boolean,
  fulfilled: boolean
) => void;
export type IsFulfilled = () => boolean;

export interface DriversLicense {
  idNumber: string;
  idNumberType: string;
  idCountryOfIssue: string;
  surname: string;
  gender: 'M' | 'F';
  firstNames: string;
  birthDate: Date; // YYYY-MM-DD
  driverRestrictions: '00' | '10' | '20' | '12';
  licenseCountryOfIssue: string;
  licenseIssueNumber: string;
  licenseNumber: string;
  licenseValidityStart: Date; // YYYY-MM-DD
  licenseValidityExpiry: Date; // YYYY-MM-DD
  professionalDrivingPermitExpiry: Date | null; // YYYY-MM-DD
  professionalDrivingPermitCodes: string | null;
  vehicleRestrictions: string[];
  vehicleCodes: string[];
  issueDates: Date[];
  // vehicleLicenses: {
  // code: 'A' | 'A1' | 'B' | 'C1' | 'C' | 'EB' | 'EC' | 'EC1';
  // restriction: string;
  // firstIssueDate: Date; // YYYY-MM-DD
  // }[];
}

export interface VehicleLicense {
  unknown1: 'MVL1CC97' | 'MVL1CC98';
  unknown2: '0138' | '0143' | '0159';
  unknown3: '4024T0NW';
  unknown4: '1';
  controlNumber: string; // no.
  registration: string; // License no.
  vehicleRegistration: string; // Veh. register no.
  description: string; // Description
  make: string; // Make
  model: string;
  color: string;
  vin: string; // VIN
  engine: string;
  expiry: string;
}

export interface ScanPayloadBase {
  ok: boolean;
  type: unknown;
  data: unknown;
  raw: number[] | null;
  message: string | null;
  charset: string;
  codeId: string;
  timestamp: Date;
}

export interface ScanPayloadVehicle extends ScanPayloadBase {
  ok: true;
  type: 'Vehicle';
  data: VehicleLicense;
  raw: number[];
  message: null;
}

export interface ScanPayloadDriver extends ScanPayloadBase {
  ok: true;
  type: 'Driver';
  data: DriversLicense;
  raw: number[];
  message: null;
}

export interface ScanPayloadQR extends ScanPayloadBase {
  ok: true;
  type: 'QR Code';
  data: string;
  raw: number[];
  message: null;
}

export interface ScanPayloadEAN extends ScanPayloadBase {
  ok: true;
  type: 'EAN-13' | 'EAN-8';
  data: string;
  raw: number[];
  message: null;
}

export interface ScanPayloadError extends ScanPayloadBase {
  ok: false;
  type: 'error';
  data: null;
  raw: number[];
  message: string;
}

export interface ScanPayloadOther extends ScanPayloadBase {
  ok: true;
  type: 'unknown';
  data: string;
  raw: number[];
  message: null;
}

export function isScanPayloadDataString(input): input is ScanPayloadQR | ScanPayloadEAN | ScanPayloadOther {
  return typeof input.data === 'string';
}

export interface ImageFieldModel {
  tooltip?: string;
  key: string;
  type: 'imagefield';
  label?: string;
  input: false;
  hidden?: boolean;
  conditional?: Conditional;
  attributes?: { [key: string]: string };
  url?: string;
  uriKey?: string;
  height?: number;
  width?: number;
}
export interface QRFieldModel {
  tooltip?: string;
  key: string;
  type: 'qr';
  label?: string;
  input: false;
  hidden?: boolean;
  conditional?: Conditional;
  attributes?: { [key: string]: string };
  data?: string;
  dataKey?: string;
}

export interface RackControlModel {
  tooltip?: string;
  key: string;
  type: 'rackcontrol';
  label: string;
  input: false;
  hidden?: boolean;
  conditional?: Conditional;
  attributes?: { [key: string]: string };
  data: (number | string)[];
  height?: number;
  width?: number;
  required: boolean;
  visible: boolean;
}

export type ScanPayload =
  | ScanPayloadDriver
  | ScanPayloadVehicle
  | ScanPayloadQR
  | ScanPayloadEAN
  | ScanPayloadOther
  | ScanPayloadError;

export interface MultiButtonSelectModel {
  tooltip?: string;
  key: string;
  type: 'multibuttonselect';
  label?: string;
  input: false;
  hidden?: boolean;
  hideLabel?: boolean;
  multiple?: true;
  numColumns?: number;
  api?: string;
  queryKeys?: string[];
  entity?: string;
  search?: Record<string, string>;
  query?: Record<string, string>;
  deepQuery?: Record<string, string>;
  cache?: boolean;
  conditional?: Conditional;
  attributes?: { [key: string]: string };
  data?: MultiButtonSelectItem[];
  dataKey?: string;
  height?: number;
  rowItemHeight?: number;
  width?: number;
  required: boolean;
  visible: boolean;
}

const test = {
  label: 'test label',
  type: 'maptrigger',
  data: {
    latitudeKey: 'latitude',
    longitudeKey: 'longitude'
  },
  key: 'map',
  input: false
};

export interface MapTriggerModel {
  label: string;
  action?: 'event' | 'submit';
  data: {
    useCoordinates: boolean;
    address: string;
    coordinates: {
      lat: string;
      long: string;
    };
    latitudeKey?: string;
    longitudeKey?: string;
  };
  state?: string; // Used when action = saveState;
  custom?: string; // code snippet used when action = custom;
  url?: string; // url used by action = url;
  headers?: { header: string; value: string }[]; // header used by action = url;
  showValidations?: boolean;
  theme?: 'primary' | 'secondary' | 'info' | 'success' | 'danger' | 'warning'; // primary
  size?: 'xs' | 'sm' | 'md' | 'lg'; // md
  block?: boolean;
  leftIcon?: SvgIcon;
  rightIcon?: SvgIcon;
  shortcut?: string;
  description?: string;
  tooltip?: string;
  tabindex?: string;
  disableOnInvalid?: boolean;
  hidden?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  alwaysEnabled?: boolean;
  tableView?: boolean;
  key: string;
  conditional?: Conditional;
  type: 'maptrigger';
  input: true;
  attributes?: { [key: string]: string };
}

export interface IconModel {
  key: string;
  name: string;
  fontSize: number;
  width: number;
  height: number;
  type: 'icon';
  input: false;

  hidden?: boolean; // false
  conditional?: Conditional;
  color?: string;
  attributes?: { [key: string]: string };
}
