import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/angular';
import { environment } from '@ov-suite/helpers-angular';
import { getTransporterIds, hasAppPermission, hasFeaturePermission, PermissionAction } from './auth.helper';

type ActivateChildResponse = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private readonly router: Router) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ActivateChildResponse {
    return this.activate(state);
  }

  getAppId(): string {
    const environmentKey = Object.keys(environment.values.webUrl).find(key => window.origin.includes(environment.values.webUrl[key]));
    return environment.values.appId[environmentKey].toString();
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Check App Level Access
    const appId = this.getAppId();
    if (appId) {
      const hasAPP = await hasAppPermission(Number(appId));
      if (!hasAPP) {
        return this.router.parseUrl('/unauthorized');
      }
    }

    if (next.data?.blockTransporter) {
      const transporterIds = await getTransporterIds();
      if (transporterIds.length) {
        return Promise.resolve(false);
      }
    }

    if (next.data?.feature) {
      const r = await hasFeaturePermission(next.data.feature.id, PermissionAction.READ);
      console.assert(r, 'Your not Authorized to view this feature');
      if (!r) {
        return Promise.resolve(false);
      }
    }
    return this.activate(state);
  }

  activate(state: RouterStateSnapshot, next?: ActivatedRouteSnapshot): Promise<boolean> {
    if (document.getElementById('authFrame')) {
      return Promise.resolve(true);
    }

    return new Promise(resolve => {
      Auth.currentSession()
        .then((cognitoUserSession: CognitoUserSession) => {
          const sessionValid = cognitoUserSession.isValid();
          if (!sessionValid) {
            console.log('Session not valid.');
            window.location.href = `${environment.values.webUrl.idm}/auth/login?redirect=${window.location.href}`;
          }

          const currentUserTokenInfo = cognitoUserSession.getIdToken().decodePayload();
          if (currentUserTokenInfo) {
            Sentry.setUser(currentUserTokenInfo['email']);
          }

          resolve(cognitoUserSession.isValid());
        })
        .catch(e => {
          console.error('[Auth Guard]');
          console.error(e);
          window.location.href = `${environment.values.webUrl.idm}/auth/login?redirect=${window.location.href}`;
          resolve(false);
        });
    });
  }

  checkSession(): Promise<boolean> {
    return Auth.currentSession()
      .then((cognitoUserSession: CognitoUserSession) => cognitoUserSession.isValid())
      .catch(() => false);
  }
}
