import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { environment } from '@ov-suite/helpers-angular';

@NgModule({
  imports: [BrowserModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory(httpLink: HttpLink): NamedOptions {
        const authLink = setContext(async (_, { headers }) => {
          try {
            const currentSession = await Auth.currentSession();
            const token = currentSession?.getIdToken().getJwtToken() ?? '';
            return {
              headers: {
                ...headers,
                authorization: token,
              },
            };
          } catch (e) {
            return headers;
          }
        });

        function createApollo(url: string) {
          return {
            link: authLink.concat(
              httpLink.create({
                uri: uri => `${url}/graphql/graphql-${uri.operationName}`,
              }),
            ),
            cache: new InMemoryCache(),
          };
        }

        return {
          idmlink: createApollo(environment.values.apiUrl.idm),
          adminlink: createApollo(environment.values.apiUrl.admin),
          warehouselink: createApollo(environment.values.apiUrl.warehouse),
          yardlink: createApollo(environment.values.apiUrl.yard),
          executionlink: createApollo(environment.values.apiUrl.execution),
          'ceramic-portal': createApollo(environment.values.apiUrl.ceramicPortal),
          yardlinkWs: {
            link: new WebSocketLink({
              uri: `${environment.values.apiUrl.yardWs}/graphql`,
              options: {
                reconnect: true,
                lazy: true,
              },
            }),
            cache: new InMemoryCache(),
          },
        };
      },
    },
  ],
})
export class GraphQLModule {}
