import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Domain } from '@ov-suite/models-admin';
import { UserAuthService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-domains-dialog',
  templateUrl: './domains-dialog.component.html',
  styleUrls: ['./domains-dialog.component.css']
})
export class DomainsDialogComponent {
  domains: Domain[] = [];

  domainsFilter: Domain[] = [];

  selectedDomain: Domain;

  @ViewChild('search', { static: true }) search: ElementRef;

  constructor(
    private readonly dialogRef: MatDialogRef<DomainsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private authService: UserAuthService,
  ) {
    this.domains = data.domains;
    this.domainsFilter = this.domains;
    this.selectedDomain = data.selectedDomain;
  }

  filterDomains() {
    let input = this.search.nativeElement.value;

    this.domainsFilter = this.domains.filter(domain => {
      return domain.name.toLowerCase().includes(input.toLowerCase())
    });
  }

  clearFilter() {
    this.domainsFilter = this.domains;
    this.search.nativeElement.value = '';
  }

  changeDomain(domain) {
    this.authService.setUserDomain(domain);
  }
}
