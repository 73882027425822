<div class="data-actions">
  <mat-card class="hierarchy-data-actions shadow-none background-transparent">
    <button mat-button [color]="getButtonColor()" (click)="onLiveClick()">
      <ov-suite-icons [icon]="'mobile-video'" [size]="'icon-md'"></ov-suite-icons>
      <span [ngClass]="live ? 'live-active' : ''">live</span>
    </button>
    <button mat-button color="blank" class="mat-icon-small" (click)="refresh()">
      <ov-suite-icons [icon]="'repeat'" [size]="'icon-md'"></ov-suite-icons>
      <span>Refresh</span>
    </button>
  </mat-card>
</div>
