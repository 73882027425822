import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { OvAutoService, OvAutoServiceMultipleParams } from '@ov-suite/services';
import { Constructor, FieldMetadata, FieldType, getFieldMetadata, getOVEntities, HasId, PageReturn } from '@ov-suite/ov-metadata';
import { QueryParams } from '@ov-suite/helpers-shared';

export type TopFilter<T> = TopFilterBase<T> & (TopFilterLookup | TopFilterStatic);

interface TopFilterBase<T> {
  placeholder: string;
  key: keyof T | string;
}

interface TopFilterLookup {
  type: 'lookup';
  labelKey: string;
  search?: Record<string, QueryParams[]>;
  filter?: Record<string, QueryParams[]>;
  query?: Record<string, QueryParams[]>;
}

interface TopFilterStatic {
  type: 'static';
  data: {
    key: string | number;
    label: string;
  }[];
}

@Component({
  selector: 'ov-suite-top-filters',
  templateUrl: './top-filters.component.html',
  styleUrls: ['./top-filters.component.scss'],
})
export class TopFiltersComponent<T extends HasId> implements OnInit {
  @Output() filterChange = new EventEmitter<Record<string, unknown>>();

  @Input() entity: Constructor<T>;

  @Input() filters: TopFilter<T>[] = [];

  metadata: FieldMetadata<T>;

  data: Record<string, unknown[]> = {};

  selected: Record<string, unknown> = {};

  selectedLabels: Record<string, string> = {};

  constructor(private readonly ovAutoService: OvAutoService) {}

  ngOnInit() {
    this.metadata = getFieldMetadata(this.entity);

    const params: OvAutoServiceMultipleParams = {};

    this.filters.forEach(filter => {
      if (filter.type === 'lookup') {
        const entity = getEntity(this.metadata as FieldMetadata<HasId>, filter.key as string);
        if (entity) {
          const key = (filter.key as string).split('.').join('_');
          params[key] = {
            entity,
            keys: ['id', filter.labelKey],
            type: 'list',
            filter: filter.filter,
            search: filter.search,
            query: filter.query,
          };
        }
      } else {
        // do nothing?
      }
    });

    this.ovAutoService.multipleFetch(params).then(response => {
      Object.entries(response).forEach(([key, value]) => {
        const newKey = key.split('_').join('.');
        this.data[newKey] = (value as PageReturn<T>).data;
      });
    });
  }

  selectFilter(filter: TopFilter<HasId>, value: unknown, label: string) {
    let saveKey = filter.key;
    if (filter.type === 'lookup') {
      saveKey += 'Id';
    }
    this.selected[saveKey] = value;
    this.selectedLabels[filter.key] = label;
    this.filterChange.emit(this.selected);
  }
}

function getEntity(metadata: FieldMetadata<HasId>, input: string): Constructor<HasId> {
  const [first, ...keys] = input.split('.');
  const field = metadata.fields.find(i => first === i.propertyKey);
  const type = getType(field.type);

  if (keys.length) {
    const newMetadata = getFieldMetadata(type);
    return getEntity(newMetadata, keys.join('.'));
  }
  return type;
}

function getType(input: FieldType): Constructor<HasId> {
  if (typeof input === 'function') {
    const type = input();
    const [entityRaw] = Array.isArray(type) ? type : [type];
    if (typeof entityRaw === 'string') {
      return getOVEntities()[entityRaw];
    }
    return entityRaw;
  }
  return null;
}
