<div class="day-btn-container" [ngClass]="size">
  <button
    *ngFor="let day of days; index as i"
    (click)="onSelect(_value, day.value)"
    class="day-btn"
    [ngClass]="isSelected(_value, day.value) ? 'day-btn-dark' : 'day-btn-light'"
  >
    {{ day.name }}
  </button>
</div>
