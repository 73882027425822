import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from './format-datepicker';

@Component({
  selector: 'ov-suite-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  providers: [MakeProvider(TimeComponent),
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeComponent extends AbstractValueAccessor<string> implements OnInit {
  time = '00:00:00';
  @Input() disabled;

  ngOnInit(): void {}

  writeValue(value: string) {
    if (!!value) {
      this.val = value;
      this.time = value;
      this.onChange(this.time);
    }
  }

  set value(time: string) {
    this.val = time;
  }

  get value(): string {
    return this.time;
  }

  valueChange() {
    this.onChange(this.time);
  }

}
