import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// eslint-disable-next-line import/no-cycle
import { MatDialogComponent } from '../mat-dialog.component';

@Component({
  selector: 'ov-suite-mat-dialog-content',
  templateUrl: './mat-dialog-content.component.html',
  styleUrls: ['./mat-dialog-content.component.css']
})
export class MatDialogContentComponent implements OnInit {
  header;

  content;

  actions;

  constructor(private readonly dialogRef: MatDialogRef<MatDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.content = data.component.content;
    this.header = data.component.header;
    this.actions = data.component.actions;
  }

  ngOnInit(): void {}
}
