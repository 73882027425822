import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
// eslint-disable-next-line import/no-cycle
import { MatDialogContentComponent } from './mat-dialog-content/mat-dialog-content.component';

@Component({
  selector: 'ov-suite-mat-dialog',
  templateUrl: './mat-dialog.component.html',
  styleUrls: ['./mat-dialog.component.css'],
})
export class MatDialogComponent implements OnInit {
  // Used for the button that will open this dialog
  @Input() icon;

  @Input() header;

  @Input() content;

  @Input() actions;

  @Input() width: number;

  @Input() toggler: BehaviorSubject<boolean>;

  dialogRef: MatDialogRef<MatDialogContentComponent, unknown>;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.toggler?.subscribe(toggle => {
      console.log('Toggler: ', toggle);
      if (!toggle) {
        this.dialogRef?.close(this.dialog);
      } else {
        this.openDialog();
      }
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data = {
      component: {
        header: this.header,
        content: this.content,
        actions: this.actions,
      },
    };

    dialogConfig.width = `${this.width}px` ?? '850px';
    // dialogConfig.height = '450px';

    dialogConfig.position = {};

    dialogConfig.panelClass = 'mat-dialog-container-white';

    this.dialogRef = this.dialog.open(MatDialogContentComponent, dialogConfig);
    this.dialogRef.disableClose = true;
  }
}
