import { OVEntity, OVField, OVForm, OVSearchable, OVSidebar, OVTable } from '@ov-suite/ov-metadata';
import { Domain } from './domain.model';

@OVEntity('AreaStatus', 'adminlink')
export class AreaStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Area', 'adminlink')
@OVForm([['#Area Details'], ['areaId', 'name'], ['description'], ['domains']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Area>([
  {
    key: 'areaId',
    title: 'Area ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Area Name',
    type: 'string',
  },
  {
    type: 'other',
    title: 'Domains',
    action: item => item?.domains?.map(d => d.name).join(', ') || 'None',
    keys: ['domains.name'],
  },
  {
    type: 'other',
    title: 'Number of Sub Areas',
    action: item => item?.childrenCount?.toString() ?? '0',
    keys: ['childrenCount'],
    routerLink: () => [],
    queryParams: (item: { id: number }) => ({ _parentId: item.id }),
  },
])
export class Area {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Area Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Area ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  areaId: string;

  @OVField({ type: () => Area, title: 'Parent', bulkDependency: 'areaId' })
  parent?: Area;

  @OVField({ type: () => Area })
  children?: Area[];

  @OVField({ type: 'number', generated: true, readonly: true })
  childrenCount?: number;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: () => [Domain],
    title: 'Domains',
    tooltip: 'Add multiple domains for this area.',
    selectionType: 'multiple',
    required: true,
  })
  domains?: Domain[];

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  // @SidebarField({ type: 'dropdown', title: 'Status' })
  @OVField({
    type: () => AreaStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: AreaStatus;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;
}
