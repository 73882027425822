import { Routes } from '@angular/router';
import { UserListComponent } from './list/user-list.component';
import { UserAddOrEditComponent } from './add-or-edit/user-add-or-edit.component';

export const UsersRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: UserListComponent,
      },
      {
        path: 'add',
        component: UserAddOrEditComponent,
      },
      {
        path: 'edit',
        component: UserAddOrEditComponent,
      },
    ],
  },
];
