<div class="hierarchy-tabs">
  <div
    *ngFor="let item of tabs; let i = index"
    class="ht-block"
    [ngClass]="{ 'ht-selected': item.path === active }"
    [style.border-bottom]="item.path === active? '3px solid '+highlightColor : 'none'"
  >
    <a [routerLink]="item.path" [ngClass]="{ 'font-weight-bold': item.path === active}">
      {{ item?.title }}
      <i *ngIf="item.iconClass" [class]="item.iconClass" aria-hidden="true"></i>
    </a>
  </div>
</div>
