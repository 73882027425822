<div class="main-content">
    <ov-suite-create-or-edit
      [ovAutoService]="ovAutoService"
      [api]="options.api"
      [formClass]="options.entity"
      [title]="options.createTitle"
      (save)="navigateBack()"
      (delete)="navigateBack()"
      (updateSuccess)="options.onUpdate($event)"
      (createSuccess)="options.onCreate($event)"
      (deleteSuccess)="options.onDelete($event)"
    >
    </ov-suite-create-or-edit>
</div>
