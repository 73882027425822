import { NgModule } from '@angular/core';
import { Camera, Heart, PlusCircle, Settings, Plus, Minus, MinusCircle, ArrowLeft, ArrowRight, OvsuiteIconsModule } from '@ov-suite/styles';

// select icons to include
const icons = {
  Camera,
  Heart,
  MinusCircle,
  PlusCircle,
  ArrowLeft,
  ArrowRight,
  Plus,
  Minus,
  Settings,
};

@NgModule({
  imports: [OvsuiteIconsModule.pick(icons)],
  exports: [OvsuiteIconsModule],
})
export class UiIconsModule {}
