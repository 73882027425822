import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Recurrence } from '@ov-suite/helpers-shared';
import moment from 'moment';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

enum RepeatFrequency {
  Day = 'daily',
  Week = 'weekly',
  Month = 'monthly',
  Year = 'yearly',
}

enum WeekNumber {
  first = 1,
  second,
  third,
  last,
}

@Component({
  selector: 'ov-suite-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
  providers: [MakeProvider(RecurrenceComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurrenceComponent extends AbstractValueAccessor<string> implements OnInit {
  repeatFrequencies = RepeatFrequency;

  weekNumbers = WeekNumber;

  // new ones
  @Input() id: string;

  @Input() tooltip: string;

  @Input() disabled: boolean;

  @Input() inputLabel: string;

  @Input() danger: boolean;

  @Input() title: string; // todo: cleanup title and inputLabel

  _data: string;

  get endCount() {
    if (this.current.endType === 'after') {
      return this.current.endCount;
    }
    return 1;
  }

  get endDate() {
    if (this.current.endType === 'on') {
      return this.current.endDate;
    }
    return null;
  }

  @Input() set data(item: string) {
    if (item) {
      this._data = item;
      this.current = JSON.parse(item);
    }
  }

  get data() {
    return this._data;
  }

  val = '';

  current: Recurrence = {
    repeat: 1,
    type: 'yearly',
    endType: 'never',
  };

  constructor() {
    super();
  }

  ngOnInit(): void {}

  onTypeChange(type) {
    const { current } = this;
    current['type'] = type.target.value;
    this.current = current;
    this.writeValue(JSON.stringify(current));
  }

  onRepeatChange(event) {
    const repeatNumber = Number(event.target.value);
    this.current.repeat = repeatNumber > 0 ? repeatNumber : 1;
    this.writeValue(JSON.stringify(this.current));
  }

  onDayChange(event) {
    if (this.current.type === 'weekly') {
      this.current.days = event;
      this.writeValue(JSON.stringify(this.current));
    }
  }

  onMonthlySelectorChange(event) {
    // set those monthly things
    if (this.current.type === 'monthly') {
      if (event.target.value > 0) {
        // whole thing
        this.current.day = moment().day();
        this.current.weekNumber = this.getMonthlyWeekNumber();
      } else {
        this.current.day = this.getMonthlyDay();
        const { weekNumber, ...rest } = this.current;
        this.current = rest;
      }
    }

    this.writeValue(JSON.stringify(this.current));
  }

  getMonthlyDay() {
    return moment().date();
  }

  getMonthlyWeekNumber() {
    return Math.ceil(moment().date() / 7);
  }

  getMonthlyDayString() {
    return moment().format('dddd');
  }

  onEndDateChange(event) {
    if (this.current.endType === 'on') {
      this.current.endDate = event.target.value;
      this.writeValue(JSON.stringify(this.current));
    }
  }

  onAfterOccurrenceChange(event) {
    if (this.current.endType === 'after') {
      this.current.endCount = event.target.value;
      this.writeValue(JSON.stringify(this.current));
    }
  }

  setEndType(value) {
    if (this.current.endType === 'after') {
      delete this.current.endCount;
    }

    if (this.current.endType === 'on') {
      delete this.current.endDate;
    }
    this.current.endType = value;
    this.writeValue(JSON.stringify(this.current));
  }
}
