export { Activity } from './svg/activity';
export { Airplay } from './svg/airplay';
export { AlertCircle } from './svg/alert-circle';
export { AlertOctagon } from './svg/alert-octagon';
export { AlertTriangle } from './svg/alert-triangle';
export { AlignCenter } from './svg/align-center';
export { AlignJustify } from './svg/align-justify';
export { AlignLeft } from './svg/align-left';
export { AlignRight } from './svg/align-right';
export { Anchor } from './svg/anchor';
export { Aperture } from './svg/aperture';
export { Archive } from './svg/archive';
export { ArrowDownCircle } from './svg/arrow-down-circle';
export { ArrowDownLeft } from './svg/arrow-down-left';
export { ArrowDownRight } from './svg/arrow-down-right';
export { ArrowDown } from './svg/arrow-down';
export { ArrowLeftCircle } from './svg/arrow-left-circle';
export { ArrowLeft } from './svg/arrow-left';
export { ArrowRightCircle } from './svg/arrow-right-circle';
export { ArrowRight } from './svg/arrow-right';
export { ArrowUpCircle } from './svg/arrow-up-circle';
export { ArrowUpLeft } from './svg/arrow-up-left';
export { ArrowUpRight } from './svg/arrow-up-right';
export { ArrowUp } from './svg/arrow-up';
export { AtSign } from './svg/at-sign';
export { Award } from './svg/award';
export { BarChart2 } from './svg/bar-chart-2';
export { BarChart } from './svg/bar-chart';
export { BatteryCharging } from './svg/battery-charging';
export { Battery } from './svg/battery';
export { BellOff } from './svg/bell-off';
export { Bell } from './svg/bell';
export { Bluetooth } from './svg/bluetooth';
export { Bold } from './svg/bold';
export { BookOpen } from './svg/book-open';
export { Book } from './svg/book';
export { Bookmark } from './svg/bookmark';
export { Box } from './svg/box';
export { Briefcase } from './svg/briefcase';
export { Calendar } from './svg/calendar';
export { CameraOff } from './svg/camera-off';
export { Camera } from './svg/camera';
export { Cast } from './svg/cast';
export { CheckCircle } from './svg/check-circle';
export { CheckSquare } from './svg/check-square';
export { Check } from './svg/check';
export { ChevronDown } from './svg/chevron-down';
export { ChevronLeft } from './svg/chevron-left';
export { ChevronRight } from './svg/chevron-right';
export { ChevronUp } from './svg/chevron-up';
export { ChevronsDown } from './svg/chevrons-down';
export { ChevronsLeft } from './svg/chevrons-left';
export { ChevronsRight } from './svg/chevrons-right';
export { ChevronsUp } from './svg/chevrons-up';
export { Chrome } from './svg/chrome';
export { Circle } from './svg/circle';
export { Clipboard } from './svg/clipboard';
export { Clock } from './svg/clock';
export { CloudDrizzle } from './svg/cloud-drizzle';
export { CloudLightning } from './svg/cloud-lightning';
export { CloudOff } from './svg/cloud-off';
export { CloudRain } from './svg/cloud-rain';
export { CloudSnow } from './svg/cloud-snow';
export { Cloud } from './svg/cloud';
export { Code } from './svg/code';
export { Codepen } from './svg/codepen';
export { Codesandbox } from './svg/codesandbox';
export { Coffee } from './svg/coffee';
export { Columns } from './svg/columns';
export { Command } from './svg/command';
export { Compass } from './svg/compass';
export { Copy } from './svg/copy';
export { CornerDownLeft } from './svg/corner-down-left';
export { CornerDownRight } from './svg/corner-down-right';
export { CornerLeftDown } from './svg/corner-left-down';
export { CornerLeftUp } from './svg/corner-left-up';
export { CornerRightDown } from './svg/corner-right-down';
export { CornerRightUp } from './svg/corner-right-up';
export { CornerUpLeft } from './svg/corner-up-left';
export { CornerUpRight } from './svg/corner-up-right';
export { Cpu } from './svg/cpu';
export { CreditCard } from './svg/credit-card';
export { Crop } from './svg/crop';
export { Crosshair } from './svg/crosshair';
export { Database } from './svg/database';
export { Delete } from './svg/delete';
export { Disc } from './svg/disc';
export { DollarSign } from './svg/dollar-sign';
export { DownloadCloud } from './svg/download-cloud';
export { Download } from './svg/download';
export { Droplet } from './svg/droplet';
export { Edit2 } from './svg/edit-2';
export { Edit3 } from './svg/edit-3';
export { Edit } from './svg/edit';
export { ExternalLink } from './svg/external-link';
export { EyeOff } from './svg/eye-off';
export { Eye } from './svg/eye';
export { Facebook } from './svg/facebook';
export { FastForward } from './svg/fast-forward';
export { Feather } from './svg/feather';
export { Figma } from './svg/figma';
export { FileMinus } from './svg/file-minus';
export { FilePlus } from './svg/file-plus';
export { FileText } from './svg/file-text';
export { File } from './svg/file';
export { Film } from './svg/film';
export { Filter } from './svg/filter';
export { Flag } from './svg/flag';
export { FolderMinus } from './svg/folder-minus';
export { FolderPlus } from './svg/folder-plus';
export { Folder } from './svg/folder';
export { Framer } from './svg/framer';
export { Frown } from './svg/frown';
export { Gift } from './svg/gift';
export { GitBranch } from './svg/git-branch';
export { GitCommit } from './svg/git-commit';
export { GitMerge } from './svg/git-merge';
export { GitPullRequest } from './svg/git-pull-request';
export { Github } from './svg/github';
export { Gitlab } from './svg/gitlab';
export { Globe } from './svg/globe';
export { Grid } from './svg/grid';
export { HardDrive } from './svg/hard-drive';
export { Hash } from './svg/hash';
export { Headphones } from './svg/headphones';
export { Heart } from './svg/heart';
export { HelpCircle } from './svg/help-circle';
export { Hexagon } from './svg/hexagon';
export { Home } from './svg/home';
export { Image } from './svg/image';
export { Inbox } from './svg/inbox';
export { Info } from './svg/info';
export { Instagram } from './svg/instagram';
export { Italic } from './svg/italic';
export { Key } from './svg/key';
export { Layers } from './svg/layers';
export { Layout } from './svg/layout';
export { LifeBuoy } from './svg/life-buoy';
export { Link2 } from './svg/link-2';
export { Link } from './svg/link';
export { Linkedin } from './svg/linkedin';
export { List } from './svg/list';
export { Loader } from './svg/loader';
export { Lock } from './svg/lock';
export { LogIn } from './svg/log-in';
export { LogOut } from './svg/log-out';
export { Mail } from './svg/mail';
export { MapPin } from './svg/map-pin';
export { Map } from './svg/map';
export { Maximize2 } from './svg/maximize-2';
export { Maximize } from './svg/maximize';
export { Meh } from './svg/meh';
export { Menu } from './svg/menu';
export { MessageCircle } from './svg/message-circle';
export { MessageSquare } from './svg/message-square';
export { MicOff } from './svg/mic-off';
export { Mic } from './svg/mic';
export { Minimize2 } from './svg/minimize-2';
export { Minimize } from './svg/minimize';
export { MinusCircle } from './svg/minus-circle';
export { MinusSquare } from './svg/minus-square';
export { Minus } from './svg/minus';
export { Monitor } from './svg/monitor';
export { Moon } from './svg/moon';
export { MoreHorizontal } from './svg/more-horizontal';
export { MoreVertical } from './svg/more-vertical';
export { MousePointer } from './svg/mouse-pointer';
export { Move } from './svg/move';
export { Music } from './svg/music';
export { Navigation2 } from './svg/navigation-2';
export { Navigation } from './svg/navigation';
export { Octagon } from './svg/octagon';
export { Package } from './svg/package';
export { Paperclip } from './svg/paperclip';
export { PauseCircle } from './svg/pause-circle';
export { Pause } from './svg/pause';
export { PenTool } from './svg/pen-tool';
export { Percent } from './svg/percent';
export { PhoneCall } from './svg/phone-call';
export { PhoneForwarded } from './svg/phone-forwarded';
export { PhoneIncoming } from './svg/phone-incoming';
export { PhoneMissed } from './svg/phone-missed';
export { PhoneOff } from './svg/phone-off';
export { PhoneOutgoing } from './svg/phone-outgoing';
export { Phone } from './svg/phone';
export { PieChart } from './svg/pie-chart';
export { PlayCircle } from './svg/play-circle';
export { Play } from './svg/play';
export { PlusCircle } from './svg/plus-circle';
export { PlusSquare } from './svg/plus-square';
export { Plus } from './svg/plus';
export { Pocket } from './svg/pocket';
export { Power } from './svg/power';
export { Printer } from './svg/printer';
export { Radio } from './svg/radio';
export { RefreshCcw } from './svg/refresh-ccw';
export { RefreshCw } from './svg/refresh-cw';
export { Repeat } from './svg/repeat';
export { Rewind } from './svg/rewind';
export { RotateCcw } from './svg/rotate-ccw';
export { RotateCw } from './svg/rotate-cw';
export { Rss } from './svg/rss';
export { Save } from './svg/save';
export { Scissors } from './svg/scissors';
export { Search } from './svg/search';
export { Send } from './svg/send';
export { Server } from './svg/server';
export { Settings } from './svg/settings';
export { Share2 } from './svg/share-2';
export { Share } from './svg/share';
export { ShieldOff } from './svg/shield-off';
export { Shield } from './svg/shield';
export { ShoppingBag } from './svg/shopping-bag';
export { ShoppingCart } from './svg/shopping-cart';
export { Shuffle } from './svg/shuffle';
export { Sidebar } from './svg/sidebar';
export { SkipBack } from './svg/skip-back';
export { SkipForward } from './svg/skip-forward';
export { Slack } from './svg/slack';
export { Slash } from './svg/slash';
export { Sliders } from './svg/sliders';
export { Smartphone } from './svg/smartphone';
export { Smile } from './svg/smile';
export { Speaker } from './svg/speaker';
export { Square } from './svg/square';
export { Star } from './svg/star';
export { StopCircle } from './svg/stop-circle';
export { Sun } from './svg/sun';
export { Sunrise } from './svg/sunrise';
export { Sunset } from './svg/sunset';
export { Tablet } from './svg/tablet';
export { Tag } from './svg/tag';
export { Target } from './svg/target';
export { Terminal } from './svg/terminal';
export { Thermometer } from './svg/thermometer';
export { ThumbsDown } from './svg/thumbs-down';
export { ThumbsUp } from './svg/thumbs-up';
export { ToggleLeft } from './svg/toggle-left';
export { ToggleRight } from './svg/toggle-right';
export { Tool } from './svg/tool';
export { Trash2 } from './svg/trash-2';
export { Trash } from './svg/trash';
export { Trello } from './svg/trello';
export { TrendingDown } from './svg/trending-down';
export { TrendingUp } from './svg/trending-up';
export { Triangle } from './svg/triangle';
export { Truck } from './svg/truck';
export { Tv } from './svg/tv';
export { Twitch } from './svg/twitch';
export { Twitter } from './svg/twitter';
export { Type } from './svg/type';
export { Umbrella } from './svg/umbrella';
export { Underline } from './svg/underline';
export { Unlock } from './svg/unlock';
export { UploadCloud } from './svg/upload-cloud';
export { Upload } from './svg/upload';
export { UserCheck } from './svg/user-check';
export { UserMinus } from './svg/user-minus';
export { UserPlus } from './svg/user-plus';
export { UserX } from './svg/user-x';
export { User } from './svg/user';
export { Users } from './svg/users';
export { VideoOff } from './svg/video-off';
export { Video } from './svg/video';
export { Voicemail } from './svg/voicemail';
export { Volume1 } from './svg/volume-1';
export { Volume2 } from './svg/volume-2';
export { VolumeX } from './svg/volume-x';
export { Volume } from './svg/volume';
export { Watch } from './svg/watch';
export { WifiOff } from './svg/wifi-off';
export { Wifi } from './svg/wifi';
export { Wind } from './svg/wind';
export { XCircle } from './svg/x-circle';
export { XOctagon } from './svg/x-octagon';
export { XSquare } from './svg/x-square';
export { X } from './svg/x';
export { Youtube } from './svg/youtube';
export { ZapOff } from './svg/zap-off';
export { Zap } from './svg/zap';
export { ZoomIn } from './svg/zoom-in';
export { ZoomOut } from './svg/zoom-out';

export { allIcons } from './all';
