import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomainsDialogComponent } from './domains-dialog/domains-dialog.component';
import { Domain } from '@ov-suite/models-admin';
import { Subscription } from 'rxjs';
import { UserAuthService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-domains',
  templateUrl: './domains.component.html',
  styleUrls: [ './domains.component.css' ]
})
export class DomainsComponent implements OnInit, OnDestroy {
  domains: Domain[] = [];

  selectedDomain: Domain = new Domain();

  @Input() opened;

  domainsDialogOpen = false;

  domainsSubscription: Subscription;

  selectedDomainSubscription: Subscription;

  constructor(public dialog: MatDialog, private userAuth: UserAuthService) {}

  ngOnInit() {
    this.domainsSubscription = this.userAuth.userDomainsSubject.subscribe((domains) => {
      this.domains = domains;
    })

    this.selectedDomainSubscription = this.userAuth.currentUserDomainSubject.subscribe(domain => {
      this.selectedDomain = domain;
    })
  }

  ngOnDestroy() {
    this.domainsSubscription.unsubscribe();
    this.selectedDomainSubscription.unsubscribe();
  }

  openDialog(element) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data = {
      domains: this.domains,
      selectedDomain: this.selectedDomain
    };

    dialogConfig.position = {
      top: `${element.offsetTop + 10}px`,
      left: `${element.offsetWidth + 10}px`,
    };

    dialogConfig.panelClass = 'ov-custom-dialog';
    // dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';

    this.domainsDialogOpen = true;
    const dialogRef = this.dialog.open(DomainsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.domainsDialogOpen = false;
    });
  }

  closeDialog () {
    this.dialog.closeAll();
    this.domainsDialogOpen = false;
  }
}
